<ion-header>
  <ion-toolbar
    *ngIf="!selectComponent.headerTemplate"
    [color]="selectComponent.headerColor ? selectComponent.headerColor : null"
  >
    <ion-title>
      <!-- Need span for for text ellipsis. -->
      <span
        *ngIf="selectComponent.titleTemplate"
        [ngTemplateOutlet]="selectComponent.titleTemplate"
      >
      </span>
      <span *ngIf="!selectComponent.titleTemplate">
        {{ selectComponent.label }}
      </span>
    </ion-title>
  </ion-toolbar>

  <ion-button color="danger" fill="clear" class="close-button" (click)="selectComponent._close()">
    <ion-icon name="close-outline" slot="icon-only"> </ion-icon>
  </ion-button>

  <div
    *ngIf="selectComponent.headerTemplate"
    [ngTemplateOutlet]="selectComponent.headerTemplate"
  ></div>
  <ion-toolbar *ngIf="selectComponent.canSearch || selectComponent.messageTemplate">
    <ion-searchbar
      class="search-bar"
      style="margin: 0"
      *ngIf="selectComponent.canSearch"
      #searchbarComponent
      [(ngModel)]="selectComponent._searchText"
      (ionChange)="selectComponent._filterItems()"
      (ionClear)="selectComponent._onSearchbarClear()"
      [placeholder]="selectComponent.searchPlaceholder"
      [debounce]="selectComponent.searchDebounce"
    >
    </ion-searchbar>
    <div class="ionic-selectable-message" *ngIf="selectComponent.messageTemplate">
      <div [ngTemplateOutlet]="selectComponent.messageTemplate"></div>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content scrollbar [scrollY]="false">
  <cdk-virtual-scroll-viewport
    [itemSize]="selectComponent.virtualScrollApproxItemHeight"
    minBufferPx="900"
    maxBufferPx="1350"
    class="scrollable"
  >
    <div class="ionic-selectable-spinner" *ngIf="selectComponent._isSearching">
      <div class="ionic-selectable-spinner-background"></div>
      <ion-spinner></ion-spinner>
    </div>
    <ion-list
      mode="md"
      class="ion-no-margin"
      *ngIf="!selectComponent.hasVirtualScroll && selectComponent._hasFilteredItems"
    >
      <ion-item-group
        *ngFor="let group of selectComponent._filteredGroups"
        class="ionic-selectable-group"
      >
        <ion-item-divider
          *ngIf="selectComponent._hasGroups"
          [color]="selectComponent.groupColor ? selectComponent.groupColor : null"
        >
          <span
            *ngIf="selectComponent.groupTemplate"
            [ngTemplateOutlet]="selectComponent.groupTemplate"
            [ngTemplateOutletContext]="{ group: group }"
          >
          </span>
          <!-- Need ion-label for text ellipsis. -->
          <ion-label *ngIf="!selectComponent.groupTemplate">
            {{ group.text }}
          </ion-label>
          <div *ngIf="selectComponent.groupEndTemplate" slot="end">
            <div
              [ngTemplateOutlet]="selectComponent.groupEndTemplate"
              [ngTemplateOutletContext]="{ group: group }"
            ></div>
          </div>
        </ion-item-divider>
        <ion-item
          button="true"
          detail="false"
          *ngFor="let item of group.items"
          (click)="selectComponent._select(item)"
          class="ionic-selectable-item"
          [ngClass]="{
            'ionic-selectable-item-is-selected': selectComponent._isItemSelected(item),
            'ionic-selectable-item-is-disabled': selectComponent._isItemDisabled(item)
          }"
          [disabled]="selectComponent._isItemDisabled(item)"
        >
          <!-- Need span for text ellipsis. -->
          <span
            *ngIf="selectComponent.itemTemplate"
            [ngTemplateOutlet]="selectComponent.itemTemplate"
            [ngTemplateOutletContext]="{
              item: item,
              isItemSelected: selectComponent._isItemSelected(item)
            }"
          >
          </span>

          <!-- Need ion-label for text ellipsis. -->
          <ion-label *ngIf="!selectComponent.itemTemplate">
            {{ selectComponent._formatItem(item) }}
          </ion-label>
          <div *ngIf="selectComponent.itemEndTemplate" slot="end">
            <div
              [ngTemplateOutlet]="selectComponent.itemEndTemplate"
              [ngTemplateOutletContext]="{
                item: item,
                isItemSelected: selectComponent._isItemSelected(item)
              }"
            ></div>
          </div>
          <span
            *ngIf="selectComponent.itemIconTemplate"
            [ngTemplateOutlet]="selectComponent.itemIconTemplate"
            [ngTemplateOutletContext]="{
              item: item,
              isItemSelected: selectComponent._isItemSelected(item)
            }"
          >
          </span>
          <ion-icon
            *ngIf="!selectComponent.itemIconTemplate"
            [name]="selectComponent._isItemSelected(item) ? 'checkmark-circle' : 'radio-button-off'"
            [color]="selectComponent._isItemSelected(item) ? 'primary' : null"
            [slot]="selectComponent.itemIconSlot"
          >
          </ion-icon>
          <ion-button
            *ngIf="selectComponent.canSaveItem"
            class="ionic-selectable-item-button"
            slot="end"
            fill="outline"
            (click)="selectComponent._saveItem($event, item)"
          >
            <ion-icon slot="icon-only" ios="create" md="create-sharp"></ion-icon>
          </ion-button>
          <ion-button
            *ngIf="selectComponent.canDeleteItem"
            class="ionic-selectable-item-button"
            slot="end"
            fill="outline"
            (click)="selectComponent._deleteItemClick($event, item)"
          >
            <ion-icon slot="icon-only" ios="trash" md="trash-sharp"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-item-group>
    </ion-list>
    <!-- Fail text should be above InfiniteScroll to avoid a gap when no items are found. -->
    <div *ngIf="!selectComponent._hasFilteredItems">
      <span
        *ngIf="selectComponent.searchFailTemplate"
        [ngTemplateOutlet]="selectComponent.searchFailTemplate"
      >
      </span>
      <div *ngIf="!selectComponent.searchFailTemplate" class="ion-margin">
        {{ selectComponent.searchFailText }}
      </div>
    </div>
    <ion-infinite-scroll
      *ngIf="!selectComponent.hasVirtualScroll"
      [disabled]="!selectComponent.hasInfiniteScroll"
      (ionInfinite)="selectComponent._getMoreItems()"
    >
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
    <ion-item-divider
      *virtualHeader="let header"
      [color]="selectComponent.groupColor ? selectComponent.groupColor : null"
    >
      {{ header }}
    </ion-item-divider>
    <ion-item
      *cdkVirtualFor="let item of selectComponent._filteredGroups[0].items"
      button="true"
      lines="full"
      detail="false"
      (click)="selectComponent._select(item)"
      class="ionic-selectable-item"
      [ngClass]="{
        'ionic-selectable-item-is-selected': selectComponent._isItemSelected(item),
        'ionic-selectable-item-is-disabled': selectComponent._isItemDisabled(item)
      }"
      [disabled]="selectComponent._isItemDisabled(item)"
    >
      <!-- Need span for text ellipsis. -->
      <span
        *ngIf="selectComponent.itemTemplate"
        [ngTemplateOutlet]="selectComponent.itemTemplate"
        [ngTemplateOutletContext]="{
          item: item,
          isItemSelected: selectComponent._isItemSelected(item)
        }"
      >
      </span>

      <ui-lazy-img
        *ngIf="item.image"
        slot="start"
        [src]="item ? (item.image | cloudUrl : '50x50') : undefined"
        [width]="35"
        [height]="35"
      >
      </ui-lazy-img>

      <!-- Need ion-label for text ellipsis. -->
      <ion-label *ngIf="!selectComponent.itemTemplate">
        {{ selectComponent._formatItem(item) }}
      </ion-label>
      <div *ngIf="selectComponent.itemEndTemplate" slot="end">
        <div
          [ngTemplateOutlet]="selectComponent.itemEndTemplate"
          [ngTemplateOutletContext]="{
            item: item,
            isItemSelected: selectComponent._isItemSelected(item)
          }"
        ></div>
      </div>
      <span
        *ngIf="selectComponent.itemIconTemplate"
        [ngTemplateOutlet]="selectComponent.itemIconTemplate"
        [ngTemplateOutletContext]="{
          item: item,
          isItemSelected: selectComponent._isItemSelected(item)
        }"
      >
      </span>
      <ion-icon
        *ngIf="!selectComponent.itemIconTemplate"
        [name]="selectComponent._isItemSelected(item) ? 'checkmark-circle' : 'radio-button-off'"
        [color]="selectComponent._isItemSelected(item) ? 'primary' : null"
        [slot]="selectComponent.itemIconSlot"
      >
      </ion-icon>
      <ion-button
        *ngIf="selectComponent.canSaveItem"
        class="ionic-selectable-item-button"
        slot="end"
        fill="outline"
        (click)="selectComponent._saveItem($event, item)"
      >
        <ion-icon slot="icon-only" name="md-create"></ion-icon>
      </ion-button>
      <ion-button
        *ngIf="selectComponent.canDeleteItem"
        class="ionic-selectable-item-button"
        slot="end"
        fill="outline"
        (click)="selectComponent._deleteItemClick($event, item)"
      >
        <ion-icon slot="icon-only" name="md-trash"></ion-icon>
      </ion-button>
    </ion-item>
  </cdk-virtual-scroll-viewport>
</ion-content>
<div
  class="ionic-selectable-add-item-template"
  *ngIf="selectComponent._isAddItemTemplateVisible"
  [ngStyle]="{ 'top.px': _header.offsetHeight }"
>
  <div
    class="ionic-selectable-add-item-template-inner"
    [ngStyle]="{ height: selectComponent._addItemTemplateFooterHeight }"
  >
    <span
      [ngTemplateOutlet]="selectComponent.addItemTemplate"
      [ngTemplateOutletContext]="{
        item: selectComponent._itemToAdd,
        isAdd: selectComponent._itemToAdd === null
      }"
    >
    </span>
  </div>
</div>
<ion-footer
  class="modal-footer"
  *ngIf="selectComponent._footerButtonsCount > 0 || selectComponent.footerTemplate"
  [ngStyle]="{ visibility: selectComponent._isFooterVisible ? 'initial' : 'hidden' }"
>
  <ion-button
    *ngIf="selectComponent.canClear"
    fill="clear"
    (click)="selectComponent._clear()"
    [disabled]="!selectComponent._selectedItems.length"
  >
    {{ selectComponent.clearButtonText }}
  </ion-button>
  <ion-button
    *ngIf="selectComponent.canAddItem"
    fill="clear"
    (click)="selectComponent._addItemClick()"
  >
    {{ selectComponent.addButtonText }}
  </ion-button>

  <ion-button
    color="primary"
    (click)="selectComponent._confirm()"
    *ngIf="selectComponent.isMultiple || selectComponent.hasConfirmButton"
    [disabled]="!selectComponent.isConfirmButtonEnabled"
  >
    {{ selectComponent.confirmButtonText }}
  </ion-button>

  <div
    *ngIf="selectComponent.footerTemplate"
    [ngTemplateOutlet]="selectComponent.footerTemplate"
  ></div>
</ion-footer>
