import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { NoContentFoundComponent } from './no-content-found.component';

@NgModule({
  imports: [TranslocoModule, CommonModule],
  providers: [],
  declarations: [NoContentFoundComponent],
  exports: [NoContentFoundComponent],
})
export class NoContentFoundModule {}
