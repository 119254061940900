import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import {
  LikeCounterDirective,
  LikeObjectDirective,
  LikesModalDirective,
} from 'src/app/shared/directives/user/user.like.directive';
import { InfiniteDataGridModule } from 'src/app/ui/infinite-data-grid/infinite-data-grid.module';
import { NgxVisibilityModule } from '../../modules/ngx-visibility/ngx-visibility.module';
import { NgxVisibilityService } from '../../modules/ngx-visibility/ngx-visibility.service';
import { PipesModule } from '../../pipes/pipes.module';
import { MediaModule } from '../media/media.module';
import { ScrollbarModule } from '../scrollbar/scrollbar.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { HeartLikesComponent } from './post-likes/heart-likes.component';
import { PostReactionsComponent } from './post-reactions/post-reactions.component';
import { SubscriberListModalComponent } from './subscriber-list-modal/subscriber-list-modal.component';
import { UserLikeComponent } from './user.like.component';
import { UserReactComponent } from './user.react.component';
import { ReactCounterDirective, ReactModalDirective } from './user.react.directive';
import { UILazyLoadModule } from 'src/app/ui/lazy-load/lazy-load.module';

@NgModule({
  imports: [
    NgxVisibilityModule,
    InfiniteDataGridModule,
    IonicModule,
    UILazyLoadModule,
    PipesModule,
    TranslocoModule,
    RouterModule,
    TooltipModule,
    ScrollbarModule,
    MediaModule,
    CommonModule,
  ],
  providers: [NgxVisibilityService],
  declarations: [
    LikeObjectDirective,
    SubscriberListModalComponent,
    LikeCounterDirective,
    HeartLikesComponent,
    LikesModalDirective,
    UserLikeComponent,
    PostReactionsComponent,
    ReactModalDirective,
    ReactCounterDirective,
    UserReactComponent,
  ],
  exports: [
    LikeObjectDirective,
    HeartLikesComponent,
    LikeCounterDirective,
    LikesModalDirective,
    UserLikeComponent,
    PostReactionsComponent,
    ReactModalDirective,
    ReactCounterDirective,
    UserReactComponent,
  ],
})
export class UserLikeModule {}
