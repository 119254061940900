<ng-template #empty></ng-template>
<div
  class="ui-content"
  [threshold]="0.5"
  [ngClass]="{
    'has-favorites': hasFavorites,
    'not-full-screen': !isFullScreen
  }"
  [ngStyle]="{ '--right-menu-width': rightMenuWidth + 'px' }"
  #content
  (visibilityChange)="onVisibilityChange($event)"
>
  <div
    class="ui-content-center is-mobile"
    [style.left.px]="contentPosX$ | async"
    [ngClass]="{
      'is-mobile': isMobile
    }"
  >
    <div
      class="ui-content-outer-left"
      [style.left.px]="leftMenuLeftPosX$ | async"
      [ngStyle]="{ '--left-menu-width': leftMenuWidth + 'px' }"
      #leftMenu
      *ngIf="hasLeftSideEnabled || (leftOnlyOnMobile && isMobile)"
      [ngClass]="{
        'ui-abs': isMobile,
        dark: forceDarkThemeLeft,
        'rounded hide-overflow': roundedLeft
      }"
    >
      <div class="ui-content-left outer-content scrollable">
        <div class="outer-content scrollable">
          <div class="wrapper outer-content-abs" #left>
            <ng-content select="[left]"></ng-content>
          </div>
        </div>
      </div>
    </div>
    <div class="center-inner-content">
      <div
        (click)="closeMenus($event)"
        class="ui-content-backdrop"
        [ngClass]="{
          closed: (!isMobile || !openLeftMenu) && (!isTabletMode || !openRightMenu)
        }"
      ></div>

      <div class="center-inner">
        <div class="outer-center-inner">
          <header
            class="page-header"
            *ngIf="
              hasHeader &&
              (hasLeftMenu || hasRightMenu || title || icon) &&
              (!headerOnlyOnMobile || (headerOnlyOnMobile && isMobile))
            "
          >
            <div class="page-content-none-tope">
              <div class="page-header-container">
                <div class="left-buttons" *ngIf="hasBackButton && hasBackHistory">
                  <button
                    class="menu-button undraggable"
                    (click)="goBack($event)"
                    routerDirection="back"
                  >
                    <ion-icon name="arrow-back-outline"></ion-icon>
                  </button>
                </div>
                <div class="left-buttons" *ngFor="let button of leftButtons$ | async">
                  <button
                    (click)="button.callback()"
                    class="menu-button undraggable"
                    [tooltip]="button.label || ''"
                    placement="bottom"
                  >
                    <ion-icon [name]="button.icon"></ion-icon>
                  </button>
                </div>

                <div class="left-buttons-start" *ngIf="hasHeaderLeft">
                  <ng-content class="left-buttons" select="[headerLeft]"></ng-content>
                </div>
                <div *ngIf="title || icon" class="cover-image">
                  <div class="widget-image icon-menu" *ngIf="icon">
                    <ion-skeleton-text [animated]="true"></ion-skeleton-text>
                    <div class="widget-image-container">
                      <img
                        loading="lazy unloaded"
                        (load)="imageLoaded($event)"
                        [alt]="title"
                        [src]="icon"
                      />
                    </div>
                  </div>
                  <div class="page-title-outer">
                    <div class="page-title-inner">
                      <h1 class="page-title" *ngIf="title">{{ title }}</h1>
                    </div>
                    <p class="page-desc" *ngIf="desc">
                      <ng-container [ngTemplateOutlet]="descTemplate" *ngIf="isDescTemplate">
                      </ng-container>
                      <ng-container *ngIf="!isDescTemplate">{{ descString }}</ng-container>
                    </p>

                    <p
                      class="page-desc"
                      *ngIf="amount && amount >= 0"
                      transloco="store.results"
                      [translocoParams]="{ numResults: amount }"
                    ></p>
                  </div>
                </div>

                <div class="right-buttons-start">
                  <ng-content class="right-buttons" select="[headerRight]"></ng-content>
                </div>
                <div class="right-buttons" *ngIf="hasRightMenu">
                  <button
                    (click)="button.callback()"
                    class="menu-button undraggable"
                    [tooltip]="button.label || ''"
                    *ngFor="let button of rightButtons$ | async"
                    placement="bottom"
                  >
                    <ion-icon [name]="button.icon"></ion-icon>
                  </button>
                </div>
              </div>
            </div>
          </header>
          <div
            class="outer-content scrollable"
            [ngClass]="{ rounded: rounded }"
            #scrollbar
            [windowResizeTrigger]="windowResize"
            id="scroll-{{ windowResize }}"
          >
            <div class="outer-content-abs">
              <div class="wrapper" #center><ng-content select="[center]"></ng-content></div>
            </div>
          </div>
        </div>

        <div
          *ngIf="hasRightSideEnabled"
          [style.right.px]="rightMenuPosX$ | async"
          class="ui-content-outer-right"
          [ngClass]="{
            'ui-abs': isTabletMode || isMobile,
            'rounded hide-overflow': rounded
          }"
        >
          <div class="ui-content-right">
            <div class="outer-content scrollable">
              <div class="wrapper outer-content-abs" #right>
                <ng-content select="[right]"></ng-content>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
