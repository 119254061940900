import { isPlatformBrowser } from '@angular/common';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LangService } from 'src/app/shared/services/lang/lang.service';
import { AuthService } from './auth.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  isBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private langService: LangService,
    private authService: AuthService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  /**
   * Intercept a http request to handle the rest token
   * @todo Problem after timeout of a token (site is reloading 3 times)
   * @param request
   * @param next
   * @returns
   */
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let headers: {
      [name: string]: string | string[];
    } = {};

    if (this.isBrowser) {
      if (this.authService.userStateData && this.authService.userStateData.language) {
        headers['Accept-Language'] =
          this.authService.userStateData.language + ';q=0.9,en-US,en;q=0.8';
      } else {
        headers['Accept-Language'] = this.langService.currentLangSlug + ';q=0.9,en-US,en;q=0.8';
      }
      return next.handle(request.clone({ setHeaders: headers }));
    } else {
      headers['Accept-Language'] = this.langService.currentLangSlug + ';q=0.9,en-US,en;q=0.8';
      return next.handle(request.clone({ setHeaders: headers }));
    }
  }
}
