import { Subscription } from 'rxjs';
import { CoreModelInterface } from 'src/app/shared/models/core.model';
import { SocketPrivateChannel } from 'src/app/shared/services/socket/client/socket-private-channel';
import { ChatMember } from './chat.message';

export enum ChatRoomType {
  User = 'user',
  Group = 'group',
}

export interface ChatRoomInstance extends CoreModelInterface {
  type: ChatRoomType;
  title: string;
  image: string;
  unreaded: number;
  otherUser: number | null;
  members: ChatMember[];
  owner: number;
  channelSubscription?: SocketPrivateChannel;
  commandSub?: Subscription;
}
