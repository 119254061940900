import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[uiNavigationContext]',
})
export class UINavigationContextDirective {
  private origDisplay: string | undefined = undefined;
  public isDirty = true;
  constructor(private renderer: Renderer2, private host: ElementRef) {
    this.origDisplay = this.host.nativeElement.style.display;
    this.host.nativeElement.style.display = 'none';
  }
  getRootElement(): ElementRef<any> {
    return this.host;
  }
  moveToNewNode(node: any) {
    if (!this.isDirty) {
      return;
    }
    this.renderer.appendChild(node, this.getRootElement().nativeElement);
    this.host.nativeElement.style.display = this.origDisplay;
    this.isDirty = false;
  }
}
