import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { InviteModalModule } from 'src/app/b2c/friends/components/invite-modal/invite-modal.module';
import { MediaModule } from 'src/app/shared/directives/media/media.module';
import { ScrollbarModule } from 'src/app/shared/directives/scrollbar/scrollbar.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { UIModule } from 'src/app/ui/ui.module';
import { TooltipModule } from '../shared/directives/tooltip/tooltip.module';
import { WindowSizeModule } from '../shared/directives/window-size/window-size.module';
import { LoadingContentModule } from '../ui/loading-content/loading-content.module';
import { NoContentFoundModule } from '../ui/no-content-found/no-content-found.module';
import { B2CComponent } from './b2c.component';
import { SidebarIconsModule } from './layout/sidebar-icons.modal';
import { StreamPrepareModule } from './streams/components/stream-prepare-modal/stream-pepare-modal.module';
import { ShortcutModule } from './favorites/shortcuts/shortcut.module';

@NgModule({
  providers: [AuthStateService, CurrencyPipe],
  imports: [
    CommonModule,
    UIModule,
    ShortcutModule,

    MediaModule,
    PipesModule,
    NoContentFoundModule,
    LoadingContentModule,
    RouterModule.forChild([]),
    WindowSizeModule,
    StreamPrepareModule,
    DragDropModule,
    InviteModalModule,
    TranslocoModule,
    ScrollbarModule,
    FormsModule,
    TooltipModule,
    IonicModule,
    SidebarIconsModule,
  ],
  declarations: [B2CComponent],
  exports: [B2CComponent],
})
export class B2CModule {}
