import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Platform } from '@ionic/angular';
import { WindowSizeService } from '../../services/window-size.service';

@Directive({
  selector: '[windowResizeTrigger]',
})
export class WindowSizeDirective implements OnInit, OnDestroy {
  constructor(
    private host: ElementRef<HTMLDivElement | HTMLElement>,
    private sizeService: WindowSizeService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private platform: Platform
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  ngOnDestroy(): void {
    this.observer?.unobserve(this.host.nativeElement);
  }

  public isBrowser = false;
  @Input() windowResizeTrigger = 'body';
  private observer: ResizeObserver | null = null;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.host.nativeElement.clientWidth > 0) {
      this.sizeService.setWidth(
        this.host,
        this.windowResizeTrigger,
        this.host.nativeElement.clientWidth
      );
    }
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      this.sizeService.setWidth(
        this.host,
        this.windowResizeTrigger,
        this.host.nativeElement.clientWidth
      );
      this.observer = new ResizeObserver(entries => {
        this.sizeService.setWidth(
          this.host,
          this.windowResizeTrigger,
          this.host.nativeElement.clientWidth
        );
      });
      this.observer.observe(this.host.nativeElement);
    } else {
      this.sizeService.setWidth(this.host, this.windowResizeTrigger, 1024);
    }
  }
}
