import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxTippyService } from 'ngx-tippy-wrapper';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';

@Component({
  selector: 'app-home-navigation-icons',
  templateUrl: './home-navigation-icons.component.html',
  styleUrls: ['./home-navigation-icons.component.scss'],
})
export class HomeNavigationIconsComponent {
  @ViewChild('tippyTemplate', { read: ElementRef, static: true }) tippyTemplate:
    | ElementRef
    | undefined = undefined;

  hide(event: any, route: string) {
    event.stopPropagation();
    event.preventDefault();
    this.tippyService.hideAll();

    this.router.navigateByUrl(route);
  }
  get isB2B() {
    return this.router.url.startsWith('/app/b2b');
  }
  constructor(
    public router: Router,
    private tippyService: NgxTippyService,
    public authState: AuthStateService,
    @Inject(DOCUMENT) public document: Document
  ) {}

  isActive() {
    return (
      this.router.url.startsWith('/app/dashboard') ||
      this.router.url.startsWith('/app/market') ||
      this.router.url.startsWith('/app/game-studios') ||
      this.router.url == '/' ||
      this.router.url == '/home' ||
      this.router.url.startsWith('/app/browse') ||
      this.router.url.startsWith('/app/realms')
    );
  }
}
