<div class="search-outer">
  <div class="search-icon"><ion-icon name="search"></ion-icon></div>
  <input
    (focus)="enableFocus(true)"
    (focusout)="enableFocus(false)"
    class="input-field"
    [placeholder]="placeholder"
    (ngModelChange)="onSearchChange($event)"
    [ngModel]="value"
  />
  <button (click)="reset()" class="clear-button" *ngIf="value && value.length > 0">
    <ion-icon name="close-circle"></ion-icon>
  </button>
</div>
