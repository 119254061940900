import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-invite-friends-list-item',
  templateUrl: './invite-friend-list-item.component.html',
  styleUrls: ['./invite-friend-list-item.component.scss'],
})
export class InviteFriendsListItem implements OnInit {
  @Input() item: any = {};
  @Input() last: boolean = false;
  @Input() isDisabled: boolean = false;
  @Output() selectionChanged = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  onChange(event: any) {
    this.selectionChanged.emit(event.detail.checked);
  }
}
