import { isPlatformBrowser, Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Injector,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ActivationEnd, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { MetafrenzyService } from 'ngx-metafrenzy';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { MenuDetails } from 'src/app/shared/models/menu/menu.records';
import { environment } from 'src/environments/environment';
import { CloudUriPipe } from '../directives/media/cloud-url.pipe';
import { PreRouteService } from '../services/router/router.service';
import { SubLevelPage } from './sub-level-page';
import { SubLevelRestPage } from './sub-level-rest-page';
import { TopLevelPage } from './top-level-page';
import { UIPage } from './ui-page';

@Component({ template: '' })
export abstract class CoreTopLevelPage extends UIPage implements OnDestroy, OnInit, AfterViewInit {
  data: any = {};
  isBrowser = false;

  @ViewChild(RouterOutlet, { static: true }) outlet: RouterOutlet | undefined;

  protected router: Router;
  protected location: Location;
  protected nav: NavController;
  protected translate: TranslocoService;
  protected preRouteService: PreRouteService;

  protected menuDetailsHolder = new BehaviorSubject<MenuDetails>({});
  public menuDetailsSub = this.menuDetailsHolder.asObservable();

  protected currentRoute = '';
  private metafrenzyService: MetafrenzyService;

  constructor(protected injector: Injector) {
    super(injector);
    this.isBrowser = isPlatformBrowser(injector.get(PLATFORM_ID));

    this.router = injector.get(Router);
    this.translate = injector.get(TranslocoService);
    this.location = injector.get(Location);
    this.nav = injector.get(NavController);
    this.preRouteService = injector.get(PreRouteService);
    this.metafrenzyService = injector.get(MetafrenzyService);

    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        if (ev.urlAfterRedirects == this.currentRoute) {
          this.updateMeta();
          this.afterReinit();
        }
      }
    });
  }

  /**
   * Function is triggered after re init
   */
  public afterReinit() {}
  /**
  /**
   * Override seo title
   * @returns string|null
   */
  public getTitle(): string | null {
    return null;
  }

  /**
   * Override seo desc
   * @returns string|null
   */
  public getDesc(): string | null {
    return null;
  }

  /**
   * Override meta image
   * @returns string|null
   */
  public getMetaImage(): string | null {
    return null;
  }

  /**
   * Override seo keywords
   * @returns string|null
   */
  public getKeywords(): string[] | null {
    return null;
  }

  protected updateMeta() {
    let title = '';

    title = 'Striked — A gaming universe';
    let replaceTitle = this.getTitle();
    if (replaceTitle != null) {
      title = replaceTitle + ' — Striked';
    }

    let desc = this.getDesc();
    if (desc == null) {
      desc =
        'Striked offers a fully automated platform for small and large game development studios to create, sell, and distribute video games worldwide.';
    }

    const fullUrl = environment.frontendURL.slice(0, -1) + this.router.url;

    const metaImage = this.getMetaImage();
    const metaImageDefault =
      metaImage != null ? metaImage : environment.frontendURL + 'assets/striked.png';
    this.metafrenzyService.setTags({
      image: metaImageDefault,
      url: fullUrl,
      title: title,
      description: desc,
    });

    this.metafrenzyService.setMetaTag('twitter:image', metaImageDefault);
    this.metafrenzyService.setMetaTag('image_src', metaImageDefault);
    this.metafrenzyService.setMetaTag('twitter:card', 'summary_large_image');
    this.metafrenzyService.setMetaTag('twitter:site', '@joinstriked');

    let keywords = this.getKeywords();
    let keywordsStr = '';
    if (keywords == null || keywords.length <= 0) {
      keywordsStr = 'game development, game distribution, game publishing, game sales, game store';
    } else {
      keywordsStr = keywords.join(', ');
    }

    this.metafrenzyService.setMetaTag('keywords', keywordsStr);
  }

  /**
   * @description Determines after page is activated
   * @author Stefan Boronczyk <stefan@strikd.com>
   */
  public abstract onActivation(): void;

  /**
   * @description Determines after page will be destroyed
   * @author Stefan Boronczyk <stefan@strikd.com>
   */
  public abstract onDeactivation(): void;

  initalize() {}

  ngOnInit(): void {
    super.ngOnInit();
    this.currentRoute = this.router.url.toString();
    if (this.outlet?.isActivated) {
      if (
        this.outlet.component instanceof SubLevelPage ||
        this.outlet.component instanceof SubLevelRestPage
      ) {
        (this.outlet.component as SubLevelPage).topLevel = this as TopLevelPage;
      }
    }

    this.outlet?.activateEvents.subscribe(component => {
      if (component instanceof SubLevelPage || component instanceof SubLevelRestPage) {
        (component as SubLevelPage).topLevel = this as TopLevelPage;
      }
    });

    if (!this.isBrowser) {
      this.onActivation();
      this.updateMeta();
      this.changeDetectorRef.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    this.update();
  }

  reload() {
    this.router.navigated = false;
    this.router.navigate([this.router.url]).then(() => {});
  }

  update() {
    if (this.isBrowser) {
      this.onActivation();
      this.updateMeta();
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.onDeactivation();
    super.ngOnDestroy();
  }
}
