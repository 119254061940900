import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InfiniteDataGridComponent } from '../infinite-data-grid.component';

@Component({
  selector: 'app-infinite-data-grid-search',
  templateUrl: './infinite-data-grid-search.component.html',
  styleUrls: ['./infinite-data-grid-search.component.scss'],
})
export class InfiniteDataGridSearchComponent implements OnInit {
  ngOnInit(): void {
    if (this.dataGridRef) {
      this.dataGridRef.searchEnabled = true;
    }
  }
  @Output() searchRequest = new EventEmitter<string | null>();
  @Input() dataGridRef: InfiniteDataGridComponent | undefined = undefined;

  @Input() disabled = false;

  public searchTerm: string | null = null;

  search(event: any) {
    if (this.disabled) {
      return;
    }

    this.dataGridRef?.search(event);
    this.searchRequest?.next(event);
  }
}
