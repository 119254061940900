import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate, UpdateActivatedEvent, UpdateAvailableEvent } from '@angular/service-worker';
import { GestureController, GestureDetail } from '@ionic/angular';
import {
  BehaviorSubject,
  fromEvent,
  mapTo,
  merge,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import { StorageService } from './storage.service';
export const MOBILE_WIDTH = 750;
export const TABLET_WIDTH = 1280;
export const LOWER_TABLET_WIDTH = 1000;

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private developerModeState = new BehaviorSubject<boolean>(false);
  public isDeveloperMode = this.developerModeState.asObservable();

  private swUpdatesEvent = new BehaviorSubject('');
  public swUpdates = this.swUpdatesEvent.asObservable();

  public theme = 'dark';
  private isBrowser = false;

  private isMainMenuVisible = true;
  private isLeftMenuIsVisible = true;
  private isRightMenuIsVisible = true;

  private isMobileMenuVisible = false;

  private mainMobileMenu = new BehaviorSubject<boolean>(this.isMobileMenuVisible);
  public mainMobileMenuSub = this.mainMobileMenu.asObservable();

  private _isTabletMode = false;
  private isTabletMode = new BehaviorSubject<boolean>(this._isTabletMode);
  public isTabletMode$ = this.isTabletMode.asObservable();

  private _isLowerTabletMode = false;
  private isLowerTabletMode = new BehaviorSubject<boolean>(this._isLowerTabletMode);
  public isLowerTabletMode$ = this.isLowerTabletMode.asObservable();

  private leftMenuOpen = new BehaviorSubject<boolean>(this.isMobileMenuVisible);
  public leftMenuOpenSub = this.leftMenuOpen.asObservable();

  private rightMenuOpen = new BehaviorSubject<boolean>(this.isRightMenuIsVisible);
  public rightMenuOpenSub = this.rightMenuOpen.asObservable();

  private _gesture = new Subject<GestureDetail>();
  public gesture$ = this._gesture.asObservable();
  private _gestureEnd = new Subject<void>();
  public gestureEnd$ = this._gestureEnd.asObservable();

  public set visibleMobileMenu(value: boolean) {
    if (this.isMobileMenuVisible != value) {
      this.isMobileMenuVisible = value;
      this.mainMobileMenu.next(this.isMobileMenuVisible);
    }
  }

  doResize(width: number) {
    if (width < MOBILE_WIDTH) {
      this.visibleMobileMenu = true;
    } else {
      this.visibleMobileMenu = false;
    }

    if (this._isTabletMode != width < TABLET_WIDTH) {
      this._isTabletMode = width < TABLET_WIDTH;
      this.isTabletMode.next(this._isTabletMode);
    }
    if (this._isLowerTabletMode != width < LOWER_TABLET_WIDTH) {
      this._isLowerTabletMode = width < LOWER_TABLET_WIDTH;
      this.isLowerTabletMode.next(this._isLowerTabletMode);
    }

    if (width < MOBILE_WIDTH != this.visibleLeftUiMenu) {
      this.visibleLeftUiMenu = width < MOBILE_WIDTH;
    }
    if (width < MOBILE_WIDTH != this.visibleLeftUiMenu) {
      this.visibleLeftUiMenu = width < MOBILE_WIDTH;
    }
  }

  public get visibleMobileMenu(): boolean {
    return this.isMobileMenuVisible;
  }

  public get visibleLeftUiMenu(): boolean {
    return this.isLeftMenuIsVisible;
  }

  public set visibleLeftUiMenu(value: boolean) {
    if (this.isLeftMenuIsVisible != value) {
      this.isLeftMenuIsVisible = value;

      this.leftMenuOpen.next(this.isLeftMenuIsVisible);
    }
  }

  private updates: SwUpdate | null = null;

  private _frameSize: number = 1024;
  private _frameSize$ = new BehaviorSubject<number>(this._frameSize);
  public frameSize$ = this._frameSize$.asObservable();

  resizeObservable$: Observable<Event> | null = null;
  resizeSubscription$: Subscription | null = null;

  public subscribeWidth() {
    if (this.isBrowser) {
      this._frameSize = window.innerWidth;
      this._frameSize$.next(this._frameSize);
      this.doResize(this._frameSize);

      this.resizeObservable$ = fromEvent(window, 'resize');
      this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
        if (evt != null && evt.target != null) {
          this._frameSize = (evt.target as Window).innerWidth || 0;
          this._frameSize$.next(this._frameSize);

          this.doResize(this._frameSize);
        }
      });
    } else {
      this._frameSize = 1024;
      this._frameSize$.next(this._frameSize);
      this.doResize(this._frameSize);
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private storage: StorageService,
    private injector: Injector,
    public gestureCtrl: GestureController,

    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.parseUrl(this.router.url);
      }
    });

    this.parseUrl(router.url); // to print only path eg:"/login"
    this.initTheme();

    if (this.isBrowser) {
      this.updates = injector.get(SwUpdate);

      this.updates.available.subscribe((event: UpdateAvailableEvent) => {
        console.log('[ Available ] current version: ', event.current);
        console.log('[ Available ] available version: ', event.available);

        if (event.available) {
          // notify subscribers of a new app version
          this.swUpdatesEvent.next(event.available.hash.slice(0, 4));
        }
      });

      // Notify the service when service worker starts serving a new version of the application immediately
      this.updates.activated.subscribe((event: UpdateActivatedEvent) => {
        console.log('[ Activated ] old version was: ', event.previous);
        console.log('[ Activated ] new version is: ', event.current);
      });

      if (this.isBrowser && window.document) {
        const emz = window.document;
        const gesture = this.gestureCtrl.create({
          el: emz,
          gestureName: 'move-left',
          onMove: (detail: GestureDetail) => {
            this._gesture.next(detail);
          },
          onEnd: () => {
            this._gestureEnd.next();
          },
        });

        gesture.enable();
      }
    }
  }

  reloadApp() {
    return this.updates?.activateUpdate().then(() => document.location.reload());
  }

  // the online status of the application
  get online() {
    return merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );
  }

  initTheme() {
    if (!this.isBrowser) {
      this.theme = 'dark';
    } else {
      const res = this.storage.get('theme');

      if (res == null) {
        this.theme = 'dark';
      } else if (res == 'light') {
        this.theme = 'light';
      } else {
        this.theme = 'dark';
      }
      console.debug('[App][Theme] ' + this.theme);
    }
  }

  setTheme(mode: string) {
    this.storage.set('theme', mode);
    this.theme = mode;
  }

  private parseUrl(url: string) {
    if (url.startsWith('/dev')) {
      this.developerModeState.next(true);
    } else this.developerModeState.next(false);
  }
}
export function initializeWidth(quoteOFTheDayService: AppService) {
  return () => quoteOFTheDayService.subscribeWidth();
}
