import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { InviteModalModule } from 'src/app/b2c/friends/components/invite-modal/invite-modal.module';
import { MediaModule } from 'src/app/shared/directives/media/media.module';
import { ScrollbarModule } from 'src/app/shared/directives/scrollbar/scrollbar.module';
import { TooltipModule } from 'src/app/shared/directives/tooltip/tooltip.module';
import { WindowSizeModule } from 'src/app/shared/directives/window-size/window-size.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { LoadingContentModule } from 'src/app/ui/loading-content/loading-content.module';
import { NoContentFoundModule } from 'src/app/ui/no-content-found/no-content-found.module';
import { UIModule } from 'src/app/ui/ui.module';
import { AppShortcutItemComponent } from './app-shortcut-item/app-shortcut-item.component';
import { ShortcutsQuickActionComponent } from './shortcut-quick-action.component';
import { ShortcutsComponent } from './shortcuts.component';

@NgModule({
  providers: [],
  imports: [
    CommonModule,
    UIModule,
    MediaModule,
    PipesModule,
    NoContentFoundModule,
    LoadingContentModule,
    RouterModule.forChild([]),
    WindowSizeModule,
    DragDropModule,
    InviteModalModule,
    TranslocoModule,
    ScrollbarModule,
    FormsModule,
    TooltipModule,
    IonicModule,
  ],
  declarations: [ShortcutsQuickActionComponent, ShortcutsComponent, AppShortcutItemComponent],
  exports: [ShortcutsComponent, ShortcutsQuickActionComponent, AppShortcutItemComponent],
})
export class ShortcutModule {}
