import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiSectionHeaderComponent } from './ui-section-header/ui-section-header.component';
import { TranslocoModule } from '@ngneat/transloco';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [CommonModule, TranslocoModule, IonicModule],
  declarations: [UiSectionHeaderComponent],
  exports: [UiSectionHeaderComponent],
})
export class UISectionModule {}
