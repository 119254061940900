import { Channel } from './channel';
import { PresenceChannel } from './presence-channel';

export abstract class Connector {
  /**
   * Default connector options.
   */
  private _defaultOptions: any = {
    auth: {
      headers: {},
    },
    authEndpoint: '/broadcasting/auth',
    broadcaster: 'pusher',
    csrfToken: null,
    host: null,
    key: null,
    namespace: 'App.Events',
  };

  /**
   * Connector options.
   */
  options: any;

  /**
   * Merge the custom options with the defaults.
   */
  public setOptions(options: any): any {
    this.options = Object.assign(this._defaultOptions, options);

    if (this.csrfToken()) {
      this.options.auth.headers['X-CSRF-TOKEN'] = this.csrfToken();
    }

    return options;
  }

  /**
   * Extract the CSRF token from the page.
   */
  protected csrfToken(): string | null {
    let selector;

    if (this.options.csrfToken) return this.options.csrfToken;

    return null;
  }

  /**
   * Create a fresh connection.
   */
  abstract connect(): void;

  /**
   * Get a channel instance by name.
   */
  abstract channel(channel: string): Channel;

  /**
   * Get a private channel instance by name.
   */
  abstract privateChannel(channel: string): Channel;

  /**
   * Get a presence channel instance by name.
   */
  abstract presenceChannel(channel: string): PresenceChannel;

  /**
   * Leave the given channel, as well as its private and presence variants.
   */
  abstract leave(channel: string): void;

  /**
   * Leave the given channel.
   */
  abstract leaveChannel(channel: string): void;

  /**
   * Get the socket_id of the connection.
   */
  abstract socketId(): string | null;

  /**
   * Disconnect from the Echo server.
   */
  abstract disconnect(): void;
}
