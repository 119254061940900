import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, NgModule, PLATFORM_ID } from '@angular/core';
import { makeStateKey, StateKey, TransferState } from '@angular/core';
import {
  Translation,
  translocoConfig,
  TranslocoLoader,
  TranslocoModule,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
} from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { de } from 'src/app/shared/lang/de';
import { en } from 'src/app/shared/lang/en';
import { RestService } from '../services/rest/rest.service';

@Injectable({ providedIn: 'root' })
export class TranslocoBrowserHttpLoader implements TranslocoLoader {
  isBrowser = false;

  constructor(
    private transferState: TransferState,
    private restService: RestService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  getTranslation(lang: string): Observable<any> {
    if (environment.useHTTPLang) {
      return this.restService.get<any>(`framework/language/${lang}.json?keyed=true`);
    } else {
      return new Observable(observer => {
        if (lang == 'de') {
          observer.next(de);
        } else {
          observer.next(en);
        }
        observer.complete();
      });
    }
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: environment.availableLanguages,
        defaultLang: 'en',
        missingHandler: {
          allowEmpty: true,
          logMissingKey: false,
          // It will use the first language set in the `fallbackLang` property
          useFallbackTranslation: true,
        },
        reRenderOnLangChange: true,
        prodMode: environment.production,
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoBrowserHttpLoader },
  ],
})
export class TranslateBrowserLoaderModule {}
