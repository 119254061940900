import { Component } from '@angular/core';
import { UIModal } from 'src/app/shared/framework/modal/ui-modal';
import { UIModalRest } from 'src/app/shared/framework/modal/ui-modal-rest';
import { UITabAction } from 'src/app/ui/ui-side-menu/ui-side-menu.component';
import { StreamPrepareSettingsComponent } from './stream-prepare-settings/stream-prepare-settings.component';

@Component({
  selector: 'app-stream-prepare-modal',
  templateUrl: './stream-prepare-modal.component.html',
  styleUrls: ['./stream-prepare-modal.component.scss'],
})
export class StreamPrepareModalComponent extends UIModal<any> {
  OnModalOpened() {
    var tabActions: UITabAction[] = [
      {
        key: 'settings',
        name: 'stream.settings.title',
        component: StreamPrepareSettingsComponent,
        icon: 'cog',
      } as UITabAction,
    ];

    this.activeTab = tabActions[0];

    this.tabs.push({
      name: this.translate.translate('shortcuts.actions.create_stream'),
      actions: tabActions,
    });
  }
}
