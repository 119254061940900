import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { DesktopService } from 'src/app/shared/services/electron.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements AfterViewInit {
  isBrowser = false;

  constructor(
    public authState: AuthStateService,
    public menu: MenuController,
    public desktopService: DesktopService,
    @Inject(DOCUMENT) private document: Document,

    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterViewInit(): void {
    if (this.document) {
      this.document.body.classList.add('hide-splash');
    }
  }
}
