import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonPopover } from '@ionic/angular';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';

@Component({
  selector: 'app-user-navigation-icons',
  templateUrl: './user-navigation-icons.component.html',
  styleUrls: ['./user-navigation-icons.component.scss'],
})
export class UserNavigationIconsComponent implements OnInit, OnDestroy {
  constructor(public authService: AuthStateService) {}
  @ViewChild('actions', { static: true }) actions: IonPopover | undefined = undefined;

  ngOnDestroy(): void {}
  ngOnInit(): void {}
  doLogout() {
    this.authService.logout();
  }

  async presentPopover(ev: any) {
    await this.actions?.present(ev);
  }
}
