<div
  class="app"
  [ngClass]="{
    electron: desktopService.isDesktop(),

    dark: appService.theme == 'dark' || appService.theme == '',
    light: appService.theme == 'light'
  }"
>
  <div class="app-electron-title draggable" *ngIf="desktopService.isDesktop()">
    <div class="app-electron-title-brand">Striked</div>
  </div>
  <ion-progress-bar
    [style.display]="!isLoading ? 'none' : 'block'"
    class="overall-loader"
    color="primary"
    type="indeterminate"
  >
  </ion-progress-bar>

  <div
    class="notify-page-alert only-on-desktop draggable"
    *ngIf="appService.swUpdates | async as update"
  >
    <span class="info" transloco="update_available.desc" [translocoParams]="{ update: update }">
    </span>

    <button
      class="btn btn-xxxs btn-solid warning uppercase undraggable"
      (click)="appService.reloadApp()"
    >
      <span transloco="update_available.reload"></span>
    </button>
  </div>
  <div
    class="notify-page-alert"
    [ngClass]="authState.currentMessage.color"
    *ngIf="authState.currentMessage != null"
  >
    {{ authState.currentMessage.message }}
  </div>
  <div class="outer-content scrollable">
    <router-outlet></router-outlet>
  </div>
  <div class="notify-page-alert only-on-desktop dark draggable">
    <span class="info" transloco="early_access_notice"> </span>

    <button
      class="btn btn-xxxs btn-solid contrast uppercase undraggable"
      (click)="createIssueModal($event)"
    >
      <span transloco="issue_tracker.create_issue"></span>
    </button>
  </div>
</div>

<audio *ngIf="isBrowser" id="notification" src="./assets/notify.wav" muted></audio>
<audio *ngIf="isBrowser" id="leave-sound" src="./assets/leave.wav" muted></audio>
<audio *ngIf="isBrowser" id="join-sound" src="./assets/enter.wav" muted></audio>
<audio *ngIf="isBrowser" id="mute-sound" src="./assets/mute.wav" muted></audio>
<audio *ngIf="isBrowser" id="unmute-sound" src="./assets/unmute.wav" muted></audio>
<audio *ngIf="isBrowser" id="share-screen-sound" src="./assets/share.wav" muted></audio>
