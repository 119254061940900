import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { LoadingContentModule } from '../loading-content/loading-content.module';
import { NoContentFoundModule } from '../no-content-found/no-content-found.module';
import { InfiniteDataGridComponent } from './infinite-data-grid.component';
import { InfiniteDataGridSearchComponent } from './infinite-data-grid-search/infinite-data-grid-search.component';
import { InfiniteScrollModule } from '../infinite-scroll/ngx-infinite-scroll.module';
import { UISearchbarModule } from '../ui-searchbar/ui-searchbar.module';

@NgModule({
  imports: [
    IonicModule,
    InfiniteScrollModule,
    NoContentFoundModule,
    UISearchbarModule,
    LoadingContentModule,
    TranslocoModule,
    CommonModule,
  ],
  providers: [],
  declarations: [InfiniteDataGridComponent, InfiniteDataGridSearchComponent],
  exports: [InfiniteDataGridComponent, InfiniteDataGridSearchComponent],
})
export class InfiniteDataGridModule {}
