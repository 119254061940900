import { KeyValue } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IonPopover } from '@ionic/angular';
import { RestService } from 'src/app/shared/services/rest/rest.service';
import {
  FormBuilderTagResult,
  FormBuilderInput,
  FormBuilderOption,
} from '../../form-builder-options';

@Component({
  selector: 'app-tag-component',
  templateUrl: './tag-component.component.html',
  styleUrls: ['./tag-component.component.scss'],
})
export class TagComponentComponent {
  @Input() options: FormBuilderOption[] = [];

  get allOptions() {
    return this.options.filter(df => df != null) || [];
  }
  @Input() value: any = null;
  @Input() key: any = null;
  @Input() multipleSelection: boolean = false;

  @Output() addOrDeleteTag = new EventEmitter<any>();
  @ViewChild('dropDownSelect', { static: true }) popover: IonPopover | undefined = undefined;
  @ViewChild('coverTrigger', { static: true }) coverTrigger:
    | ElementRef<HTMLInputElement>
    | undefined = undefined;

  isDisabled() {
    return (
      this.value.max &&
      this.value.max > 0 &&
      this.allOptions &&
      this.allOptions.length >= this.value.max
    );
  }
  tagResults: FormBuilderTagResult[] = [];
  constructor(public restService: RestService) {}

  isOpen: boolean = false;
  async doTagSearch(event: KeyboardEvent) {
    const term = (event.target as HTMLInputElement).value || '';
    if (this.value.tagSearchCallback != undefined) {
      this.value.tagSearchCallback(term, this.restService).subscribe((df: any) => {
        let currentValues = this.allOptions.map(df => df.key);
        this.tagResults = df.filter((df: any) => !currentValues.includes(df.key));
      });
    }
  }

  async doDelete(event: KeyboardEvent) {
    if (
      this.coverTrigger &&
      this.coverTrigger.nativeElement.value == '' &&
      event.key == 'Backspace'
    ) {
      if (this.allOptions && this.allOptions.length > 0) {
        this.emitValue(this.allOptions[this.allOptions.length - 1]);
      }
    }
  }

  log(event: any) {}

  emitValue(selectedKey: any) {
    this.tagResults = [];
    this.addOrDeleteTag.next(selectedKey);
    if (this.coverTrigger) {
      this.coverTrigger.nativeElement.value = '';
    }
  }
}
