import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { AppService } from 'src/app/shared/services/app.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent {
  constructor(
    public appService: AppService,
    public authState: AuthStateService,
    private router: Router
  ) {}
}
