import { map } from 'rxjs/operators';
import moment from 'moment-timezone';
import { CoreModel } from 'src/app/shared/models/core.model';

export enum FriendshipState {
  None,
  Pending,
  Accepted,
  Declined,
  OwnProfile,
  PendingSend,
}
export enum OnlineState {
  Offline = 'offline',
  Online = 'online',
  Busy = 'busy',
}
export interface FriendshipUserData {
  id: number;
  custom_id: string;
  image: string;
  name: string;
  slug: string;
}
export class Friendship extends CoreModel {
  public stateName: string;
  public userData: FriendshipUserData = {
    id: 0,
    custom_id: '',
    image: '',
    name: '',
    slug: '',
  };
  public isRequest: boolean;
  public requestedAt: string;
  public timestamp: number;
  public onlineState: OnlineState = OnlineState.Offline;

  get onlineStateAsClassName(): string {
    return this.onlineState.toString().toLowerCase();
  }
  get state(): FriendshipState {
    switch (this.stateName) {
      case 'pending':
        return this.isRequest ? FriendshipState.Pending : FriendshipState.PendingSend;
        break;
      case 'accepted':
        return FriendshipState.Accepted;
        break;
      case 'declined':
        return FriendshipState.Declined;
        break;
      default:
        return FriendshipState.None;
        break;
    }
  }

  setOnlineState(state: string) {
    if (state == 'online') {
      this.onlineState = OnlineState.Online;
      return;
    } else if (state == 'busy') {
      this.onlineState = OnlineState.Busy;
      return;
    }
    this.onlineState = OnlineState.Offline;
  }

  constructor(
    friendShipId: number,
    userState: string,
    userData: FriendshipUserData,
    state: string,
    isRequest: boolean,
    _requestedAt: string = '',
    _timestamp: number = 0
  ) {
    super();
    this.id = friendShipId;
    this.setOnlineState(userState);
    this.userData = userData;
    this.stateName = state;
    this.isRequest = isRequest;
    this.requestedAt = _requestedAt;
    this.timestamp = _timestamp;
  }
}
