import { List } from 'immutable';
import { BehaviorSubject, Observable } from 'rxjs';
import { CoreModel, CoreModelInterface } from '../models/core.model';

export class ResourceList<T extends CoreModelInterface> {
  protected valuesHolder: BehaviorSubject<List<T>> = new BehaviorSubject(List<T>([]));

  protected lastRecord: List<T> = List<T>();

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public readonly values: Observable<List<T>> = this.valuesHolder.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public identifier = 'id';

  getList(): List<T> {
    return this.lastRecord;
  }

  setValues(newValues: T[]) {
    this.lastRecord = List<T>(newValues);
    this.valuesHolder.next(this.lastRecord);
  }

  setList(_list: List<T>) {
    this.lastRecord = _list;
    this.valuesHolder.next(this.lastRecord);
  }

  count() {
    return this.lastRecord.size;
  }

  reset() {
    this.lastRecord = List<T>();
    this.valuesHolder.next(this.lastRecord);
  }

  get(id: number): T | null {
    const index = this.lastRecord.findIndex(record => record.id === id);

    if (index !== -1) {
      return this.lastRecord.get(index) as T;
    } else {
      return null;
    }
  }

  update(id: number, value: T) {
    const index = this.lastRecord.findIndex(record => record.id === id);

    if (index !== -1) {
      this.lastRecord = this.lastRecord.set(index, value);
      this.valuesHolder.next(this.lastRecord);
    } else {
      return;
    }
  }

  updateValue(id: number, key: string, value: any) {
    const index = this.lastRecord.findIndex(record => record.id === id);

    if (index !== -1) {
      const obj = this.lastRecord.get(index);
      //: {[key: string]: number}
      if (obj instanceof Object) {
        const keyConverted: keyof T = key;
        obj[keyConverted] = value;

        this.lastRecord = this.lastRecord.set(index, obj);
        this.valuesHolder.next(this.lastRecord);
      }
    } else {
      return;
    }
  }

  getValue(id: number, key: string): any | null {
    const index = this.lastRecord.findIndex(record => record.id === id);

    if (index !== -1) {
      const obj = this.lastRecord.get(index);
      //: {[key: string]: number}
      if (obj instanceof Object) {
        const keyConverted: keyof T = key;
        return obj[keyConverted];
      }
    } else {
      return null;
    }
  }

  updateOrInsert(value: T, revered: boolean = false): boolean {
    const index = this.lastRecord.findIndex(record => record.id === value.id);
    if (index !== -1) {
      this.lastRecord = this.lastRecord.set(index, value);
      this.valuesHolder.next(this.lastRecord);
    } else {
      if (revered) {
        this.lastRecord = this.lastRecord.unshift(value);
      } else {
        this.lastRecord = this.lastRecord.push(value);
      }
      this.valuesHolder.next(this.lastRecord);
    }

    return index <= -1;
  }

  add(newRecord: T) {
    this.lastRecord = this.lastRecord.push(newRecord);
    this.valuesHolder.next(this.lastRecord);
  }

  push(newValues: T[]) {
    this.lastRecord = this.lastRecord.push(...newValues);
    this.valuesHolder.next(this.lastRecord);
  }

  unshift(newValues: T[]) {
    this.lastRecord = this.lastRecord.unshift(...newValues);
    this.valuesHolder.next(this.lastRecord);
  }

  delete(id: number) {
    const index = this.lastRecord.findIndex(record => record.id === id);
    if (index !== -1) {
      this.lastRecord = this.lastRecord.delete(index);
      this.valuesHolder.next(this.lastRecord);
    }
  }
}
