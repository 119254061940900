<app-settings-modal>
  <app-settings-modal-menu>
    <ui-side-menu (onChange)="loadModalTab($event)" [tabs]="tabs" [activeTab]="activeTab">
    </ui-side-menu>
  </app-settings-modal-menu>

  <app-settings-modal-content [title]="activeTab?.name" (onClose)="dismiss()">
    <template #container></template>
  </app-settings-modal-content>
</app-settings-modal>
