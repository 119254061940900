import { Channel } from './channel';
import { PresenceChannel } from './presence-channel';
import { EventSubscriptionCallback } from './socket-channel';
import { SocketConnector } from './socket-connector';
import { SocketPrivateChannel } from './socket-private-channel';

/**
 * This class is the primary API for interacting with broadcasting.
 */
export default class Echo {
  /**
   * The broadcasting connector.
   */
  connector: SocketConnector = new SocketConnector();

  /**
   * The Echo options.
   */
  options: any;

  /**
   * Create a new class instance.
   */
  constructor() {}

  /**
   * Get a channel instance by name.
   */
  channel(channel: string): Channel {
    return this.connector.channel(channel);
  }

  /**
   * Create a new connection.
   */
  connect(): void {
    console.debug('[Service][Socket] Try to connect with: ' + this.options.host);

    this.connector.setOptions(this.options);
    this.connector.connect();
  }

  /**
   * Disconnect from the Echo server.
   */
  disconnect(): void {
    this.connector.disconnect();
  }

  /**
   * Get a presence channel instance by name.
   */
  join(channel: string): PresenceChannel {
    return this.connector.presenceChannel(channel);
  }

  /**
   * Leave the given channel, as well as its private and presence variants.
   */
  leave(channel: string): void {
    this.connector.leave(channel);
  }

  /**
   * Leave the given channel.
   */
  leaveChannel(channel: string): void {
    this.connector.leaveChannel(channel);
  }

  /**
   * Listen for an event on a channel instance.
   */
  listen(
    channel: string,
    event: string,
    callback: EventSubscriptionCallback,
    opts: any = null
  ): Channel {
    return this.connector.listen(channel, event, callback, opts);
  }

  /**
   * Get a private channel instance by name.
   */
  private(channel: string): SocketPrivateChannel {
    return this.connector.privateChannel(channel);
  }

  /**
   * Get the Socket ID for the connection.
   */
  socketId(): string | null {
    return this.connector.socketId();
  }
}
