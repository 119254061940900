import { Component, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilderBaseComponent } from '../form-builder.base.component';

/**
 * @description Form builder steps
 * @author Stefan Boronczyk <stefan@strikd.com>
 */
export interface FormBuilderSteps {
  hasNext: boolean;
  hasPrev: boolean;
  nextAvaible: boolean;
}

/**
 * @description Form builder wizard component
 * @author Stefan Boronczyk <stefan@strikd.com>
 */
@Component({
  selector: 'app-form-builder-wizard',
  templateUrl: './form-builder-wizard.component.html',
  styleUrls: ['./form-builder-wizard.component.scss'],
})
export class FormBuilderWizardComponent extends FormBuilderBaseComponent {
  /**
   * @description Event which contains tab informations
   */
  @Output() avaibleSteps = new EventEmitter<FormBuilderSteps>();

  protected onLoad(): void {
    this.avaibleSteps.emit({ hasNext: true, hasPrev: false, nextAvaible: false });
    this.ref.detectChanges();
  }
  protected afterLoad(): void {
    if (this.currentStep != null) {
      this.sendUpdate(this.currentStep);
    }

    this.ref.detectChanges();

    for (let tabId in this.formGroups) {
      var group = this.formGroups[tabId];
      for (let key of Object.keys(this.errors)) {
        if (Object.keys(group.controls).includes(key)) {
          this.activate(tabId);
          return;
        }
      }
    }
  }

  protected currentStep: string | null = null;

  private formSub: Subscription | null = null;

  /**
   * @description Check if all tabs are validated
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param key
   * @returns true if completed
   */
  public isCompleted(key: string): boolean {
    if (!this.isInit) return false;

    let valid = true;
    for (let tabId in this.formGroups) {
      var group = this.formGroups[tabId];

      if (!group.valid) {
        valid = false;
      }
      if (tabId == key) {
        break;
      }
    }
    return valid;
  }

  /**
   * @description Determines if tab can be activated (required previous tab validated)
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param key
   * @param [ignoreFirstKey]
   * @returns true if activate
   */
  public canActivate(key: string, ignoreFirstKey: boolean = true): boolean {
    if (!this.isInit) return false;
    let tabIndex = this.getStepIndex(key);

    if (tabIndex == 0) {
      if (ignoreFirstKey) {
        return true;
      } else {
        var group = this.formGroups[key];
        return group.valid;
      }
    }

    let valid = true;
    for (let tabId in this.options.tabs) {
      if (tabId == key) {
        break;
      }

      if (!(tabId in this.formGroups)) {
        valid = false;
        break;
      }

      var group = this.formGroups[tabId];
      if (!group.valid) {
        valid = false;
      }
    }

    return valid;
  }

  /**
   * @description Activate a tab by given key
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param key
   */
  public activate(key: string) {
    if (this.canActivate(key)) {
      this.sendUpdate(key);
      for (let tabId in this.options.tabs) {
        if (tabId == key) {
          this.options.tabs[tabId].visible = true;
          this.currentStep = tabId;
        } else {
          this.options.tabs[tabId].visible = false;
        }
      }

      if (this.currentStep != null) {
        this.formSub?.unsubscribe();
        var form = this.formGroups[this.currentStep];
        this.formSub = form.valueChanges.subscribe(data => {
          this.sendUpdate(key);
        });
      }
    }
    this.ref.detectChanges();
  }

  /**
   * @description Goto next tab
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @returns
   */
  public gotoNext() {
    if (this.currentStep == null) {
      return;
    }
    let currentTab = this.getStepIndex(this.currentStep);
    var nextStep = this.getStepByIndex(currentTab + 1);
    if (nextStep != null) {
      this.activate(nextStep);
    }
  }

  /**
   * @description Goto previous tab
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @returns
   */
  public gotoPrev() {
    if (this.currentStep == null) {
      return;
    }
    let currentTab = this.getStepIndex(this.currentStep);
    var nextStep = this.getStepByIndex(currentTab - 1);
    if (nextStep != null) {
      this.activate(nextStep);
    }
  }

  /**
   * @description Initalize the form builder
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @returns
   */
  public initalize() {
    this.avaibleSteps.emit({ hasNext: false, hasPrev: false, nextAvaible: false });
    super.initalize();
    let tabName: string | null = null;
    for (let tabId in this.options.tabs) {
      tabName = tabId;
      break;
    }

    if (tabName != null) {
      this.activate(tabName);
    }
    this.ref.detectChanges();
  }

  private getStepByIndex(index: number): string | null {
    let tabs = 0;

    for (let tabId in this.options.tabs) {
      if (tabs++ == index) {
        return tabId;
      }
    }
    return null;
  }

  private getStepIndex(key: string): number {
    let tabs = 0;

    for (let tabId in this.options.tabs) {
      if (tabId == key) {
        return tabs;
      }
      tabs++;
    }

    return 0;
  }

  private getTotalSteps(): number {
    let tabs = 0;

    for (let tabId in this.options.tabs) {
      tabs++;
    }

    return tabs;
  }

  private sendUpdate(key: string) {
    let totalTabs = this.getTotalSteps();
    let currentTab = this.getStepIndex(key);

    this.avaibleSteps.emit({
      hasNext: totalTabs - 1 > currentTab,
      hasPrev: currentTab > 0 && currentTab < totalTabs,
      nextAvaible: this.isCompleted(key),
    });
  }
}
