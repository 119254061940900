export const environment = {
  production: true,
  hmr: false,
  useHTTPLang: false,
  apiUrl: 'https://api.striked.gg/v1/',
  liveUrl: 'wss://live.striked.gg/',
  internalApiUrl: 'https://api.striked.gg/v1/',
  socketUrl: 'https://socket.striked.gg',
  mediaUrl: 'https://media.striked.gg/user-data/',
  developersDomain: 'developers.striked.gg',

  frontendURL: 'https://striked.gg/',
  domain: 'striked.gg',
  pushPubliyKey:
    'BJqkN0Dl-ZFKOtD4obPNTkF5teREmKjeCPbizfNYRYCY46zrHFL6NUa7Jf9AHY30jz5qNxhXSs_K4KE5FoFN_hY',
  debug: false,
  mapBoxApiKey:
    'pk.eyJ1Ijoic3RyaWtlZGFwaSIsImEiOiJja3lkYmJrNXQwYXgxMnZzMjJkOGljMGQwIn0.4ToCNHP5-5F0_hYjtIawWw',
  availableLanguages: ['de', 'en'],
};
