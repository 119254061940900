import { CoreModel } from '../../../shared/models/core.model';

export class BasketRecord extends CoreModel {
  public type: string;
  public amount: number;
  constructor(basketId: number, type: string, amount: number = 1) {
    super();
    this.id = basketId;
    this.type = type;
    this.amount = amount;
  }
}
