import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { RestService } from '../../../shared/services/rest/rest.service';
import { ToastService } from '../../../shared/services/toast.service';
import { BasketRecord } from '../models/basket.record';

// const basket local storage key
const BASKET_KEY = 'basket';

/**
 * @description Injectable basket service
 * @author Stefan Boronczyk <stefan@strikd.com>
 */
@Injectable({
  providedIn: 'root',
})
export class BasketService implements OnDestroy {
  public static records: BasketRecord[] = [];

  private refreshBasketEvent = new Subject<boolean>();
  public refreshBasket = this.refreshBasketEvent.asObservable();

  isBrowser = false;

  constructor(
    private restService: RestService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthStateService,
    public toastService: ToastService,
    public router: Router,
    private storageService: StorageService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      this.authService.userAuthed.subscribe(result => {
        this.fetchBasketRecords();
      });

      this.authService.userConnected.subscribe(result => {
        if (result === true) {
          this.subscribeSocket();
        } else {
          this.unsubscribeSocket();
        }
      });

      if (this.authService.isConnectionActive()) {
        this.subscribeSocket();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.isBrowser) {
      this.unsubscribeSocket();
    }
  }

  /**
   * @description Update a basket record by given amount
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param basketId
   * @param amount
   */
  update(basketId: number, amount: number) {
    this.doRestCall('basket/update/' + basketId + '/' + amount);
  }

  /**
   * @description Deletes a basket item
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param type
   * @param id
   * @param [callback]
   */
  delete(type: string, id: number, callback: Function | null = null) {
    const index = this.getIndex(type, id);
    if (index != -1) {
      BasketService.records.splice(index, 1);
      this.saveBasketOnServer(true, callback);
      this.refreshBasketEvent.next(true);
    }
  }

  /**
   * @description Increase the amount of a basket item
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param type
   * @param id
   * @param [callback]
   */
  increase(type: string, id: number, callback: Function | null = null) {
    // this.doRestCall('basket/remove/' + basketId);
    const index = this.getIndex(type, id);
    if (index != -1) {
      BasketService.records[index].amount++;
      this.saveBasketOnServer(true, callback);
      this.refreshBasketEvent.next(true);
    }
  }

  /**
   * @description Decrease the amount of a basket item
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param type
   * @param id
   * @param [callback]
   */
  decrease(type: string, id: number, callback: Function | null = null) {
    // this.doRestCall('basket/remove/' + basketId);
    const index = this.getIndex(type, id);
    if (index != -1) {
      BasketService.records[index].amount--;
      if (BasketService.records[index].amount <= 0) {
        BasketService.records.splice(index, 1);
      }
      this.saveBasketOnServer(true, callback);
      this.refreshBasketEvent.next(true);
    }
  }

  /**
   * @description Create a new basket item
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param type
   * @param id
   */
  create(type: string, id: number) {
    const index = this.getIndex(type, id);
    if (index == -1) {
      BasketService.records.push(new BasketRecord(id, type));
    } else {
      BasketService.records[index].amount++;
    }

    this.saveBasketOnServer();
    this.refreshBasketEvent.next(true);
    this.router.navigateByUrl('/app/basket/checkout');
  }

  /**
   * @description Do an rest call and handle toasts
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param url
   */
  private doRestCall(url: string) {
    this.restService.get(url).subscribe(
      result => {
        this.toastService.createSuccessToast(result.message);
      },
      error => {
        this.toastService.createErrorToast(error.message);
      }
    );
  }

  /**
   * @description Subscribe socket events for basket service
   * @author Stefan Boronczyk <stefan@strikd.com>
   */
  private subscribeSocket() {
    this.authService.listenOnAuthChannel('SaveBasketItem', this.SaveBasketItem, this);
    // this.authService.listenChannel('DeketeBasketRecord', this.deleteBasketRecord);
  }

  /**
   * @description Unsubscribe socket events for basket service
   * @author Stefan Boronczyk <stefan@strikd.com>
   */
  private unsubscribeSocket() {
    this.authService.unlistenAuthChannel('SaveBasketItem', this.SaveBasketItem);
    //  this.authService.unlistenChannel('DeketeBasketRecord', this.deleteBasketRecord);
  }

  /**
   * @description Save an basket item
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param df
   */
  public SaveBasketItem(df: any, mainClass: any) {
    BasketService.records = df.data as BasketRecord[];
    mainClass.storageService.set(BASKET_KEY, JSON.stringify(BasketService.records));
  }

  /**
   * @description Fetch an list of basket items
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @returns items
   */
  public fetchItems(): Observable<any> {
    var converted = JSON.stringify(BasketService.records);
    return this.restService.post('basket/items', { items: JSON.parse(converted) });
  }

  /**
   * @description Save current basket by server rest call
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param [storeInDatabase]
   * @param [callback]
   */
  private saveBasketOnServer(storeInDatabase: boolean = true, callback: Function | null = null) {
    this.storageService.set(BASKET_KEY, JSON.stringify(BasketService.records));

    var converted = JSON.stringify(BasketService.records);
    if (this.authService.isLoggedIn && storeInDatabase) {
      this.restService.post('basket', { items: JSON.parse(converted) }).subscribe(
        res => {
          if (callback != null) {
            callback();
          }
        },
        err => {}
      );
    } else {
      if (callback != null) {
        callback();
      }
    }
  }

  /**
   * @description Fetch current basket records from local storage
   * @author Stefan Boronczyk <stefan@strikd.com>
   */
  private fetchBasketRecords() {
    // fetch from storage
    const obj = this.storageService.get(BASKET_KEY);
    if (obj != null) {
      const bj = JSON.parse(obj);
      for (let item of bj) {
        let converted = item as BasketRecord;
        if (!this.isInBasket(converted.type, converted.id)) {
          BasketService.records.push(converted);
        }
      }
    }

    // fetch from basket list
    if (this.authService.isLoggedIn) {
      this.restService.getList('basket').subscribe(result => {
        const results = result as any[];
        for (let item of results) {
          let converted = new BasketRecord(item.id, item.type, item.amount);

          var index = this.getIndex(converted.type, converted.id);
          if (index == -1) {
            BasketService.records.push(converted);
          } else {
            BasketService.records[index] = converted;
          }

          this.saveBasketOnServer();
        }
      });
    }
  }

  /**
   * @description Check if a basket item already in basket
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param type
   * @param typeId
   * @returns
   */
  public isInBasket(type: string, typeId: number) {
    return BasketService.records.filter(df => df.type == type && df.id == typeId).length > 0;
  }

  /**
   * @description Get the array index of an existing basket item
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param type
   * @param typeId
   * @returns
   */
  public getIndex(type: string, typeId: number) {
    return BasketService.records.findIndex(df => df.type == type && df.id == typeId);
  }

  /**
   * @description Destroying the current basket list
   * @author Stefan Boronczyk <stefan@strikd.com>
   */
  private destroyBasketList() {
    BasketService.records = [];
  }
}
