import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { InviteModalModule } from 'src/app/b2c/friends/components/invite-modal/invite-modal.module';
import { MediaModule } from 'src/app/shared/directives/media/media.module';
import { ScrollbarModule } from 'src/app/shared/directives/scrollbar/scrollbar.module';
import { TooltipModule } from 'src/app/shared/directives/tooltip/tooltip.module';
import { WindowSizeModule } from 'src/app/shared/directives/window-size/window-size.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { LoadingContentModule } from 'src/app/ui/loading-content/loading-content.module';
import { NoContentFoundModule } from 'src/app/ui/no-content-found/no-content-found.module';
import { UIModule } from 'src/app/ui/ui.module';
import { ChatNavigationIconsComponent } from './chat-navigation-icons/chat-navigation-icons.component';
import { HomeNavigationIconsComponent } from './home-navigation-icons/home-navigation-icons.component';
import { UserActionPopoverComponent } from './user-action-popover/user-action-popover.component';
import { UserNavigationIconsComponent } from './user-navigation-icons/user-navigation-icons.component';
import { HoverModule } from './title/hover.module.ts/hover.module';
import { NgxTippyModule } from 'ngx-tippy-wrapper';

@NgModule({
  providers: [],
  imports: [
    CommonModule,
    UIModule,
    MediaModule,
    PipesModule,
    NoContentFoundModule,
    LoadingContentModule,
    RouterModule.forChild([]),
    WindowSizeModule,
    DragDropModule,
    InviteModalModule,
    TranslocoModule,
    ScrollbarModule,
    HoverModule,
    FormsModule,
    TooltipModule,
    IonicModule,
    NgxTippyModule,
  ],
  declarations: [
    UserNavigationIconsComponent,
    UserActionPopoverComponent,
    ChatNavigationIconsComponent,
    HomeNavigationIconsComponent,
  ],
  exports: [
    UserNavigationIconsComponent,
    UserActionPopoverComponent,
    ChatNavigationIconsComponent,
    HomeNavigationIconsComponent,
  ],
})
export class SidebarIconsModule {}
