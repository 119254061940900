import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { BehaviorSubject, Subscription } from 'rxjs';
import { RestService } from 'src/app/shared/services/rest/rest.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-media-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class MediaImageCropperComponent implements OnDestroy, OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = null;

  public onSubmit = false;
  public process = 0.0;

  @Input() enableAspectRatio: boolean = true;
  @Input() aspectRatio: number = 4 / 4;
  @Input() maxResizeInPx: number = 1024;
  @Input() roundCropper: boolean = true;
  @Input() noUpload: boolean = false;

  public uploadedFile: File | null = null;
  public fileUploadControl = new FileUploadControl({}, FileUploadValidators.filesLimit(2));
  private subscription: Subscription | null = null;

  @Input() uri: string | null = null;
  public readonly control = new FileUploadControl(
    {
      listVisible: true,
      accept: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'],
      discardInvalid: true,
      multiple: false,
    },
    [
      FileUploadValidators.accept(['image/jpg', 'image/jpeg', 'image/png', 'image/gif']),
      FileUploadValidators.filesLimit(1),
    ]
  );

  constructor(
    private modalController: ModalController,
    private toastService: ToastService,
    private restService: RestService
  ) {}

  public ngOnInit(): void {
    this.subscription = this.control.valueChanges.subscribe(
      (values: Array<File>) => (this.uploadedFile = values[0])
    );
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  /**
   * Close modal
   */
  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
      openMode: null,
    });
  }

  /**
   * Send the image to rest, detect progress and dismiss modal after complete
   */
  submit() {
    if (this.croppedImage != null && !this.onSubmit) {
      this.onSubmit = true;
      this.process = 0;

      if (this.noUpload) {
        this.onSubmit = false;

        this.modalController.dismiss({
          dismissed: true,
          data: this.croppedImage,
        });
      } else if (this.uri != null && this.uri != undefined) {
        this.restService.uploadBase64(this.uri, { image: this.croppedImage }).subscribe(
          (res: any) => {
            if (res.status == 'response') {
              const message = res.message;

              this.onSubmit = false;
              this.toastService.createSuccessToast(message.message);

              this.modalController.dismiss({
                dismissed: true,
                path: message.data.path,
              });
            }
            if (res.status == 'progress') {
              this.process = parseFloat(res.message) / 100;
            }
          },
          (error: any) => {
            this.onSubmit = false;
            this.process = 0;
            if (error.errors?.image != null) {
              let message = '';
              for (var m of error.errors?.image) {
                message += m + '\n';
              }

              this.toastService.createErrorToast(message);
            } else {
              this.toastService.createErrorToast(error.message);
            }
          }
        );
      }
    }
  }
}
