<form *ngIf="formGroup" [formGroup]="formGroup">
  <ion-grid class="grid-no-padding">
    <ion-row class="grid-items" *ngFor="let grid of tab.sections | keyvalue : unsorted">
      <ion-col size="12" *ngIf="grid.value.description">
        <div class="tab-info">
          <p class="desc" [transloco]="grid.value.description"></p>
        </div>
      </ion-col>

      <ion-col
        *ngFor="let input of grid.value.inputs | keyvalue : unsorted; let isLast = last"
        [size]="input.value.size || 12"
        [sizeMd]="input.value.sizeMd"
        [sizeMd]="input.value.sizeLg"
        [style.display]="input.value.type == 'hidden' ? 'none' : 'block'"
      >
        <div
          class="option-item"
          *ngIf="input.value.type != 'hidden'"
          [ngClass]="{
            'with-error': errors && errors[input.key] !== undefined
          }"
        >
          <div *ngIf="input.value.type == 'select-box'" class="input-element">
            <div class="ion-card-grid">
              <div
                class="card-select {{ option.value.cssExtra }}"
                (click)="setValueForSelectBox(option, input)"
                [ngClass]="{
                'active':  isFormSelectBoxActive(option, input),
                'disabled': option.value.disabled,

              }"
                *ngFor="let option of input.value.boxOptions | keyvalue : unsorted"
              >
                <div class="left">
                  <ion-icon
                    *ngIf="option.value.icon"
                    [color]="option.value.iconColor || 'primary'"
                    [name]="option.value.icon"
                  ></ion-icon>
                </div>

                <div class="right">
                  <p class="card-title" [transloco]="option.value.title"></p>
                  <p
                    *ngIf="option.value.desc"
                    [transloco]="option.value.desc"
                    class="card-desc"
                  ></p>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="input.value.type == 'list-view'" class="input-element">
            <div class="ion-card-grid">
              <div
                class="card-select"
                (click)="setValueForSelectBox(option, input)"
                [ngClass]="{
                'active':  isFormSelectBoxActive(option, input),
                'disabled': option.value.disabled,

              }"
                *ngFor="let option of input.value.boxOptions | keyvalue : unsorted"
              >
                <div class="left">
                  <ion-icon
                    *ngIf="option.value.icon || option.value.src"
                    [color]="option.value.iconColor || 'primary'"
                    [name]="option.value.icon || undefined"
                    [src]="option.value.src || undefined"
                  ></ion-icon>
                </div>

                <div class="right">
                  <p class="card-title" [transloco]="option.value.title"></p>
                  <p
                    *ngIf="option.value.desc"
                    [transloco]="option.value.desc"
                    class="card-desc"
                  ></p>
                </div>

                <div class="right-checkmark" *ngIf="isFormSelectBoxActive(option, input)">
                  <ion-icon name="checkmark-outline"></ion-icon>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="input.value.gridData && input.value.type == 'data-view'">
            <app-infinite-data-grid-search
              [dataGridRef]="dataGrid"
              class="data-search"
              *ngIf="input.value.gridData.searchUrl"
            ></app-infinite-data-grid-search>

            <ng-template #dataGridTemplate let-record let-mode="mode" let-data="data">
              <div class="full-width" (click)="setValueForDataView(record?.id, input)">
                <ng-container
                  [ngTemplateOutlet]="input.value.gridData.dataTemplate || defaultTemplate"
                  [ngTemplateOutletContext]="{
                    $implicit: record,
                    mode: mode,
                    data: {
                      active: isActiveDataView(record?.id, input)
                    }
                  }"
                >
                </ng-container>
              </div>
            </ng-template>

            <div class="data-scroller scrollable" #dataScroller>
              <app-infinite-data-grid
                [initValues]="36"
                [replaceMode]="true"
                minWidth="175px"
                [elementContainer]="dataScroller"
                #dataGrid
                [windowResize]="dataScroller"
                [searchUrl]="input.value.gridData.searchUrl"
                [dataTemplate]="dataGridTemplate"
                [dataUrl]="input.value.gridData.dataUrl"
              >
              </app-infinite-data-grid>
            </div>
          </ng-container>

          <div class="input-element" *ngIf="input.value.type == 'viewbox'">
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>
            <div class="viewbox" [innerHTML]="input.value.value"></div>
          </div>

          <div
            class="input-element"
            [ngClass]="{ centered: input.value.centered }"
            *ngIf="input.value.type == 'avatar'"
          >
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>

            <div class="avatar-image">
              <div class="avatar">
                <ion-img
                  [style.max-width]="input.value.imageWidth || '100%'"
                  [src]="formGroup.value[input.key] || input.value.placeholder | cloudUrl"
                >
                </ion-img>
              </div>
              <div class="upload-avatar">
                <button
                  *ngIf="!formGroup.value[input.key]"
                  (click)="uploadAvatar(input.key, input.value.dataUri)"
                >
                  <span transloco="form.image.upload"></span>
                </button>
                <button
                  *ngIf="formGroup.value[input.key]"
                  (click)="resetImage(input.key, input.value)"
                  placement="bottom"
                >
                  <span transloco="form.image.delete"></span>
                </button>
              </div>
              <ion-note
                *ngIf="input.value.description"
                class="input-note"
                [transloco]="input.value.description"
              >
              </ion-note>
            </div>
          </div>

          <div class="input-element" *ngIf="input.value.type == 'image'">
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>

            <div class="select-image">
              <div
                class="select-image-inner"
                *ngIf="formGroup.value[input.key] || input.value.placeholder"
              >
                <ion-img
                  [style.max-width]="input.value.imageWidth || '100%'"
                  [src]="formGroup.value[input.key] || input.value.placeholder | cloudUrl"
                >
                </ion-img>
              </div>
              <div class="select-image-buttons">
                <button
                  class="btn btn-xxs btn-solid primary"
                  (click)="uploadImage(input.key, input.value)"
                  transloco="form.image.upload"
                ></button>
                <button
                  class="btn btn-xxs btn-solid danger"
                  (click)="resetImage(input.key, input.value)"
                  transloco="form.image.delete"
                  *ngIf="formGroup.value[input.key]"
                ></button>
              </div>
            </div>
            <ion-note
              *ngIf="input.value.description"
              class="input-note"
              [transloco]="input.value.description"
            >
            </ion-note>
          </div>

          <div class="input-element" *ngIf="input.value.type == 'text'">
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>
            <ion-input
              [readonly]="input.value.readOnly || false"
              inputmode="text"
              [formControlName]="input.key"
              [spellcheck]="input.value.spellcheck || false"
              [maxlength]="input.value.maxlength || null"
              [minlength]="input.value.minlength || null"
              type="text"
              [clearInput]="input.value.clearInput || false"
              [required]="input.value.required || false"
              [placeholder]="input.value.placeholder ? (input.value.placeholder | transloco) : null"
            ></ion-input>
            <ion-note
              *ngIf="input.value.description"
              class="input-note"
              [transloco]="input.value.description"
              [translocoParams]="{ chars: charsLeft(input.key) }"
            ></ion-note>
          </div>

          <div class="input-element" *ngIf="input.value.type == 'number'">
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>
            <ion-input
              [readonly]="input.value.readOnly || false"
              inputmode="number"
              [formControlName]="input.key"
              [max]="input.value.max || null"
              [min]="input.value.min || null"
              type="number"
              [clearInput]="input.value.clearInput || false"
              [step]="input.value.step || 1"
              [required]="input.value.required || false"
              [placeholder]="input.value.placeholder ? (input.value.placeholder | transloco) : null"
            ></ion-input>
            <ion-note
              *ngIf="input.value.description"
              class="input-note"
              [transloco]="input.value.description"
              [translocoParams]="{ chars: charsLeft(input.key) }"
            ></ion-note>
          </div>

          <div class="input-element" *ngIf="input.value.type == 'money'">
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>
            <div class="btn-list full-width">
              <ion-input
                [readonly]="input.value.readOnly || false"
                inputmode="number"
                [formControlName]="input.key"
                [max]="input.value.max || null"
                [min]="input.value.min || null"
                type="number"
                [clearInput]="input.value.clearInput || false"
                [step]="input.value.step || 1"
                [required]="input.value.required || false"
                [placeholder]="
                  input.value.placeholder ? (input.value.placeholder | transloco) : null
                "
              ></ion-input>
              <div class="append-currency">
                <span>
                  {{ input.value.currency || 'EUR' }}
                </span>
              </div>
            </div>
            <ion-note
              *ngIf="input.value.description"
              class="input-note"
              [transloco]="input.value.description"
              [translocoParams]="{ chars: charsLeft(input.key) }"
            ></ion-note>
          </div>

          <div class="input-element" *ngIf="input.value.type == 'password'">
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>
            <ion-input
              [readonly]="input.value.readOnly || false"
              inputmode="password"
              [formControlName]="input.key"
              [spellcheck]="input.value.spellcheck || false"
              [maxlength]="input.value.maxlength || null"
              type="password"
              [clearInput]="input.value.clearInput || false"
              [required]="input.value.required || false"
              [placeholder]="input.value.placeholder ? (input.value.placeholder | transloco) : null"
            ></ion-input>
            <ion-note
              *ngIf="input.value.description"
              class="input-note"
              [transloco]="input.value.description"
              [translocoParams]="{ chars: charsLeft(input.key) }"
            ></ion-note>
          </div>

          <div class="input-element" *ngIf="input.value.type == 'date'">
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>
            <ion-input
              [readonly]="input.value.readOnly || false"
              inputmode="date"
              [formControlName]="input.key"
              [max]="input.value.max || null"
              [max]="input.value.min || null"
              type="date"
              [clearInput]="input.value.clearInput || false"
              [required]="input.value.required || false"
              [placeholder]="input.value.placeholder ? (input.value.placeholder | transloco) : null"
            ></ion-input>
            <ion-note
              *ngIf="input.value.description"
              class="input-note"
              [transloco]="input.value.description"
              [translocoParams]="{ chars: 0 }"
            ></ion-note>
          </div>

          <div position="stacked" class="input-element" *ngIf="input.value.type == 'tags'">
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>

            <div class="tags">
              <div
                (click)="
                  addOrDeleteTag(
                    input.value,
                    input.key,
                    option,
                    input.value.multipleSelection || false
                  )
                "
                [ngClass]="{
                  active: isTagActivated(
                    input.key,
                    option.key,
                    input.value.multipleSelection || false
                  )
                }"
                class="tag"
                *ngFor="let option of input.value.options"
              >
                #{{ option.translate ? ('model.value' | transloco) : option.value }}
              </div>
            </div>

            <ion-note
              *ngIf="input.value.description"
              class="input-note"
              [transloco]="input.value.description"
            >
            </ion-note>
          </div>

          <div position="stacked" class="input-element" *ngIf="input.value.type == 'suggest'">
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>

            <app-tag-component
              [value]="input.value"
              [key]="input.key"
              [multipleSelection]="false"
              (addOrDeleteTag)="
                addOrDeleteTagSingle(
                  input.value,
                  input.key,
                  $event,
                  input.value.multipleSelection || false
                )
              "
              [options]="getValueSingle(input.key)"
            ></app-tag-component>
            <ion-note
              *ngIf="input.value.description"
              class="input-note"
              [transloco]="input.value.description"
            >
            </ion-note>
          </div>
          <div position="stacked" class="input-element" *ngIf="input.value.type == 'tags-search'">
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>

            <app-tag-component
              [value]="input.value"
              [key]="input.key"
              [multipleSelection]="input.value.multipleSelection || false"
              (addOrDeleteTag)="
                addOrDeleteTag(
                  input.value,
                  input.key,
                  $event,
                  input.value.multipleSelection || false
                )
              "
              [options]="getValueAny(input.key)"
            ></app-tag-component>
            <ion-note
              *ngIf="input.value.description"
              class="input-note"
              [transloco]="input.value.description"
            >
            </ion-note>
          </div>

          <div class="input-element" *ngIf="input.value.type == 'datetime'">
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>
            <ion-input
              [readonly]="input.value.readOnly || false"
              inputmode="date"
              [formControlName]="input.key"
              [max]="input.value.max || null"
              [max]="input.value.min || null"
              type="datetime-local"
              [clearInput]="input.value.clearInput || false"
              [required]="input.value.required || false"
              [placeholder]="input.value.placeholder ? (input.value.placeholder | transloco) : null"
            ></ion-input>
            <ion-note
              *ngIf="input.value.description"
              class="input-note"
              [transloco]="input.value.description"
              [translocoParams]="{ chars: 0 }"
            ></ion-note>
          </div>

          <div class="input-element" *ngIf="input.value.type == 'readonly'">
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>
            <ion-input
              readonly="true"
              inputmode="text"
              [formControlName]="input.key"
              type="text"
              [required]="input.value.required || false"
            ></ion-input>
            <ion-note
              *ngIf="input.value.description"
              class="input-note"
              [transloco]="input.value.description"
              [translocoParams]="{ chars: charsLeft(input.key) }"
            ></ion-note>
          </div>

          <div class="input-element" *ngIf="input.value.type == 'readonly-html'">
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>

            <div class="markdown markdown-field scrollable">
              <div class="markdown-content" [innerHTML]="getValue(input.key) | safeHtml"></div>
            </div>
            <ion-note
              *ngIf="input.value.description"
              class="input-note"
              [transloco]="input.value.description"
              [translocoParams]="{ chars: charsLeft(input.key) }"
            ></ion-note>
          </div>
          <div class="input-element" *ngIf="input.value.type == 'readonly-copy'">
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>
            <div class="readonly-copy">
              <ion-input
                readonly="true"
                inputmode="text"
                [formControlName]="input.key"
                type="text"
                [required]="input.value.required || false"
              ></ion-input>

              <button class="btn btn-xxs btn-solid primary" (click)="copy(input.key)">Copy</button>
            </div>
            <ion-note
              *ngIf="input.value.description"
              class="input-note"
              [transloco]="input.value.description"
              [translocoParams]="{ chars: 0 }"
            ></ion-note>
          </div>

          <div class="input-element" *ngIf="input.value.type == 'readonly-action'">
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>
            <div class="readonly-copy">
              <ion-input
                readonly="true"
                inputmode="text"
                [formControlName]="input.key"
                type="text"
                [required]="input.value.required || false"
              ></ion-input>
              <button
                class="btn btn-xxs btn-solid primary"
                (click)="input.value.actionCallback ? input.value.actionCallback() : null"
              >
                {{ input.value.actionName || '' | transloco }}
              </button>
            </div>
            <ion-note
              *ngIf="input.value.description"
              class="input-note"
              [transloco]="input.value.description"
              [translocoParams]="{ chars: 0 }"
            ></ion-note>
          </div>

          <div class="input-element" *ngIf="input.value.type == 'checkbox'" lines="none">
            <div class="checkbox-line">
              <ion-item lines="none" class="checkbox-item">
                <ion-checkbox
                  mode="ios"
                  slot="start"
                  [formControlName]="input.key"
                  labelPlacement="end"
                  [required]="input.value.required || false"
                  [transloco]="input.value.label"
                >
                </ion-checkbox>
              </ion-item>
            </div>
            <ion-note
              *ngIf="input.value.description"
              class="input-note"
              [transloco]="input.value.description"
            >
            </ion-note>
          </div>

          <div
            class="input-element"
            *ngIf="input.value.type == 'select' && input.value.optionsSearchable"
          >
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>
            <ionic-selectable
              [placeholder]="'select.empty' | transloco"
              [items]="input.value.options || []"
              itemValueField="key"
              [hasVirtualScroll]="true"
              [isMultiple]="input.value.multipleSelection || false"
              itemTextField="value"
              [canClear]="input.value.clearInput || false"
              [formControlName]="input.key"
              [labelTitle]="input.value.label | transloco"
              [canSearch]="input.value.optionsSearchable || false"
              (onSearch)="
                input.value.optionsLoadCallback ? input.value.optionsLoadCallback($event) : null
              "
            >
            </ionic-selectable>
            <ion-note
              *ngIf="input.value.description"
              class="input-note"
              [transloco]="input.value.description"
            >
            </ion-note>
          </div>

          <div
            class="input-element"
            *ngIf="input.value.type == 'select' && !input.value.optionsSearchable"
          >
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>
            <ion-select
              [placeholder]="'select.empty' | transloco"
              [formControlName]="input.key"
              [label]="input.value.label | transloco"
              [multiple]="input.value.multipleSelection || false"
            >
              <ion-select-option [value]="model.key" *ngFor="let model of input.value.options">
                <span
                  *ngIf="model.translate"
                  [transloco]="model.value"
                  [translocoParams]="model.translocoParams"
                >
                </span>

                <span *ngIf="!model.translate">
                  {{ model.value }}
                </span>
              </ion-select-option>
            </ion-select>
            <ion-note
              *ngIf="input.value.description"
              class="input-note"
              [transloco]="input.value.description"
            >
            </ion-note>
          </div>

          <div class="input-element" *ngIf="input.value.type == 'textarea'">
            <ion-label
              position="stacked"
              [transloco]="input.value.label"
              *ngIf="input.value.label"
            ></ion-label>
            <ion-textarea
              [readonly]="input.value.readOnly || false"
              auto-grow="true"
              [maxlength]="input.value.maxlength || null"
              [minlength]="input.value.minlength || null"
              [formControlName]="input.key"
              [placeholder]="input.value.placeholder ? (input.value.placeholder | transloco) : null"
            ></ion-textarea>
            <ion-note
              *ngIf="input.value.description"
              class="input-note"
              [transloco]="input.value.description"
              [translocoParams]="{ chars: charsLeft(input.key) }"
            ></ion-note>
          </div>
          <div size="12" *ngIf="errors && errors[input.key] !== undefined">
            <ion-note *ngFor="let error of errors[input.key]" class="error-note" color="danger">{{
              error
            }}</ion-note>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>

<ng-template #defaultTemplate> </ng-template>
