<div class="app-mobile-nav undraggable">
  <div class="chat-item spacer" *ngIf="(authState.userAuthed | async) === true && i2BSBActive()">
    <div
      class="shortcut-icon shortcut-action-button shortcut-active"
      [routerLink]="'/app/b2b/game-centre'"
      placement="right"
      [tooltip]="'appcenter.title' | transloco"
    >
      <ion-icon [name]="i2BSBActive() ? 'rocket' : 'rocket-outline'"></ion-icon>
    </div>
  </div>

  <div class="chat-item" *ngIf="!desktopService.isDesktop()">
    <div
      class="shortcut-icon shortcut-action-button icon-warning"
      (click)="download($event)"
      placement="right"
      [tooltip]="'title.download_launcher' | transloco"
    >
      <ion-icon class="download" name="download-outline"></ion-icon>
    </div>
  </div>

  <div class="chat-item" *ngIf="(authState.userAuthed | async) === true">
    <div
      class="shortcut-icon shortcut-action-button"
      [routerLink]="'/app/dashboard'"
      [ngClass]="{ 'shortcut-active': isFeedActive() }"
      placement="right"
      [tooltip]="'feed.title' | transloco"
    >
      <ion-icon [name]="isFeedActive() ? 'copy' : 'copy-outline'"></ion-icon>
    </div>
    <div class="unread-chats" *ngIf="unreadFeed > 0">
      <span>{{ unreadFeed }}</span>
    </div>
  </div>
  <div class="chat-item" *ngIf="(authState.userAuthed | async) === true">
    <div
      class="shortcut-icon shortcut-action-button"
      (click)="routeToChat($event)"
      [ngClass]="{ 'shortcut-active': isActive() }"
      placement="right"
      [tooltip]="'chats.title' | transloco"
    >
      <ion-icon [name]="isActive() ? 'chatbox' : 'chatbox-outline'"></ion-icon>
    </div>
    <div class="unread-chats" *ngIf="unreadChats > 0">
      <span>{{ unreadChats }}</span>
    </div>
  </div>

  <div class="chat-item" *ngIf="(authState.userAuthed | async) === true">
    <div
      class="shortcut-icon shortcut-action-button"
      [routerLink]="'/app/library'"
      [ngClass]="{ 'shortcut-active': isDownloadsActive() }"
      placement="right"
      [tooltip]="'library.title' | transloco"
    >
      <ion-icon
        [name]="isDownloadsActive() ? 'game-controller' : 'game-controller-outline'"
      ></ion-icon>
    </div>
  </div>

  <div class="chat-item" *ngIf="(authState.userAuthed | async) === true">
    <div
      class="shortcut-icon shortcut-action-button"
      [routerLink]="'/app/friends'"
      [ngClass]="{ 'shortcut-active': isFriendsActive() }"
      placement="right"
      [tooltip]="'friends.title' | transloco"
    >
      <ion-icon [name]="isFriendsActive() ? 'people' : 'people-outline'"></ion-icon>

      <div class="unread-chats" *ngIf="friendRequests > 0">
        <span>{{ friendRequests }}</span>
      </div>
    </div>
  </div>

  <div class="chat-item" *ngIf="(authState.userAuthed | async) === true">
    <div
      class="shortcut-icon shortcut-action-button"
      [routerLink]="'/app/notifications'"
      placement="right"
      [ngClass]="{ 'shortcut-active': isNotificationsActive() }"
      [tooltip]="'notifications.title' | transloco"
    >
      <ion-icon
        [name]="isNotificationsActive() ? 'notifications' : 'notifications-outline'"
      ></ion-icon>
      <div class="unread-chats" *ngIf="notifyService.Unreaded > 0">
        <span>{{ notifyService.Unreaded }}</span>
      </div>
    </div>
  </div>

  <div class="chat-item">
    <div
      class="shortcut-icon shortcut-action-button"
      [routerLink]="'/app/basket/checkout'"
      placement="right"
      [ngClass]="{ 'shortcut-active': isBasketActive() }"
      [tooltip]="'basket.title' | transloco"
    >
      <ion-icon [name]="isBasketActive() ? 'basket' : 'basket-outline'"></ion-icon>
      <div class="unread-chats" *ngIf="basketAmount > 0">
        <span>{{ basketAmount }}</span>
      </div>
    </div>
  </div>
  <div class="chat-item" *ngIf="(authState.userAuthed | async) === true">
    <div
      class="shortcut-icon shortcut-action-button"
      [routerLink]="'/app/missions'"
      placement="right"
      [ngClass]="{ 'shortcut-active': isMissionsActive() }"
      [tooltip]="'achievements.title' | transloco"
    >
      <ion-icon
        class="xp-icon"
        [src]="isMissionsActive() ? '/assets/ui-icons/xp2.svg' : '/assets/ui-icons/xp2-outline.svg'"
      ></ion-icon>

      <div class="xp-points">
        <span>{{ missionsXP }}</span>
      </div>
    </div>
  </div>

  <div class="chat-item">
    <div
      class="shortcut-icon shortcut-action-button"
      [routerLink]="'/app/search'"
      placement="right"
      [tooltip]="'search.title' | transloco"
    >
      <ion-icon [name]="isSettingsActive() ? 'search' : 'search-outline'"></ion-icon>
    </div>
  </div>

  <div class="chat-item">
    <div
      (click)="showCentre($event)"
      class="shortcut-icon shortcut-action-button"
      placement="right"
      [tooltip]="'apps.title' | transloco"
    >
      <ion-icon [name]="'apps-outline'"></ion-icon>
    </div>
  </div>
</div>

<ion-modal #modal>
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title transloco="apps.title"></ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-button fill="clear" color="danger" class="close-button" (click)="modal.dismiss()">
      <ion-icon name="close-outline" slot="icon-only"> </ion-icon>
    </ion-button>

    <ion-content>
      <div class="apps">
        <div class="app" routerLink="/app/b2b/game-centre" (click)="modal.dismiss()">
          <div class="icon"><ion-icon name="rocket"></ion-icon></div>
          <div class="title" transloco="appcenter.title"></div>
          <div class="desc" transloco="appcenter.desc"></div>
        </div>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>
