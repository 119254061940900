import { Injectable, Injector } from '@angular/core';
import { CoreSocketService } from 'src/app/shared/services/core.socket.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ConnectionModel } from '../models/connection.model';
import { environment } from 'src/environments/environment';
import { TokenService } from 'src/app/auth/services/token.service';
import { Observable } from 'rxjs';

/*
 * @description Game service contains assigned
 * @author Stefan Boronczyk <stefan@strikd.com>
 */
@Injectable({
  providedIn: 'root',
})
export class ConnectService extends CoreSocketService<ConnectionModel> {
  public avaiableConnectors: string[] = ['steam'];
  private alreadySyncRunning = false;
  private alreadyDisconnectRunning = false;
  constructor(
    protected injector: Injector,
    private toastService: ToastService,
    private tokenService: TokenService
  ) {
    super(
      'library/connections',
      'OnNewThirdPartyConnection',
      'OnDeleteThirdPartyConnection',
      injector
    );
  }

  connect(provider: string): Promise<boolean> {
    let params = `scrollbars=yes,resizable=yes,status=no,location=yes,toolbar=no,menubar=no`;

    const uri =
      environment.apiUrl +
      'library/connect/' +
      provider +
      '?access_token=' +
      this.tokenService.getToken();

    const windowProxy = window.open(uri, '_blank', params);
    const initialTime = performance.now();

    return new Promise(resolve => {
      if (!windowProxy) {
        resolve(false);
      } else {
        windowProxy.addEventListener('unload', ev => {
          const delta = performance.now() - initialTime;
          const thresholdMs = 1000;
          if (delta < thresholdMs) {
            return;
          }
          resolve(true);
        });
      }
    });
  }

  sync(event: any, syncId: number) {
    event.stopPropagation();
    event.preventDefault();
    if (this.alreadySyncRunning) {
      return;
    }
    this.alreadySyncRunning = true;
    this.restService.get('library/sync/' + syncId + '?time=' + new Date().getTime()).subscribe(
      df => {
        this.toastService.createInfoToast(df.message);
        this.alreadySyncRunning = false;
      },
      error => {
        this.toastService.createErrorToast(error.message);
        this.alreadySyncRunning = false;
      }
    );
  }

  disconnect(event: any, syncId: number) {
    event.stopPropagation();
    event.preventDefault();
    if (this.alreadyDisconnectRunning) {
      return;
    }
    this.alreadyDisconnectRunning = true;
    this.restService
      .get('library/disconnect/' + syncId + '?time=' + new Date().getTime())
      .subscribe(
        df => {
          this.toastService.createInfoToast(df.message);
          this.alreadyDisconnectRunning = false;
        },
        error => {
          this.toastService.createErrorToast(error.message);
          this.alreadyDisconnectRunning = false;
        }
      );
  }
}
