import { isPlatformBrowser } from '@angular/common';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { timeout } from 'rxjs';
import { TokenService } from './token.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  isBrowser = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private tokenService: TokenService) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  /**
   * Intercept a http request to handle the rest token
   * @todo Problem after timeout of a token (site is reloading 3 times)
   * @param request
   * @param next
   * @returns
   */
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let headers: {
      [name: string]: string | string[];
    } = {};

    if (this.tokenService.isValidToken()) {
      headers['Authorization'] = 'Bearer ' + this.tokenService.getToken();
    }
    return next.handle(request.clone({ setHeaders: headers }));
  }
}
