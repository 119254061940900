import { NgModule } from '@angular/core';
import { DatePipe, DateTimeDatePipe, TimeDatePipe } from './datetime.date.pipe';
import { DynamicFilterPipe } from './dynamic.filter.pipe';
import { HumanizeDatePipe } from './humanize.date.pipe';
import { Nl2brPipe } from './nl2br.pipe';
import { PlaytimePipe } from './playtime.pipe';
import { SafeHtmlPipe } from './safeHtml.pipe';
import { SlugifyPipe } from './slugify.pipe';
import { WordTruncateTextPipe } from './word.truncate.pipe';

@NgModule({
  imports: [],
  declarations: [
    HumanizeDatePipe,
    TimeDatePipe,
    DatePipe,
    PlaytimePipe,
    DateTimeDatePipe,
    SlugifyPipe,
    DynamicFilterPipe,
    Nl2brPipe,
    SafeHtmlPipe,
    WordTruncateTextPipe,
  ],
  exports: [
    HumanizeDatePipe,
    TimeDatePipe,
    DateTimeDatePipe,
    PlaytimePipe,
    DatePipe,
    SlugifyPipe,
    DynamicFilterPipe,
    Nl2brPipe,
    SafeHtmlPipe,
    WordTruncateTextPipe,
  ],
})
export class PipesModule {}
