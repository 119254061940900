import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[uiListItemAction]',
})
export class UIListItemActionDirective {
  public isDirty = true;
  private origDisplay: string | undefined = undefined;
  @Output() onClick = new EventEmitter<MouseEvent>();
  constructor(private renderer: Renderer2, private host: ElementRef) {
    this.origDisplay = this.host.nativeElement.style.display;
    this.host.nativeElement.style.display = 'none';
  }
  getRootElement(): ElementRef<any> {
    return this.host;
  }
  moveToNewNode(node: any) {
    if (!this.isDirty) {
      return;
    }
    this.renderer.appendChild(node, this.getRootElement().nativeElement);
    this.host.nativeElement.style.display = this.origDisplay;
    this.isDirty = false;
  }

  @HostListener('click', ['$event']) onClickHandler($event: MouseEvent) {
    console.info('clicked: ' + $event);
    this.onClick.emit($event);
  }
}
