import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {
  NoopValueAccessorDirective,
  UICoreInputProvider,
  UICoreInputComponent,
  injectNgControl,
} from '../ui-input-root.component';
import { Subscription } from 'rxjs';
export const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

@Component({
  selector: 'ui-decimal-input',
  standalone: true,
  templateUrl: 'ui-decimal-input.component.html',
  styleUrls: ['ui-decimal-input.component.scss'],
  hostDirectives: [NoopValueAccessorDirective],
  providers: [UICoreInputProvider(UIDecimalInputComponent)],

  imports: [ReactiveFormsModule, CommonModule, IonicModule, FormsModule],
})
export class UIDecimalInputComponent extends UICoreInputComponent implements OnInit, OnDestroy {
  @Input() name: string | undefined = undefined;

  @Input() placeholder: string | undefined = undefined;
  @Input() autocomplete: boolean = false;

  @Input() max: number | null = null;
  @Input() step: number = 1;
  @Input() min: number | null = null;
  @Input() maxlength: number = 100;
  @Input() minlength: number | null = null;
  @Input() leadingZeros: number | null = null;
  @Input() currency: string = 'EUR';

  private _value: number = 0;
  rootId: string | undefined = undefined;
  ngControl = injectNgControl();

  isDirty = false;

  private inputSub: Subscription | undefined = undefined;

  private _disabled: boolean = false;

  @Input() get disabled(): boolean {
    if (this._disabled) {
      return true;
    }

    if (this.ngControl) {
      return this.ngControl.isDisabled;
    }

    return false;
  }

  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }
  ngOnInit(): void {
    if (this.ngControl?.control) {
      this._value = this.parseNumber(this.ngControl.control.value);
      this.inputSub = this.ngControl.valueChanges?.subscribe(_ => {
        this._value = this.parseNumber(_);
      });
    }
  }

  @Input() get value(): number {
    return this._value;
  }

  set value(value: number) {
    this._value = value;
    this.ngControl?.control.setValue(this.value);
  }

  ngOnDestroy(): void {
    this.inputSub?.unsubscribe();
  }

  validateNumber(number: number) {
    if (this.max && number > this.max) {
      number = this.max;
    }

    if (this.min && number < this.min) {
      number = this.min;
    }
    return number;
  }

  increase(event: any) {
    event.stopPropagation();
    event.preventDefault();

    let input = this.value;
    input -= this.step;
    input = this.validateNumber(input);

    this.value = this.parseNumber(input);
  }

  decrease(event: any) {
    event.stopPropagation();
    event.preventDefault();

    let input = this.value;
    input += this.step;
    input = this.validateNumber(input);

    this.value = this.parseNumber(input);
  }
  parseNumber(currentNumber: string | number) {
    let value = Number(Math.round(parseFloat(currentNumber + 'e' + 2)) + 'e-' + 2);

    if (Number.isNaN(value)) {
      value = 0;
    }

    return value;
  }
  changed(event: any) {
    let origValue = event.target.value || '';

    this.value = this.parseNumber(origValue);
  }
}
