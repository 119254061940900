import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface UITab {
  name: string;
  actions: UITabAction[];
}
export interface UITabActionProp {
  [details: string]: any;
}
export interface UITabAction {
  name: string;
  key: string;
  icon?: string;
  component: any;
  componentProps?: UITabActionProp;
}
@Component({
  selector: 'ui-side-menu',
  templateUrl: './ui-side-menu.component.html',
  styleUrls: ['./ui-side-menu.component.scss'],
})
export class UiSideMenuComponent {
  @Input() activeTab: UITabAction | null = null;
  @Input() tabs: UITab[] = [];
  @Output() onChange: EventEmitter<UITabAction> = new EventEmitter<UITabAction>();

  switchTo(tab: UITabAction) {
    this.onChange.emit(tab);
  }

  isActive(action: UITabAction): boolean {
    if (action.key == this.activeTab?.key) return true;

    return false;
  }
}
