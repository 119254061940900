import { AfterContentInit, ChangeDetectorRef, Component, Injector } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { RestService } from '../../services/rest/rest.service';
import { UIModal } from './ui-modal';

/**
 * @description Tab page for ui modal
 * @author Stefan Boronczyk <stefan@strikd.com>
 */
@Component({ template: '' })
export abstract class ModalTabPage implements AfterContentInit {
  public isInit: boolean = false;

  public get mainData(): any {
    return this.modal?.data;
  }

  public set mainData(value: any) {
    if (this.modal != null) {
      this.modal.data = value;
    }
  }

  public translate: TranslocoService;
  public rest: RestService;
  protected ref: ChangeDetectorRef;
  public modal: UIModal<any> | null = null;

  constructor(protected injector: Injector) {
    this.translate = injector.get(TranslocoService);
    this.rest = injector.get(RestService);
    this.ref = injector.get(ChangeDetectorRef);
  }

  /**
   * @description Determines whether the tab will be activated
   * @author Stefan Boronczyk <stefan@strikd.com>
   */
  public abstract onActivation(): void;

  /**
   * @description Determine when modal will be destroyed
   * @author Stefan Boronczyk <stefan@strikd.com>
   */
  public onDeactivation() {}

  public ngAfterContentInit(): void {
    if (this.isInit) {
      this.onActivation();
      this.ref.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.isInit = false;
    this.onDeactivation();
    this.ref.detectChanges();
  }
}
