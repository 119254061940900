import { FormBuilderOptions } from 'src/app/ui/form-builder/form-builder-options';

export var GroupForm: FormBuilderOptions = {
  tabs: {
    start: {
      title: 'group.step.start',
      description: 'group.step.start_desc',
      sections: {
        general: {
          inputs: {
            image: {
              type: 'avatar',
              centered: true,
              placeholder: 'placeholder/no-group-avatar.webp',
            },
            title: {
              required: true,
              label: 'group.create.group_name',
              type: 'text',
              maxlength: 64,
              clearInput: true,
              placeholder: 'group.create.group_name_placeholder',
              description: 'group.create.group_name_desc',
            },

            desc: {
              maxlength: 120,

              label: 'group.create.short_desc',
              type: 'textarea',
              multipleSelection: true,
              optionsSearchable: true,
              description: 'group.create.short_desc_desc',
              placeholder: 'group.create.short_desc_placeholder',
            },
          },
        },
      },
    },

    details: {
      title: 'group.step.details',
      description: 'group.step.details_desc',
      sections: {
        general: {
          inputs: {
            topics: {
              required: true,
              label: 'group.create.category',
              type: 'tags',
              multipleSelection: true,
              max: 3,
              optionsSearchable: true,
              options: [],
              description: 'group.create.category_desc',
            },

            apps: {
              label: 'group.create.app',
              type: 'tags-search',
              multipleSelection: true,
              optionsSearchable: true,
              clearInput: true,
              description: 'group.create.app_desc',
            },
          },
        },
      },
    },

    join_mode: {
      title: 'group.step.write_mode',
      description: 'group.step.write_mode_desc',
      sections: {
        general: {
          inputs: {
            join_mode: {
              required: true,
              type: 'select-box',
              value: 'public',

              boxOptions: {
                private: {
                  title: 'group.write_modes.private',
                  icon: 'lock-closed',
                  desc: 'group.write_modes.private_desc',
                  iconColor: 'danger',
                },
                public: {
                  title: 'group.write_modes.public',
                  icon: 'lock-open',
                  desc: 'group.write_modes.public_desc',
                  iconColor: 'primary',
                },
              },
            },
          },
        },
      },
    },
  },
};
