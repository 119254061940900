<input
  [type]="type"
  [id]="rootId"
  *ngIf="name"
  [name]="name || ''"
  [placeholder]="placeholder"
  [(ngModel)]="value"
  [disabled]="disabled"
  [autocomplete]="autocomplete ? name : 'nope'"
/>
<button
  [style.visibility]="value ? 'visible' : 'hidden'"
  class="btn btn-xs btn-solid secondary btn-full"
  (click)="clear($event)"
>
  <ion-icon name="close"></ion-icon>
</button>
