import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { AppService } from 'src/app/shared/services/app.service';
import { FavoriteRecord } from '../../models/favorite.record';
import { FavoriteService } from '../../services/favorite.service';
@Component({
  selector: 'app-shortcut-item',
  templateUrl: './app-shortcut-item.component.html',
  styleUrls: ['./app-shortcut-item.component.scss'],
})
export class AppShortcutItemComponent {
  constructor(
    private router: Router,
    private popoverController: PopoverController,
    public appService: AppService,
    public favoriteService: FavoriteService
  ) {}

  @Input() item: FavoriteRecord | null = null;
  isActive() {
    if (!this.item) {
      return false;
    }
    return this.router.url.startsWith(this.item.card.url);
  }
  doClick(event: any, item: FavoriteRecord) {
    this.router.navigateByUrl(item.card.url);
  }

  doDelete() {
    if (!this.item) {
      return;
    }
    this.favoriteService.delete(this.item.type, this.item.typeId);
    this.popoverController.dismiss();
  }
}
