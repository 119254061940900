import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NoPreloading, ROUTES, RouteReuseStrategy, RouterModule } from '@angular/router';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { NgxFileSaverModule } from '@clemox/ngx-file-saver';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { MetafrenzyModule } from 'ngx-metafrenzy';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { OnboardModule } from './auth/components/onboard-modal/onboard.module';
import { AuthStateService, initializeAuthApp } from './auth/services/auth-state.service';
import { AuthInterceptor } from './auth/services/auth.interceptor';
import { CacheInterceptor } from './auth/services/cache.interceptor';
import { LanguageInterceptor } from './auth/services/language.interceptor';
import { B2CModule } from './b2c/b2c.module';
import { ShortcutModule } from './b2c/favorites/shortcuts/shortcut.module';
import { InviteRoutingComponent } from './b2c/friends/pages/invite-routing/invite-routing.component';
import { MainPageComponent } from './b2c/main/pages/main-page/main-page.component';
import { CustomReuseStrategy } from './reuse';
import { DownloadModalComponent } from './shared/components/download-modal/download-modal.component';
import { IssueTrackerComponent } from './shared/components/issue-tracker/issue-tracker/issue-tracker.component';
import { MediaModule } from './shared/directives/media/media.module';
import { ScrollbarModule } from './shared/directives/scrollbar/scrollbar.module';
import { VisibilityModule } from './shared/directives/visibility/visibility.module';
import { WindowSizeModule } from './shared/directives/window-size/window-size.module';
import { AuthenticationGuard } from './shared/guards/auth.guard';
import { TranslateBrowserLoaderModule } from './shared/loaders/translate-browser.loader';
import { NotFoundComponent } from './shared/pages/errors/404/not-found.component';
import { AppService, initializeWidth } from './shared/services/app.service';
import { DesktopService } from './shared/services/electron.service';
import { LangService, initializeLangService } from './shared/services/lang/lang.service';
import {
  PreRouteService,
  initializePreRouteFactory,
} from './shared/services/router/router.service';
import { StorageService } from './shared/services/storage.service';
import { WindowRef } from './shared/services/window.service';
import { InfiniteScrollModule } from './ui/infinite-scroll/ngx-infinite-scroll.module';
import { LoadingContentModule } from './ui/loading-content/loading-content.module';
import { UIModule } from './ui/ui.module';
@NgModule({
  declarations: [
    AppComponent,
    DownloadModalComponent,
    InviteRoutingComponent,
    IssueTrackerComponent,
    NotFoundComponent,
    MainPageComponent,
    AuthComponent,
  ],
  providers: [
    //  provideClientHydration(),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeWidth,
      deps: [AppService],
      multi: true,
    },
    {
      provide: ROUTES,
      multi: true,
      useFactory: initializePreRouteFactory,
      deps: [PreRouteService],
    },
    AuthenticationGuard,
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeLangService,
      deps: [LangService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAuthApp,
      deps: [AuthStateService],
      multi: true,
    },
    {
      provide: WindowRef,
    },
    File,
    StorageService,
    DesktopService,
    SplashScreen,
    StatusBar,
  ],
  bootstrap: [AppComponent],
  imports: [
    RouterModule.forRoot([], {
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'ignore',
      paramsInheritanceStrategy: 'always',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: NoPreloading,
    }),
    BrowserModule.withServerTransition({ appId: 'app' }),
    IonicModule.forRoot({ animated: false }),
    ShortcutModule,
    HttpClientModule,
    FormsModule,
    LoadingContentModule,
    B2CModule,
    MetafrenzyModule.forRoot(),
    CommonModule,
    UIModule,
    OnboardModule,
    TranslocoModule,
    ScrollbarModule,
    InfiniteScrollModule,
    NgxFileSaverModule,
    WindowSizeModule,
    VisibilityModule,
    TransferHttpCacheModule,
    TranslateBrowserLoaderModule,
    MediaModule,
  ],
})
export class AppModule {}
