import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { MediaModule } from 'src/app/shared/directives/media/media.module';
import { ScrollbarModule } from 'src/app/shared/directives/scrollbar/scrollbar.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIModule } from 'src/app/ui/ui.module';
import { IonicSelectableModule } from 'src/app/ui/selectable/ionic-selectable.module';
import { FormBuilderModule } from 'src/app/ui/form-builder/form-builder.module';
import { InviteModalComponent } from './invite-modal.component';
import { InviteFriendsListItem } from './friend-list-item/invite-friend-list-item.component';
import { NoContentFoundModule } from 'src/app/ui/no-content-found/no-content-found.module';
import { UISearchbarModule } from 'src/app/ui/ui-searchbar/ui-searchbar.module';
import { TooltipModule } from 'src/app/shared/directives/tooltip/tooltip.module';

@NgModule({
  imports: [
    IonicModule,
    ReactiveFormsModule,
    ScrollbarModule,
    TooltipModule,
    UIModule,
    NoContentFoundModule,
    UISearchbarModule,
    FormsModule,
    CommonModule,
    FormBuilderModule,
    IonicSelectableModule,
    MediaModule,
    RouterModule,
    TranslocoModule,
  ],
  declarations: [InviteFriendsListItem, InviteModalComponent],
  exports: [InviteModalComponent],
})
export class InviteModalModule {}
