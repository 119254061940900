import { Component, OnDestroy, OnInit } from '@angular/core';
import { CoreTopLevelPage } from './core-top-level-page';

/**
 * @description Main Page (Top Level)
 * @author Stefan Boronczyk <stefan@strikd.com>
 */
@Component({ template: '' })
export abstract class TopLevelPage extends CoreTopLevelPage implements OnInit, OnDestroy {
  ngOnInit(): void {
    super.ngOnInit();
    this.updateMeta();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
