<ion-button fill="clear" color="danger" class="close-button" (click)="dismiss()">
  <ion-icon name="close-outline" slot="icon-only"> </ion-icon>
</ion-button>

<div class="modal-content">
  <app-form-builder-wizard
    (success)="success($event)"
    uri="space/private"
    (avaibleSteps)="setSteps($event)"
    #wizard
    [options]="form"
  ></app-form-builder-wizard>
</div>

<ion-footer class="modal-footer">
  <ion-grid *ngIf="steps != null">
    <ion-row>
      <ion-col size="6">
        <button
          transloco="wizard.step_back"
          class="btn btn-solid btn-xs secondary"
          (click)="wizard?.gotoPrev()"
          *ngIf="steps.hasPrev"
        ></button>
      </ion-col>
      <ion-col size="6" class="flex-right">
        <button
          transloco="wizard.step_forward"
          (click)="wizard?.gotoNext()"
          class="btn btn-solid btn-xs primary"
          [disabled]="!steps.nextAvaible"
          *ngIf="steps.hasNext"
        ></button>
        <button
          class="btn btn-solid btn-xs primary"
          (click)="wizard.submit()"
          *ngIf="!steps.hasNext"
          [disabled]="!steps.nextAvaible"
          transloco="group.create.submit"
        ></button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
