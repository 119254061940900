import {
  AfterContentChecked,
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { SatPopover } from '../../ui-popover/popover.component';
import { UINavigationContextDirective } from '../ui-navigation-context.directive';
import { UiNavigationRootComponent } from '../ui-navigation-root/ui-navigation-root.component';

@Component({
  selector: 'ui-navigation-element',
  templateUrl: './ui-navigation-element.component.html',
  styleUrls: ['./ui-navigation-element.component.scss'],
})
export class UiNavigationElementComponent
  implements AfterContentInit, AfterContentChecked, OnDestroy
{
  @Input() url: string | undefined = undefined;
  @Input() urlParams: Params | undefined = undefined;
  @Input() urlFragment: string | undefined = undefined;
  @ContentChildren(UINavigationContextDirective, {
    emitDistinctChangesOnly: true,
    descendants: true,
  })
  contextChildrens: QueryList<UINavigationContextDirective> | undefined;

  @ViewChild('holdContextChilds', { static: false }) holdContextChilds:
    | ElementRef<UiNavigationRootComponent>
    | undefined = undefined;

  @ViewChild('popover', { static: false }) contextMenu: SatPopover | undefined = undefined;

  @Input() exactUrl: boolean = false;
  @Input() isActive: boolean = false;
  @Input() isSecond: boolean = false;
  @Input() isHighliting: boolean = false;
  @Input() hasImageContent: boolean = false;
  @Input() hasContextMenu: boolean = false;
  @Input() hasActionButton: boolean = false;

  @Input() isCentered: boolean = false;
  @Input() isTrashed: boolean = false;
  @Input() thumbnailSize: number = 20;
  @Input() thumbnail: string | undefined | null = undefined;
  @Input() thumbnailLabel: string | undefined = undefined;
  @Input() iconColor: string | undefined = undefined;
  @Input() iconName: string | undefined = undefined;
  @Input() iconSlot: string | undefined = 'left';
  @Input() contextAligment: string | undefined = 'start';
  @Input() contextSide: string | undefined = 'right';
  @Input() iconSrc: string | undefined = undefined;
  @Output() onClick = new EventEmitter<MouseEvent>();

  private sub: Subscription | undefined = undefined;
  constructor(private ref: ChangeDetectorRef) {}
  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
  ngAfterContentChecked() {
    this.ref.detectChanges();

    if (this.contextChildrens) {
      this.contextChildrens?.forEach(_ => {
        this.detectChanges([_]);
      });
    }
  }
  async openContextMenu(event: any) {
    if (!this.hasContextMenu) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    await this.contextMenu?.toggle();
  }
  hasContextItems = false;

  ngAfterContentInit(): void {
    this.sub = this.contextChildrens?.changes.subscribe(
      (_: QueryList<UINavigationContextDirective>) => {
        this.detectChanges(_.toArray());
      }
    );
  }

  detectChanges(items: UINavigationContextDirective[]) {
    if (this.holdContextChilds) {
      this.hasContextItems = items.length > 0;
      for (let item of items) {
        if (item.isDirty) {
          item.moveToNewNode(this.holdContextChilds.nativeElement);
        }
      }
    }
  }
}
