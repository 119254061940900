import { FormBuilderOptions } from 'src/app/ui/form-builder/form-builder-options';

export var CreateStreamForm: FormBuilderOptions = {
  tabs: {
    access: {
      title: 'stream.access.title',
      description: 'stream.access.desc',
      visible: false,
      sections: {
        general: {
          inputs: {
            server: {
              label: 'stream.server',
              readOnly: true,
              type: 'readonly-copy',
            },
            token: {
              label: 'stream.token',
              readOnly: true,
              type: 'readonly-copy',
            },
          },
        },
      },
    },
    settings: {
      title: 'stream.informations.title',
      description: 'stream.informations.desc',
      sections: {
        general: {
          inputs: {
            title: {
              label: 'stream.title',
              required: true,
              type: 'text',
            },
            desc: {
              maxlength: 120,
              label: 'stream.desc',
              type: 'textarea',
              multipleSelection: true,
              optionsSearchable: true,
              description: 'stream.desc_desc',
              placeholder: 'stream.desc_placeholder',
            },
            app: {
              label: 'stream.app',
              type: 'tags-search',
              multipleSelection: true,
              max: 1,
              optionsSearchable: true,
              clearInput: true,
              description: 'stream.app_desc',
              placeholder: 'stream.app_placeholder',
            },
            topics: {
              label: 'stream.topics',
              type: 'tags',
              multipleSelection: true,
              optionsSearchable: true,
              max: 3,
              options: [],
              description: 'stream.topics_desc',
            },
          },
        },
      },
    },
  },
};
