<ion-header>
  <ion-toolbar>
    <ion-title [transloco]="title"></ion-title>
  </ion-toolbar>
</ion-header>

<ion-button fill="clear" color="danger" class="close-button" (click)="dismiss()">
  <ion-icon name="close-outline" slot="icon-only"> </ion-icon>
</ion-button>

<div class="ui-tabs">
  <div class="ui-tabs-header">
    <div
      class="ui-tab-nav"
      (click)="activeTab = 'username'"
      [ngClass]="{ active: activeTab == 'username' }"
      *ngIf="inviteByUserIds"
    >
      <ion-icon name="search-outline"></ion-icon>
      <span transloco="invite.by_username"></span>
    </div>

    <div
      class="ui-tab-nav"
      (click)="activeTab = 'friends'"
      [ngClass]="{ active: activeTab == 'friends' }"
      *ngIf="inviteFriends"
    >
      <ion-icon name="person-add-outline"></ion-icon>

      <span transloco="invite.friends"></span>
    </div>
    <div
      class="ui-tab-nav"
      (click)="activeTab = 'link'"
      [ngClass]="{ active: activeTab == 'link' }"
      *ngIf="inviteByLink"
    >
      <ion-icon name="link-outline"></ion-icon>
      <span transloco="invite.by_link"></span>
    </div>
  </div>
  <div class="ui-tabs-content">
    <form
      [formGroup]="inviteByUsernameForm"
      (ngSubmit)="doInviteByUsername(this)"
      class="ui-tabs-tab"
      [ngClass]="{ active: activeTab == 'username' }"
    >
      <div class="ui-tabs-content-inner scrollable inner-padding">
        <ion-grid>
          <ion-row>
            <ion-col size="7">
              <ion-item lines="full">
                <ion-input
                  clearInput="true"
                  name="name"
                  inputmode="text"
                  type="text"
                  formControlName="name"
                  [placeholder]="'register.placeholder.username' | transloco"
                  ngModel
                  required
                >
                </ion-input>
              </ion-item>
            </ion-col>
            <ion-col size="5">
              <ion-item lines="full">
                <ion-label class="ion-floating" color="secondary">#</ion-label>
                <ion-input
                  clearInput="false"
                  name="uid"
                  inputmode="number"
                  minlength="4"
                  maxlength="4"
                  type="tel"
                  pattern="[0-9]*"
                  formControlName="uid"
                  [placeholder]="'register.placeholder.uid' | transloco"
                  ngModel
                  required
                >
                </ion-input>
              </ion-item>
            </ion-col>
            <ion-col size="12" style="padding-top: 12px">
              <button
                class="btn btn-xs btn-solid primary"
                type="submit"
                transloco="user.friends.invite"
              ></button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </form>
    <div class="ui-tabs-tab" [ngClass]="{ active: activeTab == 'link' }">
      <div class="ui-tabs-content-header">
        <form
          [formGroup]="generateInviteLinkForm"
          (ngSubmit)="generateInviteLink(this)"
          *ngIf="inviteByLink"
        >
          <div class="invite-by-link">
            <div class="btn-list full-width">
              <ion-select
                class="invite-actions"
                name="lifetime"
                interface="popover"
                [interfaceOptions]="{
                  size: 'cover',
                  side: 'bottom',
                  alignment: 'start'
                }"
                formControlName="lifetime"
              >
                <ion-select-option
                  value="hour"
                  transloco="user.friends.onehour"
                ></ion-select-option>
                <ion-select-option value="day" transloco="user.friends.oneday"></ion-select-option>
                <ion-select-option
                  value="week"
                  transloco="user.friends.oneweek"
                ></ion-select-option>
                <ion-select-option
                  value="month"
                  transloco="user.friends.onemonth"
                ></ion-select-option>
              </ion-select>

              <button
                color="primary"
                class="btn btn-xs btn-solid primary"
                type="submit"
                transloco="user.friends.generate"
              ></button>
            </div>
          </div>
        </form>
      </div>
      <div class="ui-tabs-content-inner">
        <div class="outer-content scrollable">
          <div class="outer-content-abs">
            <ui-list>
              <app-no-content-found
                class="no-border-radius"
                *ngIf="inviteLinks.length <= 0"
              ></app-no-content-found>

              <div class="invite-links">
                <ng-container *ngFor="let invite of inviteLinks.slice().reverse()">
                  <ng-container
                    *ngTemplateOutlet="inviteLink; context: { invite: invite }"
                  ></ng-container>
                </ng-container>
              </div>
            </ui-list>
          </div>
        </div>
      </div>
    </div>
    <div class="ui-tabs-tab" [ngClass]="{ active: activeTab == 'friends' }">
      <div class="ui-tabs-content-header margin-down-12">
        <app-ui-searchbar
          [placeholder]="'friends.search.placeholder' | transloco"
          (onChange)="search($event)"
        ></app-ui-searchbar>
      </div>
      <div class="ui-tabs-content-inner">
        <div class="outer-content scrollable">
          <div class="outer-content-abs">
            <ui-list>
              <app-invite-friends-list-item
                style="width: 100%; display: block"
                (selectionChanged)="
                  onFriendSelectionChange(friend.id.toString(), friend.name, $event)
                "
                [item]="friend"
                [isDisabled]="disableUserIds.includes(friend.id)"
                [last]="last"
                *ngFor="let friend of friends | async; let last = last"
              >
              </app-invite-friends-list-item>

              <app-no-content-found
                class="no-border-radius"
                *ngIf="friendsAmount <= 0"
              ></app-no-content-found
            ></ui-list>
          </div>
        </div>
      </div>
      <div class="ui-tabs-content-footer">
        <ion-button
          color="primary"
          [disabled]="onInviteFriends || selectedFriendsAmout <= 0"
          (click)="submitInviteFriends()"
          >{{ submitTitle || 'user.friends.invite' | transloco }}</ion-button
        >
      </div>
    </div>
  </div>
</div>

<ng-template #inviteLink let-invite="invite">
  <div class="invite-link">
    <div class="invite-link-inner">
      <input
        class="invite-link-input"
        [value]="invite.url"
        #link
        (click)="selectText(link)"
        readonly
      />
      <button
        class="btn btn-xxs btn-solid btn-only-icon secondary uppercase"
        (click)="copyInviteLink(invite.url)"
        [tooltip]="'Copy to clipboard'"
        placement="top"
      >
        <ion-icon name="clipboard-outline"></ion-icon>
      </button>

      <button
        [tooltip]="'Remove link'"
        placement="top"
        (click)="removeInviteLink(invite.hash_id, invite)"
        class="btn btn-xxs btn-solid btn-only-icon danger uppercase"
      >
        <ion-icon name="trash-outline"></ion-icon>
      </button>
    </div>
    <div class="invite-link-expires">
      <ng-container *ngIf="invite.valid_until && invite.seconds_remaining > 0 && invite.useable">
        <span transloco="user.friends.expiresin"></span>
        <span>
          {{ invite.days_remaining | number : '2.0-0' }}:{{
            invite.hours_remaining | number : '2.0-0'
          }}:{{ invite.seconds_remaining * 1000 | date : 'mm:ss' }}
        </span>
      </ng-container>
      <span
        *ngIf="(invite.valid_until && invite.seconds_remaining <= 0) || !invite.useable"
        transloco="user.friends.isexpired"
      >
      </span>
      <span
        *ngIf="!invite.valid_until && invite.useable"
        transloco="user.friends.indefinitelyvalid"
      ></span>
    </div>
  </div>
</ng-template>
