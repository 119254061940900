import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonPopover, ModalController, PopoverController } from '@ionic/angular';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { RestService } from 'src/app/shared/services/rest/rest.service';
import { DesktopService } from '../../../shared/services/electron.service';
import { CreateGroupModalComponent } from '../../spaces/components/create-group-modal/create-group-modal.component';
import { StreamPrepareModalComponent } from '../../streams/components/stream-prepare-modal/stream-prepare-modal.component';

@Component({
  selector: 'app-shortcut-quick-action',
  templateUrl: './shortcut-quick-action.component.html',
  styleUrls: ['./shortcut-quick-action.component.scss'],
})
export class ShortcutsQuickActionComponent {
  @ViewChild('popover', { static: true }) popover: IonPopover | undefined = undefined;
  async openPopover(event: MouseEvent) {
    if (this.popover) {
      await this.popover.present(event);
      await this.popover.onDidDismiss();
    }
  }

  constructor(
    public router: Router,
    public authService: AuthStateService,
    public rest: RestService,
    public popoverController: PopoverController,
    public modalController: ModalController,
    public desktopService: DesktopService
  ) {}

  async createGroup(event: any, disablePopover = false) {
    event.stopPropagation();
    event.preventDefault();
    if (disablePopover) {
      this.popoverController.dismiss();
    }
    if (this.authService.isLoggedIn) {
      const modal = await this.modalController.create({
        component: CreateGroupModalComponent,
        cssClass: 'auto-height ',
      });
      modal.present();
    } else {
      this.router.navigateByUrl('/auth/login');
    }
  }
  async createStream(event: any, disablePopover = false) {
    event.stopPropagation();
    event.preventDefault();
    if (disablePopover) {
      this.popoverController.dismiss();
    }
    if (this.authService.isLoggedIn) {
      const modal = await this.modalController.create({
        component: StreamPrepareModalComponent,
        cssClass: ' full-modal',
        componentProps: {
          apiUrl: 'streams/prepare?t=' + new Date().getTime(),
        },
      });
      modal.present();
    } else {
      this.router.navigateByUrl('/auth/login');
    }
  }
}
