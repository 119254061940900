import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Request, Response } from 'express';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { DesktopService } from '../../shared/services/electron.service';
//const local storage key for token
const TOKEN_KEY = 'X-Auth-Token';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  isBrowser = false;

  private token: string | null = null;

  constructor(
    public cookieService: CookieService,
    public desktopService: DesktopService,
    @Optional() @Inject(REQUEST) private req: Request<any>,
    @Optional() @Inject(RESPONSE) private res: Response<any>,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cookieServiceSsr: SsrCookieService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  handleData(tokenData: any) {
    this.token = JSON.stringify(tokenData);
    if (this.desktopService.isDesktop()) {
      this.cookieService.set(TOKEN_KEY, this.token, {
        expires: 365,
        path: '/',
        secure: true,
        domain: 'localhost',
      });
    } else if (this.isBrowser) {
      this.cookieService.set(TOKEN_KEY, this.token, {
        expires: 365,
        path: '/',
        secure: true,
        domain: environment.domain,
      });
    }
  }

  /**
   * Get token data from local browser storage
   *
   * @returns
   */
  getTokenData(): any | null {
    if (this.token != null) {
      return JSON.parse(this.token);
    }
    if (this.isBrowser || this.desktopService.isDesktop()) {
      const cookieExists: boolean = this.cookieService.check(TOKEN_KEY);
      if (cookieExists) {
        this.token = this.cookieService.get(TOKEN_KEY);
        return JSON.parse(this.token);
      }
    } else {
      const cookieExists: boolean = this.cookieServiceSsr.check(TOKEN_KEY);
      if (cookieExists) {
        this.token = this.cookieServiceSsr.get(TOKEN_KEY);
        return JSON.parse(this.token);
      }
    }

    return null;
  }

  /**
   * @description Gets the current token (In case of server, null)
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @returns
   */
  getToken() {
    const data = this.getTokenData();
    if (data === undefined || data === null || data === '') {
      return null;
    } else {
      return data['access_token'];
    }
  }

  /**
   * @description Determines whether valid token is
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @returns
   */
  public isValidToken() {
    const token = this.getToken();

    if (token) {
      return true;
    }

    return false;
  }

  /**
   * @description Remove a token from the local storage
   * @author Stefan Boronczyk <stefan@strikd.com>
   */
  removeToken() {
    if (this.isBrowser) {
      this.cookieService.delete(TOKEN_KEY, '/', environment.domain);
    } else {
      this.cookieServiceSsr.delete(TOKEN_KEY, '/', environment.domain);
    }
    this.token = null;
  }
}
