import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
  UrlSegment,
} from '@angular/router';
import { UIPage } from './shared/framework/ui-page';
import { TopLevelPage } from './shared/framework/top-level-page';
import { TopLevelRestPage } from './shared/framework/top-level-rest-page';
import { SubLevelRestPage } from './shared/framework/sub-level-rest-page';
import { SubLevelPage } from './shared/framework/sub-level-page';
import { ComponentRef } from '@angular/core';

export class CustomReuseStrategy implements RouteReuseStrategy {
  public static handlers: { [key: string]: DetachedRouteHandle } = {};

  private static waitDelete: string;

  public static deleteRouteSnapshot(name: string): void {
    if (CustomReuseStrategy.handlers[name]) {
      delete CustomReuseStrategy.handlers[name];
    } else {
      CustomReuseStrategy.waitDelete = name;
    }
  }

  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if (route.routeConfig && route.routeConfig.data && route.routeConfig.data.reuse !== undefined) {
      return route.routeConfig.data.reuse;
    }

    return true;
  }

  public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (
      CustomReuseStrategy.waitDelete &&
      CustomReuseStrategy.waitDelete == this.getRouteUrl(route)
    ) {
      CustomReuseStrategy.waitDelete = '';
      return;
    }
    CustomReuseStrategy.handlers[this.getRouteUrl(route)] = handle;
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!CustomReuseStrategy.handlers[this.getRouteUrl(route)];
  }

  private lastRestoredRoute: ComponentRef<any> | undefined = undefined;
  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.routeConfig) {
      return {};
    }
    const handler = CustomReuseStrategy.handlers[this.getRouteUrl(route)] as any;
    const ref = handler?.componentRef as ComponentRef<any>;
    if (
      ref.instance instanceof SubLevelPage ||
      ref.instance instanceof SubLevelRestPage ||
      ref.instance instanceof TopLevelPage ||
      ref.instance instanceof TopLevelRestPage
    ) {
      if (this.lastRestoredRoute != ref) {
        ref.instance.onReload();
      }

      this.lastRestoredRoute = ref;
    }

    return CustomReuseStrategy.handlers[this.getRouteUrl(route)];
  }

  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (
      future.routeConfig &&
      future.routeConfig.data &&
      future.routeConfig.data.reuse !== undefined
    ) {
      return future.routeConfig.data.reuse;
    }

    return (
      future.routeConfig === curr.routeConfig &&
      JSON.stringify(future.params) === JSON.stringify(curr.params)
    );
  }

  private getRouteUrl(route: ActivatedRouteSnapshot) {
    const segments: UrlSegment[][] = route.pathFromRoot.map(r => r.url);
    const subpaths = ([] as UrlSegment[]).concat(...segments).map(segment => segment.path);
    return segments.length + '-' + subpaths.join('/');
  }
}
