import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {
  ICoreInput,
  NoopValueAccessorDirective,
  UICoreInputComponent,
  UICoreInputProvider,
  injectNgControl,
} from '../ui-input-root.component';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-password',
  templateUrl: './ui-password.component.html',
  styleUrls: ['./ui-password.component.scss'],
  standalone: true,
  hostDirectives: [NoopValueAccessorDirective],
  providers: [UICoreInputProvider(UiPasswordComponent)],
  imports: [ReactiveFormsModule, CommonModule, IonicModule, FormsModule],
})
export class UiPasswordComponent
  extends UICoreInputComponent
  implements ICoreInput, OnInit, OnDestroy
{
  setInputType(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.type = this.type == 'text' ? 'password' : 'text';
  }
  @Input() autocomplete: boolean = false;
  @Input() name: string | undefined = undefined;
  rootId: string | undefined = undefined;
  @Input() placeholder: string | undefined = undefined;
  type: string = 'password';

  ngControl = injectNgControl();
  private _value: string | undefined = undefined;
  private inputSub: Subscription | undefined = undefined;

  ngOnInit(): void {
    if (this.ngControl?.control) {
      this._value = this.ngControl.control.getRawValue();
      this.inputSub = this.ngControl.valueChanges?.subscribe(_ => {
        this._value = _;
      });
    }
  }
  private _disabled: boolean = false;

  @Input() get disabled(): boolean {
    if (this._disabled) {
      return true;
    }

    if (this.ngControl) {
      return this.ngControl.isDisabled;
    }

    return false;
  }

  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }
  @Input() get value(): string | undefined {
    return this._value;
  }

  set value(value: string | undefined) {
    this._value = value;
    this.ngControl?.control.setValue(this.value);
  }

  ngOnDestroy(): void {
    this.inputSub?.unsubscribe();
  }
}
