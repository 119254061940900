<div *ngFor="let tab of (options.tabs | keyvalue : unsorted) || []">
  <div
    class="tab-header"
    *ngIf="tab.value.title"
    [style.display]="
      tab.value.visible != undefined ? (tab.value.visible ? 'block' : 'none') : 'block'
    "
  >
    <h3 class="title" [transloco]="tab.value.title"></h3>
    <p class="desc" [transloco]="tab.value.description" *ngIf="tab.value.description"></p>
  </div>
  <app-form-builder-elements
    [style.display]="
      tab.value.visible != undefined ? (tab.value.visible ? 'block' : 'none') : 'block'
    "
    [formGroup]="getForm(tab.key)"
    *ngIf="hasForm(tab.key)"
    [errors]="errors"
    [tab]="tab.value"
  >
  </app-form-builder-elements>
</div>
