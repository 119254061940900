<div class="app-item" [ngClass]="{ active: active }">
  <div class="app-item-hover" [ngClass]="{ 'no-footer': !hasFooter }">
    <a class="app-item-skeleton" routerDirection="forward" [routerLink]="url">
      <div class="app-item-skeleton-inner">
        <div [ngClass]="'aspect-ratio--' + tileRatio">
          <ion-skeleton-text class="center-img"></ion-skeleton-text>

          <div class="app-img-holder">
            <img
              class="center-img unloaded"
              [alt]="title"
              loading="lazy"
              (load)="imageLoaded($event)"
              [src]="thumbnail"
              *ngIf="thumbnail"
            />
          </div>

          <div class="app-item-skeleton-content">
            <div class="app-item-skeleton-content-inner">
              <div class="app-item-action-header">
                <ng-content select="[left]]"></ng-content>
                <ng-content select="[right]"></ng-content>
              </div>

              <div class="top-down-list">
                <ng-content select="[down]"></ng-content>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div class="app-item-content" *ngIf="hasFooter">
    <div class="app-item-content-inner">
      <a routerDirection="forward" [routerLink]="url" class="app-item-title">
        <ng-container *ngIf="title">{{ title }}</ng-container>
        <ion-skeleton-text *ngIf="!title"></ion-skeleton-text
      ></a>
    </div>
    <ng-content></ng-content>
  </div>
</div>
