import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Subject, debounceTime, distinctUntilChanged, ReplaySubject, BehaviorSubject } from 'rxjs';
import { RestService } from 'src/app/shared/services/rest/rest.service';

export interface SearchResultData {
  id: number;
  name: string;
  image?: string;
  uri?: string;
}

export interface SearchResult {
  total: number;
  term?: string | null;
  search: SearchResultIndex;
}

export interface SearchResultIndex {
  [key: string]: SearchResultData[];
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private _showMenu = false;
  public isSearchEnabled = false;

  public isSearching = false;
  public searchTerm: string | null = null;
  isBrowser = false;

  private _searchResults: SearchResult = { total: 0, search: {} };
  private searchResultsEvent = new BehaviorSubject<SearchResult>(this._searchResults);
  public searchResults$ = this.searchResultsEvent.asObservable();

  private searchRequest = new Subject<string | null>();

  private refreshSearchEvent = new Subject<boolean>();
  public refreshSearch = this.refreshSearchEvent.asObservable();
  get showMenu(): boolean {
    return this._showMenu;
  }
  set showMenu(value: boolean) {
    this._showMenu = value;
  }
  pushSearch(key: string | null) {
    this.searchRequest.next(key);
    if (key != null && key.length > 0) {
      this.refreshSearchEvent.next(true);
    } else {
      this._searchResults = { total: 0, search: {} };
      this.refreshSearchEvent.next(false);
    }
  }
  constructor(private rest: RestService, @Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (!this.isBrowser) {
      return;
    }
    this.searchRequest
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe((term: string | null) => {
        if (!term || term.length <= 1) {
          this._searchResults = { total: 0, search: {}, term: term };
          this.searchResultsEvent.next(this._searchResults);
          return;
        }

        this.isSearching = true;
        this.rest.post('search', { term: term }).subscribe(
          res => {
            this.isSearching = false;
            this._searchResults = res.data as SearchResult;
            this._searchResults.term = term;
            this.searchResultsEvent.next(this._searchResults);
          },
          err => {
            this.isSearching = false;
            this._searchResults = { total: 0, search: {}, term: term };
            this.refreshSearchEvent.next(false);
          }
        );
      });
  }
}
