import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';

@Directive({
  selector: '[scrollbar]',
})
export class ScrollbarDirective implements OnInit {
  @Input() scrollbar: string = '';
  @Input() allowAllScreens: boolean | string = false;

  hostElement: HTMLElement | null = null;
  isBrowser = false;

  constructor(public elementRef: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.hostElement = this.elementRef.nativeElement;
      if (
        this.hostElement &&
        this.hostElement.tagName &&
        (this.hostElement.tagName == 'ION-CONTENT' || this.hostElement.tagName == 'ION-POPOVER')
      ) {
        let el = document.createElement('style');
        el.innerText = this.getCustomStyle().replace(/(\r\n|\n|\r)/gm, '');
        if (this.hostElement.shadowRoot != null) {
          this.hostElement.shadowRoot.appendChild(el);
        }
      }
    }
  }

  getCustomStyle() {
    return `
         .inner-scroll {
               overflow: visible overlay !important;
            scrollbar-width: auto;
            z-index:999;
        }
        ::-webkit-scrollbar {
            width:6px;
            overflow:hidden;
            z-index:1001;
        }
       .inner-scroll::-webkit-scrollbar-track:vertical {
          border-radius: var(--app-narrow-radius);
          cursor: pointer;
        }
        .inner-scroll::-webkit-scrollbar-thumb:vertical {
          border-radius: var(--app-narrow-radius);
          cursor: pointer;
        }
        .inner-scroll::-webkit-scrollbar-track:horizontal {
          border-radius: var(--app-narrow-radius);
          cursor: pointer;
        }
        .inner-scroll::-webkit-scrollbar-thumb:horizontal {
          border-radius: var(--app-narrow-radius);
          cursor: pointer;
        }

         .inner-scroll::-webkit-scrollbar-corner,  .inner-scroll::-webkit-resizer {
            background:inherit;
        }

          .inner-scroll:hover::-webkit-scrollbar-track:vertical {
              z-index:9999;
          }
          .inner-scroll:hover::-webkit-scrollbar-thumb:vertical {
  background: var(--ion-scrollbar-color);
              }
          .inner-scroll:hover::-webkit-scrollbar-track:horizontal {
  background: var(--ion-scrollbar-color);
          }
          .inner-scroll:hover::-webkit-scrollbar-thumb:horizontal {
  background: var(--ion-scrollbar-color);
          }
          .inner-scroll:hover::-webkit-scrollbar-corner, .inner-scroll:hover::-webkit-resizer {
  background: var(--ion-scrollbar-color);
          }

       `;
  }
}
