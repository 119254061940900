import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CoreSubLevelPage } from './core-sub-level-page';

@Component({ template: '' })
export abstract class SubLevelRestPage<T>
  extends CoreSubLevelPage
  implements OnInit, OnDestroy, OnInit, AfterViewInit
{
  public data: T;
  constructor(protected injector: Injector) {
    super(injector);

    this.data = this.route.snapshot.data.childData as T;
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (!this.isBrowser) {
      this.onActivation();
      this.changeDetectorRef.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      this.onActivation();
      this.changeDetectorRef.detectChanges();
    }
  }

  public abstract onActivation(): void;
  public abstract onDeactivation(): void;
}
