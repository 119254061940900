import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-navigation-root',
  templateUrl: './ui-navigation-root.component.html',
  styleUrls: ['./ui-navigation-root.component.scss'],
})
export class UiNavigationRootComponent {
  @Input() title: string | undefined = undefined;
  @Input() backButtonUrl: string | undefined = undefined;
}
