import { tap } from 'rxjs';

export function restLog<T>(description = '', badgeColor = 'darkCyan') {
  const badge = (color: string) =>
    `background:${color}; color:white; padding:4px; margin-right:4px; border-radius:3px; font-size:9px;`;

  return tap<T>({
    next: value => console.log(`%c${description} (response)`, badge(badgeColor)),
    error: error => console.log(`%c${description} (error)`, badge('fireBrick'), error),
    complete: () => console.log(`%c${description} (complete)`, badge('slateGray')),
  });
}
