<ion-button color="danger" fill="clear" class="close-button" (click)="dismiss($event)">
  <ion-icon name="close-outline" slot="icon-only"></ion-icon>
</ion-button>

<div class="tab-header" style="display: block">
  <h3 transloco="issue_tracker.create_issue" class="title"></h3>
  <p transloco="issue_tracker.desc" class="desc"></p>

  <div class="input-element">
    <ion-select
      value="issue"
      [interfaceOptions]="{ size: 'cover', showBackdrop: false }"
      [(ngModel)]="type"
      [placeholder]="'issue_tracker.issue_type' | transloco"
    >
      <ion-select-option value="issue">{{ 'bugtracker.issue' | transloco }}</ion-select-option>
      <ion-select-option value="feature">{{ 'bugtracker.feature' | transloco }}</ion-select-option>
    </ion-select>
  </div>

  <div class="input-element">
    <ion-textarea
      scrollbar
      class="scrollable"
      maxlength="1000"
      [(ngModel)]="desc"
      [disabled]="onSubmit"
      [placeholder]="'issue_tracker.issue_placeholder' | transloco"
      rows="8"
    ></ion-textarea>

    <ion-checkbox
      (ionChange)="togglePrivacyCheckbox($event)"
      labelPlacement="end"
      checked="false">
      <label transloco="issue_tracker.privacy_note"></label>
    </ion-checkbox>
  </div>

  <ion-progress-bar [value]="process" color="tertiary" *ngIf="onSubmit"></ion-progress-bar>
  <button
    [disabled]="!desc || desc.length <= 5 || onSubmit || !isPrivacyChecked"
    (click)="submit($event)"
    transloco="issue_tracker.submit"
    class="btn btn-solid btn-xs primary"
  ></button>
</div>
