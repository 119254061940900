import { Location, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injectable,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GestureDetail } from '@ionic/angular';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { AppService } from 'src/app/shared/services/app.service';

export interface Button {
  callback: Function;
  icon: string;
  label?: string | null;
  isMobileOnly: boolean;
}

export interface UICurrentMenu {
  left: Button[];
  right: Button[];
  name: string;
  hasRightMenu: boolean;
}
@Injectable({ providedIn: 'root' })
export class UIContentService {
  private _activeMenus = new BehaviorSubject<{ [key: number]: UICurrentMenu }>({});
  public activeMenusEvent = this._activeMenus.asObservable();
  public currentMenus: { [key: number]: UICurrentMenu } = {};

  private _leftMenuIsOpen: number = 0;
  private _leftMenuIsOpen$ = new ReplaySubject<number>(this._leftMenuIsOpen);
  leftMenuIsOpen$ = this._leftMenuIsOpen$.asObservable();

  public setLeftMenuIsOpenPosX(width: number) {
    this._leftMenuIsOpen = width;
    this._leftMenuIsOpen$.next(width);
  }

  public hasRightMenu = false;
  public addOrUpdateMenu(
    id: number,
    menuName: string,
    left: Button[],
    right: Button[],
    hasRightMenu: boolean
  ) {
    this.currentMenus[id] = {
      name: menuName,
      left: left,
      right: right,
      hasRightMenu: hasRightMenu,
    };
    this._activeMenus.next(this.currentMenus);
  }

  public deleteMenu(id: number) {
    if (this.currentMenus[id] != undefined) {
      delete this.currentMenus[id];
      this._activeMenus.next(this.currentMenus);
    }
  }

  public get activeMenus() {
    return Object.keys(this.currentMenus);
  }

  constructor(public injector: Injector) {}
}

@Component({
  selector: 'ui-page',
  templateUrl: './ui-content-page.component.html',
  styleUrls: ['./ui-content-page.component.scss'],
})
export class UiContentPageComponent implements OnInit, OnDestroy, OnChanges {
  private _id: number = 0;
  isBrowser = false;
  @Input() hasHeader = false;
  @Input() title: string | undefined | null = undefined;
  @Input() desc: string | undefined | null | TemplateRef<any> = undefined;
  @Input() amount: number = -1;

  @Input()
  @Input()
  hasMobileMenu = true;
  @Input() leftOnlyOnMobile = false;
  @Input() hasFavorites = true;
  @Input() rounded = false;
  @Input() roundedLeft = false;
  @Input() hasLeftSide = false;
  @Input() icon: string | null = null;
  @Input() hasRightSide = false;
  @Input() headerOnlyOnMobile = false;
  @Input() hasHeaderLeft = false;
  @Input() hasBackButton = false;
  @Input() swapOnDesktop = false;
  @Input() forceDarkThemeLeft: boolean = false;
  @ViewChild('content', { static: true }) content: ElementRef<HTMLDivElement> | undefined =
    undefined;
  @ViewChild('scrollbar', { static: true }) scrollbar: ElementRef<HTMLDivElement> | undefined =
    undefined;

  public hasBackHistory = false;

  get descTemplate(): TemplateRef<any> | null {
    if (this.desc instanceof TemplateRef<any>) {
      return this.desc;
    } else {
      return null;
    }
  }
  imageLoaded($event: any) {
    $event.target.classList.add('loaded');
  }
  goBack(event: any) {
    if (this.hasBackHistory) {
      // We can safely go back to the previous location as
      // we know it's within our app.
      this.location.back();
    } else {
      // There's no previous navigation.
      // Here we decide where to go. For example, let's say the
      // upper level is the index page, so we go up one level.
      this.router.navigate(['..'], { relativeTo: this.route });
    }
  }

  get isDescTemplate(): boolean {
    return this.desc instanceof TemplateRef<any>;
  }

  get descString(): string | null {
    if (this.desc == null || this.desc == undefined) {
      return null;
    }
    if (!(this.desc instanceof TemplateRef<any>)) {
      return this.desc;
    } else {
      return null;
    }
  }

  public hasLeftSideEnabled = this.hasLeftSide;
  public hasRightSideEnabled = this.hasRightSide;
  public isWapped = false;
  private _leftButtons = new BehaviorSubject<Button[]>([]);
  public leftButtons$ = this._leftButtons.asObservable();
  private _rightButtons = new BehaviorSubject<Button[]>([]);
  public rightButtons$ = this._rightButtons.asObservable();
  private mobileSub: Subscription | null = null;
  private gestureSub: Subscription | null = null;
  private gestureEndSub: Subscription | null = null;
  private tabletSub: Subscription | null = null;
  private routerSub: Subscription | null = null;
  private uiButtonSub: Subscription | null = null;
  public hasRightMenu = false;
  public hasLeftMenu = false;

  constructor(
    public appService: AppService,
    public authService: AuthStateService,
    public uiService: UIContentService,
    public change: ChangeDetectorRef,
    private readonly route: ActivatedRoute,

    private location: Location,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this._id = new Date().getTime();
    this.isBrowser = isPlatformBrowser(platformId);

    this.gestureSub = this.appService.gesture$.subscribe(detail => {
      this.onMove(detail);
    });
    this.hasBackHistory = !!this.router.getCurrentNavigation()?.previousNavigation;
    location.onUrlChange(
      () => (this.hasBackHistory = !!this.router.getCurrentNavigation()?.previousNavigation)
    );

    this.gestureEndSub = this.appService.gestureEnd$.subscribe(detail => {
      this.onEnd();
    });

    this.mobileSub = this.appService.mainMobileMenuSub.subscribe(df => {
      this.isMobile = df;
      this.updateService();
    });

    this.tabletSub = this.appService.isTabletMode$.subscribe(df => {
      this.isTabletMode = df;
      this.updateService();
    });

    if (this.isBrowser) {
      this.routerSub = this.router.events.subscribe(evt => {
        if (evt instanceof NavigationEnd) {
          if (this.scrollbar) {
            this.scrollbar.nativeElement.scrollTop = 0;
          }
        }
        this.openLeftMenu = false;
        this.activateLeftMenu(this.openLeftMenu);

        this.openRightMenu = false;
        this.activeRightMenu(this.openRightMenu);
      });
    }

    this.uiButtonSub = this.uiService.activeMenusEvent.subscribe(df => {
      let leftButtons: Button[] = [];
      let rightButtons: Button[] = [];
      this.hasRightMenu = false;
      this.hasLeftMenu = false;
      for (let key in df) {
        const menu = df[key];

        if (menu.left.length > 0) {
          leftButtons = [...leftButtons, ...menu.left];
          this.hasLeftMenu = true;
        }
        if (menu.right.length > 0) {
          rightButtons = [...rightButtons, ...menu.right];
          this.hasRightMenu = true;
        }
      }
      this._leftButtons.next(leftButtons);
      this._rightButtons.next(rightButtons);
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.updateService();
  }
  ngOnDestroy(): void {
    this.mobileSub?.unsubscribe();
    this.routerSub?.unsubscribe();
    this.gestureSub?.unsubscribe();
    this.gestureEndSub?.unsubscribe();
    this.uiButtonSub?.unsubscribe();
  }
  public get id() {
    return this._id;
  }
  public deactivated: boolean = false;
  onVisibilityChange(event: any) {
    if (this.isBrowser) {
      if (event == true) {
        this.deactivated = false;
        this.updateService();
      } else {
        this.uiService.deleteMenu(this._id);
        this.deactivated = true;
      }
    }
  }
  @ViewChild('leftTemplate', { read: TemplateRef }) leftTemplate: TemplateRef<any> | null = null;

  @ViewChild('center', { static: true }) center: ElementRef<HTMLDivElement> | undefined = undefined;
  @ViewChild('header', { static: true }) header: ElementRef<HTMLDivElement> | undefined = undefined;
  @ViewChild('left', { static: true }) left: ElementRef<HTMLDivElement> | undefined = undefined;
  @ViewChild('right', { static: true }) right: ElementRef<HTMLDivElement> | undefined = undefined;

  @Input() windowResize = 'ui-content';

  @Input() isFullScreen = false;
  @Input() leftIcon = 'menu';
  @Input() rightIcon = 'information-circle';

  @Input() leftIconLabel = '';
  @Input() rightIconLabel = '';

  ngOnInit(): void {
    this.updateService();
  }

  @Input() public leftMenuWidth = 250;
  @Input() public rightMenuWidth = 300;
  @Input() public isCentered = false;
  @Input() public isHeaderCentered = false;

  private leftMenuSpacing = 130;

  private _leftMenuLeftPosX: number = 0;
  private _leftMenuLeftPosX$ = new ReplaySubject<number>(this._leftMenuLeftPosX);
  leftMenuLeftPosX$ = this._leftMenuLeftPosX$.asObservable();

  private _rightMenuPosX: number = 0;
  private _rightMenuPosX$ = new ReplaySubject<number>(this._rightMenuPosX);
  rightMenuPosX$ = this._rightMenuPosX$.asObservable();

  private _contentPosX: number = 0;
  private _contentPosX$ = new ReplaySubject<number>(this._contentPosX);
  contentPosX$ = this._contentPosX$.asObservable();

  public activeRightMenu(isActive: boolean = false) {
    if (this.hasRightSide) {
      let width: number = 0;
      let widthContent: number = 0;

      if (this.isTabletMode && !isActive) {
        width = this.getRightMenuStartPosX;
        widthContent = 0;
      } else if (this.isTabletMode) {
        width = this.getRightMenuEndPosX;
        widthContent = this.getRightMenuEndPosX;
      }

      this._rightMenuPosX = width;
      this._rightMenuPosX$.next(width);

      this._contentPosX = widthContent;
      this._contentPosX$.next(widthContent);

      this.change.detectChanges();
    }
  }

  public activateLeftMenu(isActive: boolean = false) {
    if (this.hasLeftSide || this.leftOnlyOnMobile) {
      let width: number = 0;
      let widthContent: number = 0;

      if (this.isMobile) {
        width = this.leftMenuWidth * -1;
        if (!isActive) {
          widthContent = 0;
          this.uiService.setLeftMenuIsOpenPosX(this.leftMenuSpacing * -1);
        } else {
          widthContent = this.leftMenuWidth * -1;
          if (this.hasFavorites) {
            widthContent -= this.leftMenuSpacing;
          }
          this.uiService.setLeftMenuIsOpenPosX(0);
        }
      }

      this._leftMenuLeftPosX = width;
      this._leftMenuLeftPosX$.next(width);

      this._contentPosX = widthContent * -1;
      this._contentPosX$.next(widthContent * -1);
    }
  }

  private onEnd() {
    if (this.isMobile && (this.hasLeftSide || this.hasRightSide || this.leftOnlyOnMobile)) {
      let contentPos = this._contentPosX;
      let leftSideEnabled = this._contentPosX >= this.getLeftMenuStartPosX;
      let rightSideEnabled = !(this._contentPosX > this.getRightMenuEndPosX);

      if (!leftSideEnabled && !rightSideEnabled) {
        contentPos = 0;
      }

      this.openLeftMenu = leftSideEnabled;
      this.openRightMenu = rightSideEnabled;

      if (this.hasFavorites && (this.hasLeftSide || this.leftOnlyOnMobile)) {
        this.uiService.setLeftMenuIsOpenPosX(
          contentPos - (this.leftMenuWidth + this.leftMenuSpacing)
        );
      }

      this._contentPosX = contentPos;
      this._contentPosX$.next(this._contentPosX);
      this.change.detectChanges();
    }
  }

  public get getLeftMenuEndPosX(): number {
    if (this.hasFavorites) {
      return this.leftMenuSpacing;
    } else {
      return 0;
    }
  }

  public get getLeftMenuStartPosX(): number {
    if (this.hasFavorites) {
      return this.leftMenuWidth + this.leftMenuSpacing;
    }
    return this.leftMenuWidth;
  }

  public get getRightMenuEndPosX(): number {
    if (this.isMobile) {
      return this.rightMenuWidth * -1;
    }

    return 0;
  }

  public get getRightMenuStartPosX(): number {
    return this.rightMenuWidth * -1;
  }

  private onMove(detail: GestureDetail) {
    if (this.isMobile && (this.hasLeftSide || this.hasRightSide || this.leftOnlyOnMobile)) {
      const deltaX = detail.deltaX;

      let contentPos = 0;

      if (this.hasLeftSide || this.leftOnlyOnMobile) {
        contentPos += Math.min(Math.max(deltaX * 2, 0), this.getLeftMenuStartPosX);
      }

      if (this.hasRightSide) {
        contentPos += Math.max(Math.min(deltaX * 2, 0), this.getRightMenuStartPosX);
        this._contentPosX = this.getRightMenuEndPosX + this.leftMenuWidth;
        this._contentPosX$.next(this._contentPosX);
      }

      if (this.hasFavorites && (this.hasLeftSide || this.leftOnlyOnMobile)) {
        let currentPos = contentPos - (this.leftMenuWidth + this.leftMenuSpacing);
        this.uiService.setLeftMenuIsOpenPosX(currentPos);
      }

      this._contentPosX = contentPos;
      this._contentPosX$.next(this._contentPosX);

      this.change.detectChanges();
    }
  }

  public openLeftMenu: boolean = false;
  public openRightMenu: boolean = false;
  public isMobile: boolean = false;
  public isTabletMode: boolean = false;

  updateService() {
    if (this.deactivated) {
      return;
    }

    this.hasLeftSideEnabled = !this.isMobile && this.leftOnlyOnMobile ? false : this.hasLeftSide;
    this.hasRightSideEnabled = this.hasRightSide;

    let leftSideButtons: Button[] = [];
    let rightSideButtons: Button[] = [];

    if (this.hasLeftSideEnabled || (this.isMobile && this.leftOnlyOnMobile)) {
      leftSideButtons.push({
        label: this.leftIconLabel,
        icon: this.leftIcon,
        callback: () => {
          this.openRightMenu = false;
          this.activeRightMenu(this.openRightMenu);

          this.openLeftMenu = !this.openLeftMenu;
          this.activateLeftMenu(this.openLeftMenu);
        },
        isMobileOnly: true,
      });

      this.activateLeftMenu(this.openLeftMenu);
    }

    if (this.hasRightSide) {
      rightSideButtons.push({
        label: this.rightIconLabel,
        icon: this.rightIcon,
        callback: () => {
          this.openLeftMenu = false;
          this.activateLeftMenu(this.openLeftMenu);
          this.openRightMenu = !this.openRightMenu;
          this.activeRightMenu(this.openRightMenu);
        },
        isMobileOnly: true,
      });
      this.activeRightMenu(this.openRightMenu);
    }

    this.uiService.addOrUpdateMenu(
      this._id,
      this.windowResize,
      leftSideButtons.filter(df => (df.isMobileOnly && this.isMobile) || !df.isMobileOnly),
      rightSideButtons.filter(df => (df.isMobileOnly && this.isTabletMode) || !df.isMobileOnly),
      this.hasRightSide
    );
  }

  closeMenus(event: any) {
    this.openRightMenu = false;
    this.openLeftMenu = false;

    this.activateLeftMenu(this.openLeftMenu);
    this.activeRightMenu(this.openRightMenu);
  }
}
