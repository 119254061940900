import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Injector,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { UITab, UITabAction } from 'src/app/ui/ui-side-menu/ui-side-menu.component';
import { RestService } from '../../services/rest/rest.service';
import { ToastService } from '../../services/toast.service';
import { ModalTabPage } from './modal-tab-page';

/**
 * @description UI Modal
 * @author Stefan Boronczyk <stefan@strikd.com>
 * @template T
 */
@Component({ template: '' })
export abstract class UIModal<T> implements AfterViewInit, OnDestroy {
  protected componentRef: ComponentRef<unknown> | undefined = undefined;
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef | undefined =
    undefined;

  protected initliazedComponentsViews: { [key: string]: ViewRef } = {};
  protected initliazedComponents: { [key: string]: ComponentRef<unknown> } = {};
  protected currentComponent: any | null = null;

  destroyComponents() {
    if (this.currentComponent) {
      this.currentComponent.isInit = false;
    }
    for (let key in this.initliazedComponents) {
      this.initliazedComponents[key].destroy();
    }
    this.initliazedComponents = {};
    this.initliazedComponentsViews = {};
    this.currentComponent = null;
  }

  ngOnDestroy(): void {
    this.destroyComponents();
  }
  /**
   * @description Contain tabs
   */
  public tabs: UITab[] = [];

  /**
   * @description Current active tab
   */
  public activeTab: UITabAction | null = null;

  data: T | null = null;

  translate: TranslocoService;
  modalCtrl: ModalController;
  rest: RestService;
  toast: ToastService;
  ref: ChangeDetectorRef;
  constructor(protected injector: Injector) {
    this.translate = injector.get(TranslocoService);
    this.rest = injector.get(RestService);
    this.modalCtrl = injector.get(ModalController);
    this.toast = injector.get(ToastService);
    this.ref = injector.get(ChangeDetectorRef);
  }

  ngAfterViewInit(): void {
    this.OnModalOpened();

    if (this.activeTab != null) {
      this.loadModalTab(this.activeTab);
    }
  }

  /**
   * @description Called after modal was opened
   * @author Stefan Boronczyk <stefan@strikd.com>
   */
  protected abstract OnModalOpened(): void;

  /**
   * @description Dismiss modal
   * @author Stefan Boronczyk <stefan@strikd.com>
   */
  dismiss() {
    this.modalCtrl.dismiss();
  }

  /**
   * @description Loading an modal tab
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param tab
   * @returns true if component
   */
  public loadModalTab(tab: UITabAction): boolean {
    console.debug('[Modal][Component] ' + tab.name);
    if (this.container != undefined) {
      this.container.detach();
      this.activeTab = tab;
      let created = true;

      if (this.initliazedComponentsViews.hasOwnProperty(tab.name)) {
        const ref = this.initliazedComponentsViews[tab.name];
        const component = this.initliazedComponents[tab.name];
        this.container.move(ref, 0);
        this.componentRef = component;
        created = false;
      } else {
        //   const factory = this.compFactory.resolveComponentFactory(tab.component);
        const component = this.container.createComponent(tab.component);
        this.initliazedComponentsViews[tab.name] = component.hostView;
        this.initliazedComponents[tab.name] = component;
        if (tab.componentProps != undefined) {
          for (let key in tab.componentProps) {
            const value = tab.componentProps[key];
            component.setInput(key, value);
          }
        }
        this.componentRef = component;
        created = true;
      }

      if (this.componentRef != null && this.componentRef != undefined) {
        this.currentComponent = this.componentRef.instance;
        if (this.currentComponent instanceof ModalTabPage) {
          var comp = this.currentComponent as ModalTabPage;
          comp.modal = this;

          if (!comp.isInit) {
            comp.isInit = true;
          }
        }
      }

      this.ref.detectChanges();
      return created;
    } else {
      console.error('[Modal] Cant found container');
    }
    return false;
  }
}
