// main.ts
import { ApplicationRef, enableProdMode, NgModuleRef } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { createNewHosts } from '@angularclass/hmr';
import { AppBrowserModule } from './app/app.browser.module';
import { environment } from './environments/environment';
const origBoot = platformBrowserDynamic().bootstrapModule(AppBrowserModule);

if (environment.hmr && module && module['hot']) {
  console.log('Boot with hmr.');

  const hmrBootstrap = (module: any, bootstrap: () => Promise<NgModuleRef<any>>) => {
    let ngModule: NgModuleRef<any>;
    module.hot.accept();
    bootstrap().then(mod => (ngModule = mod));
    module.hot.dispose(() => {
      const appRef: ApplicationRef = ngModule.injector.get(ApplicationRef);
      const componentRef = appRef.components[0];

      enableDebugTools(componentRef);

      const elements = appRef.components.map(c => c.location.nativeElement);
      const makeVisible = createNewHosts(elements);
      ngModule.destroy();
      makeVisible();
    });
  };
  hmrBootstrap(module, () => origBoot);
} else {
  const bootstrap = () => {
    origBoot.catch(err => console.error(err));
  };

  if (document.readyState === 'complete') {
    bootstrap();
  } else {
    document.addEventListener('DOMContentLoaded', bootstrap);
  }
}
