import { AfterContentInit, Component, OnInit } from '@angular/core';
import { ModalTabPage } from './modal-tab-page';

/**
 * @description Tab page for ui modal (rest)
 * @author Stefan Boronczyk <stefan@strikd.com>
 * @template T
 */
@Component({ template: '' })
export abstract class ModalTabPageRest<T> extends ModalTabPage implements AfterContentInit {
  data: T | null = null;
  isLoading: boolean = true;
  public abstract getUrl(): string | null;

  public ngAfterContentInit(): void {
    if (this.isInit) {
      this.isLoading = true;

      var url = this.getUrl();
      if (url == null) {
        console.error('Url is empty');
        return;
      }
      this.rest.get(url).subscribe(
        result => this.handleData(result),
        error => {
          this.data = null;
          this.isLoading = false;

          this.ref.detectChanges();
        }
      );
    }
  }

  private handleData(data: any = null) {
    console.debug('[ModalTabPage][' + this.constructor.name + '] HandleData');

    this.data = data?.data as T;
    this.isLoading = false;
    this.onActivation();
    this.ref.detectChanges();
  }
}
