import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime } from 'rxjs';

export interface WindowResizeEvent {
  name: string;
  size: number;
  element: ElementRef<any> | undefined;
}

@Injectable({
  providedIn: 'root',
})
export class WindowSizeService {
  private currentSizes: { [name: string]: WindowResizeEvent } = {};
  private currentWidth = new BehaviorSubject<WindowResizeEvent>({
    name: 'body',
    size: 1024,
    element: undefined,
  });

  public currentWidthSub = this.currentWidth.asObservable();

  public getWidth(name: string): number {
    if (this.currentSizes[name] == undefined) {
      return 1024;
    } else {
      return this.currentSizes[name].size;
    }
  }

  public getElement(name: string): ElementRef<any> | undefined {
    if (this.currentSizes[name] == undefined) {
      return undefined;
    } else {
      return this.currentSizes[name].element;
    }
  }

  public scorllTo(name: string) {
    const element = this.getElement(name);
    if (element != null) {
      element.nativeElement.scrollTo({
        top: 0,
        left: 0,
      });
    }
  }

  public setWidth(host: ElementRef<any> | undefined, name: string, width: number) {
    if (this.currentSizes[name] == undefined || this.currentSizes[name].size != width) {
      const rec: WindowResizeEvent = { element: host, name: name, size: width };
      this.currentSizes[name] = rec;
      this.currentWidth.next(rec);
    }
  }
}
