import { Observable, Subject } from 'rxjs';
import { NetworkCommand } from './command';

/**
 * This class represents a basic channel.
 */
export abstract class Channel {
  /**
   * The Echo options.
   */
  options: any;

  protected _commands = new Subject<NetworkCommand[]>();
  public commands$ = this._commands.asObservable();

  /**
   * Listen for an event on the channel instance.
   */
  abstract listen(event: string, callback: Function, opt: any): Channel;

  /**
   * Listen for a whisper event on the channel instance.
   */
  listenForWhisper(event: string, callback: Function, opt: any = null): Channel {
    return this.listen('.client-' + event, callback, opt);
  }

  /**
   * Listen for an event on the channel instance.
   */
  notification(callback: Function, opt: any): Channel {
    return this.listen(
      '.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
      callback,
      opt
    );
  }

  /**
   * Stop listening to an event on the channel instance.
   */
  abstract stopListening(event: string, callback?: Function): Channel;

  /**
   * Stop listening for a whisper event on the channel instance.
   */
  stopListeningForWhisper(event: string, callback?: Function): Channel {
    return this.stopListening('.client-' + event, callback);
  }

  /**
   * Register a callback to be called anytime a subscription succeeds.
   */
  abstract subscribed(callback: Function): Channel;

  /**
   * Register a callback to be called anytime an error occurs.
   */
  abstract error(callback: Function): Channel;
}
