import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ChildrenOutletContexts, NavigationEnd, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { BasketService } from 'src/app/b2c/basket/services/basket.service';
import { NotificationService } from 'src/app/b2c/notifications/services/notification.service';
import { DesktopService } from 'src/app/shared/services/electron.service';
import { AppService } from '../shared/services/app.service';
import { UIContentService } from '../ui/ui-content-page/ui-content-page.component';
import { SearchService } from './search/services/search.service';

@Component({
  selector: 'app-b2c',
  templateUrl: './b2c.component.html',
  styleUrls: ['./b2c.component.scss'],
})
export class B2CComponent implements OnInit, AfterViewInit {
  isBrowser = false;
  @ViewChild('pageContent', { static: true }) content!: ElementRef<HTMLDivElement>;

  isHome = true;
  constructor(
    public authState: AuthStateService,
    public menu: MenuController,
    public basketService: BasketService,
    public notifyService: NotificationService,
    public searchService: SearchService,
    public uiService: UIContentService,
    public check: ChangeDetectorRef,
    public appService: AppService,
    @Inject(DOCUMENT) private document: Document,

    private contexts: ChildrenOutletContexts,
    public menuController: MenuController,
    private router: Router,
    public desktopService: DesktopService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.checkIsHome(this.router.url);

    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.checkIsHome(evt.url);
        this.authState.doOnboarding(evt.url);
      }
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (this.isBrowser) {
        this.content.nativeElement.scrollTo(0, 0);
      }
    });

    this.authState.userAuthed.subscribe(_ => {
      this.authState.doOnboarding(this.router.url);
    });
  }

  public sideLeftWidth: number = 0;

  checkIsHome(url: string) {
    if (this.desktopService.isDesktop()) {
      this.isHome = false;
      return;
    }
    if (url == '/home' || url == '' || url == '/') {
      this.isHome = true;
    } else {
      this.isHome = false;
    }
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  closeSideMenus(event: any) {
    this.searchService.showMenu = false;
  }

  ngOnInit(): void {
    //setup left and right menu
    if (this.isBrowser) {
      this.setMenu(false);
      this.authState.userAuthed.subscribe(result => {
        this.setMenu(result);
      });
    }

    this.uiService.leftMenuIsOpen$.subscribe(df => {
      this.sideLeftWidth = df;
      this.check.detectChanges();
    });
  }
  ngAfterViewInit(): void {
    if (this.document) {
      this.document.body.classList.add('hide-splash');
    }
  }
  setMenu(value: boolean) {
    if (value) {
      this.menu.enable(true, 'navMenu');
      this.menu.enable(true, 'chatMenu');
      this.menu.open('chatMenu');
    } else {
      this.menu.enable(false, 'chatMenu');
      this.menu.enable(true, 'navMenu');
      this.menu.close('chatMenu');
    }
  }
}
