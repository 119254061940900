<ion-button fill="clear" color="danger" class="close-button" (click)="dismiss()">
  <ion-icon name="close-outline" slot="icon-only"> </ion-icon>
</ion-button>

<div class="modal-content">
  <div class="tab-header">
    <h3 class="title" transloco="title.download_launcher"></h3>
  </div>

  <div class="operating-systems">
    <div class="operating-system">
      <ion-icon color="windows" name="logo-windows"></ion-icon>
      <span class="platform-title">Windows</span>
      <div class="actions">
        <button
          (click)="download($event, 'exe', 'win32_x64')"
          class="btn btn-xs btn-block btn-solid secondary"
        >
          .exe
        </button>
      </div>
    </div>
    <div class="operating-system">
      <ion-icon color="tux" name="logo-tux"></ion-icon>

      <span class="platform-title">Linux</span>
      <div class="actions">
        <button
          (click)="download($event, 'deb', 'linux_x64')"
          class="btn btn-xs btn-block btn-solid secondary"
        >
          .deb
        </button>
        <button
          (click)="download($event, 'rpm', 'linux_x64')"
          class="btn btn-xs btn-block btn-solid secondary"
        >
          .rpm
        </button>
        <button
          (click)="download($event, 'AppImage', 'linux_x64')"
          class="btn btn-xs btn-block btn-solid secondary"
        >
          .AppImage
        </button>
      </div>
    </div>
    <div class="operating-system">
      <ion-icon color="apple" name="logo-apple"></ion-icon>

      <span class="platform-title">MacOS</span>
      <div class="actions">
        <button
          (click)="download($event, 'dmg', 'darwin_x64')"
          class="btn btn-xs btn-block btn-solid secondary"
        >
          .dmg
        </button>
        <button
          (click)="download($event, 'zip', 'darwin_x64')"
          class="btn btn-xs btn-block btn-solid secondary"
        >
          .zip
        </button>
      </div>
    </div>
  </div>
</div>
