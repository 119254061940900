<div id="notfound">
  <div class="notfound">
    <ion-icon class="notfound-404" color="warning" name="bug-outline"></ion-icon>
    <h1>404</h1>
    <h2>Oops! Page Not Be Found</h2>
    <p>
      Sorry but the page you are looking for does not exist, have been removed, name changed or is
      temporarily unavailable.
    </p>
    <a routerLink="/">Back to homepage</a>
  </div>
</div>
