import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { MediaModule } from 'src/app/shared/directives/media/media.module';
import { TooltipModule } from 'src/app/shared/directives/tooltip/tooltip.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { IonicSelectableModule } from '../selectable/ionic-selectable.module';
import { FormBuilderSegmentsComponent } from './/form-builder-segments/form-builder-segments.component';
import { FormBuilderElementsComponent } from './form-builder-elements/form-builder-elements.component';
import { TagComponentComponent } from './form-builder-elements/tag-component/tag-component.component';
import { FormBuilderWizardComponent } from './form-builder-wizard/form-builder-wizard.component';
import { FormBuilder } from './form-builder.component';
import { InfiniteDataGridModule } from '../infinite-data-grid/infinite-data-grid.module';
import { UINavigationModule } from '../navigation/ui-navigation.module';
import { UISectionModule } from '../section/ui-section.module';

@NgModule({
  imports: [
    IonicModule,
    PipesModule,
    FormsModule,
    UINavigationModule,
    UISectionModule,
    IonicSelectableModule,
    InfiniteDataGridModule,
    TooltipModule,
    ReactiveFormsModule,
    MediaModule,
    CommonModule,
    TranslocoModule,
  ],
  declarations: [
    FormBuilderElementsComponent,
    FormBuilderWizardComponent,
    FormBuilderSegmentsComponent,
    FormBuilder,
    TagComponentComponent,
  ],
  exports: [FormBuilderWizardComponent, FormBuilderSegmentsComponent, FormBuilder, FormsModule],
})
export class FormBuilderModule {}
