import { isPlatformBrowser } from '@angular/common';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { timeout } from 'rxjs';
import { TokenService } from './token.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  isBrowser = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  /**
   * Intercept a http request to handle the rest token
   * @todo Problem after timeout of a token (site is reloading 3 times)
   * @param request
   * @param next
   * @returns
   */
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let headers: {
      [name: string]: string | string[];
    } = {
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Accept: 'application/json',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
      Pragma: 'no-cache',
      'X-Frame-Time': Math.floor(Date.now() / 1000).toString(),
    };

    /* if (this.authService.userStateData && this.authService.userStateData.language) {
        headers['Accept-Language'] =
          this.authService.userStateData.language + ';q=0.9,en-US,en;q=0.8';
      } else {
        headers['Accept-Language'] = this.langService.currentLangSlug + ';q=0.9,en-US,en;q=0.8';
      }
      */
    // headers['Accept-Language'] = this.langService.currentLangSlug + ';q=0.9,en-US,en;q=0.8';

    if (!this.isBrowser) {
      const timeoutValue = request.headers.get('timeout') || 3000;
      const timeoutValueNumeric = Number(timeoutValue);

      return next.handle(request.clone({ setHeaders: headers })).pipe(timeout(timeoutValueNumeric));
    } else {
      return next.handle(request.clone({ setHeaders: headers }));
    }
  }
}
