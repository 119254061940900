import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WaitingService {
  urls: string[] = [];
  private isLoadingStates = new BehaviorSubject<number>(0);
  isLoading = this.isLoadingStates.asObservable();

  constructor() {}

  add(url: string) {
    this.urls.push(url);
    if (this.isLoadingStates.getValue() != this.urls.length) {
      this.isLoadingStates.next(this.urls.length);
    }
  }

  remove(url: string) {
    const index = this.urls.indexOf(url);
    if (index > -1) {
      this.urls.splice(index, 1);
    }

    if (this.isLoadingStates.getValue() != this.urls.length) {
      this.isLoadingStates.next(this.urls.length);
    }
  }
}
