import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import {
  NoopValueAccessorDirective,
  UICoreInputProvider,
  UICoreInputComponent,
  injectNgControl,
} from '../ui-input-root.component';
import { Subscription } from 'rxjs';
export const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

@Component({
  selector: 'ui-number-input',
  standalone: true,
  templateUrl: 'ui-number-input.component.html',
  styleUrls: ['ui-number-input.component.scss'],
  hostDirectives: [NoopValueAccessorDirective],
  providers: [UICoreInputProvider(UINumberInputComponent)],

  imports: [ReactiveFormsModule, CommonModule, IonicModule, FormsModule],
})
export class UINumberInputComponent extends UICoreInputComponent implements OnInit, OnDestroy {
  @Input() name: string | undefined = undefined;

  @Input() placeholder: string | undefined = undefined;
  @Input() autocomplete: boolean = false;

  @Input() max: number | null = null;
  @Input() step: number = 1;
  @Input() min: number | null = null;
  @Input() maxlength: number = 100;
  @Input() minlength: number | null = null;
  @Input() leadingZeros: number | null = null;

  private _value: string | undefined = undefined;
  rootId: string | undefined = undefined;
  ngControl = injectNgControl();

  isDirty = false;

  private inputSub: Subscription | undefined = undefined;

  constructor() {
    super();
  }
  private _disabled: boolean = false;

  @Input() get disabled(): boolean {
    if (this._disabled) {
      return true;
    }

    if (this.ngControl) {
      return this.ngControl.isDisabled;
    }

    return false;
  }

  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }
  ngOnInit(): void {
    if (this.ngControl?.control) {
      this._value = this.ngControl.control.value;
      this.inputSub = this.ngControl.valueChanges?.subscribe(_ => {
        this._value = _;
      });
    }
  }

  @Input() get value(): string | undefined {
    return this._value;
  }

  set value(value: string | undefined) {
    this._value = value;
    this.ngControl?.control.setValue(this.value);
  }

  ngOnDestroy(): void {
    this.inputSub?.unsubscribe();
  }
  prepareNumber(): number {
    let input = this._value;
    if (input == null || input == undefined) {
      return 0;
    }
    input = input.toString().replace(/^0+/, '');
    let number: number = parseInt(input);

    return number;
  }

  validateNumber(number: number) {
    if (this.max && number > this.max) {
      number = this.max;
    }

    if (this.min && number < this.min) {
      number = this.min;
    }
    return number;
  }
  formatNumber(number: number): string {
    if (this.leadingZeros) {
      return zeroPad(number, 4).toString();
    }
    return number.toString();
  }
  increase(event: any) {
    event.stopPropagation();
    event.preventDefault();

    let input = this.prepareNumber();
    input -= this.step;
    input = this.validateNumber(input);

    this.value = this.formatNumber(input);
  }

  decrease(event: any) {
    event.stopPropagation();
    event.preventDefault();

    let input = this.prepareNumber();
    input += this.step;
    input = this.validateNumber(input);

    this.value = this.formatNumber(input);
  }
}
