<div
  class="setting-tab"
  *ngFor="let tab of (options.tabs | keyvalue : unsorted) || []"
  [style.display]="tab.value.visible === false ? 'none' : 'grid'"
>
  <ui-section-header
    [title]="tab.value.title ? tab.value.title : undefined"
    [desc]="tab.value.description ? tab.value.description : undefined"
  ></ui-section-header>

  <div class="form-side">
    <app-form-builder-elements
      *ngIf="hasForm(tab.key)"
      [formGroup]="getForm(tab.key)"
      [errors]="errors"
      [tab]="tab.value"
    >
    </app-form-builder-elements>
  </div>
</div>
