import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IonInput, IonItem } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { FriendService } from 'src/app/b2c/friends/services/friends.service';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { ModalComponent } from 'src/app/shared/framework/modal-component';
import { RestService } from 'src/app/shared/services/rest/rest.service';
import { ToastService } from 'src/app/shared/services/toast.service';

export interface FriendsInviteCallback {
  (friends: { [id: string]: string }): Promise<boolean>;
}

@Component({
  selector: 'app-invite-modal',
  templateUrl: './invite-modal.component.html',
  styleUrls: ['./invite-modal.component.scss'],
})
export class InviteModalComponent extends ModalComponent implements OnInit, OnDestroy {
  @Input() multipleSelect: boolean = false;

  @Input() inviteFriends: boolean = false;
  @Input() inviteByUserIds: boolean = false; //invite by tagline
  @Input() inviteByLink: boolean = false;
  @Input() tableType: string = '';
  @Input() tableId: string = '';
  @Input() title: string = '';
  @Input() submitTitle: string | null = null;
  @Input() searchTerm: string = '';
  @Input() restUri: string = '';
  activeTab = '';
  @Input() disableUserIds: number[] = [];
  public selectedFriends: { [id: string]: string } = {};
  public get selectedFriendsAmout() {
    return Object.keys(this.selectedFriends).length;
  }

  inviteByUsernameForm = new UntypedFormGroup({
    name: new UntypedFormControl(),
    uid: new UntypedFormControl(),
  });

  generateInviteLinkForm = new UntypedFormGroup({
    lifetime: new UntypedFormControl('week'),
  });

  friendsAmount = 0;
  authState: AuthStateService;
  friendService: FriendService;

  isOnSubmit = false;

  inviteLinks: Array<any> = [];
  inviteLinksTimeRemainingInterval: NodeJS.Timer | null = null;

  constructor(
    injector: Injector,
    private restService: RestService,
    public toastService: ToastService
  ) {
    super(injector);
    this.authState = injector.get(AuthStateService);
    this.friendService = injector.get(FriendService);
  }

  ngOnInit(): void {
    this.selectedFriends = {};

    if (this.inviteByLink) {
      this.restService.get('invites/links/' + this.tableType + '/' + this.tableId).subscribe(
        result => {
          this.inviteLinks = result.data;
        },
        error => {
          this.toastService.createErrorToast(error.message);
        }
      );

      // Provide realtime feedback about remaining time until the invite link expires
      this.inviteLinksTimeRemainingInterval = setInterval(
        this.updateInviteLinksTimeRemaining,
        1000,
        this
      );
    }

    if (this.inviteByUserIds) {
      this.activeTab = 'username';
    } else if (this.inviteFriends) {
      this.activeTab = 'friends';
    } else if (this.inviteByLink) {
      this.activeTab = 'link';
    }
  }

  ngOnDestroy() {
    if (this.inviteLinksTimeRemainingInterval) {
      clearInterval(this.inviteLinksTimeRemainingInterval);
    }
  }

  updateInviteLinksTimeRemaining(modal: InviteModalComponent) {
    for (let i = 0; i < modal.inviteLinks.length; i++) {
      modal.inviteLinks[i].seconds_remaining -= 1;
      modal.inviteLinks[i].days_remaining = Math.floor(
        modal.inviteLinks[i].seconds_remaining / 86400
      );
      modal.inviteLinks[i].hours_remaining = Math.floor(
        (modal.inviteLinks[i].seconds_remaining % 86400) / 3600
      );
    }
  }

  get friends() {
    return this.friendService.allFriends.pipe(
      map(items => {
        var result = items
          .filter(
            item =>
              item.userData != null && item.userData.name.toLowerCase().includes(this.searchTerm)
          )
          .map(df => df.userData);
        this.friendsAmount = result.size;
        return result;
      })
    );
  }

  search(event: any) {
    this.searchTerm = event;
  }

  onFriendSelectionChange(friendId: string, friendName: string, isChecked: boolean) {
    if (isChecked && !(friendId in this.selectedFriends)) {
      this.selectedFriends[friendId] = friendName;
    } else if (!isChecked && friendId in this.selectedFriends) {
      delete this.selectedFriends[friendId];
    }
  }

  public onInviteFriends = false;

  public useRest = true;
  submitInviteFriends() {
    if (this.onInviteFriends) {
      return;
    }
    this.onInviteFriends = true;
    if (!this.useRest) {
      this.modalController.dismiss({
        friends: this.selectedFriends,
      });
      return;
    }
    this.doRestPOSTCall('invites/friends/' + this.tableType + '/' + this.tableId, {
      friends: Object.keys(this.selectedFriends),
    })
      .then(result => {
        this.onInviteFriends = false;
        this.modalController.dismiss({
          friends: this.selectedFriends,
        });
      })
      .catch(error => {
        this.onInviteFriends = false;
      });
  }

  private async doRestPOSTCall(url: string, data: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.restService.post(url, data).subscribe(
        result => {
          this.toastService.createSuccessToast(result.message);
          resolve(result);
        },
        error => {
          this.toastService.createErrorToast(error.message);
          resolve(false);
        }
      );
    });
  }

  doInviteByUsername(modal: InviteModalComponent) {
    this.inviteByUsernameForm.disable();

    this.doRestPOSTCall('friends/inviteTagline', modal.inviteByUsernameForm.value).then(result => {
      this.inviteByUsernameForm.enable();

      this.dismiss();
    });
  }

  generateInviteLink(modal: InviteModalComponent) {
    this.generateInviteLinkForm.disable();

    this.doRestPOSTCall(
      'invites/generate/' + this.tableType + '/' + this.tableId,
      modal.generateInviteLinkForm.value
    ).then(result => {
      this.generateInviteLinkForm.enable();

      if (result) {
        this.inviteLinks.push(Object(result).data);
      }
    });
  }

  removeInviteLink(hash: string, item: IonItem) {
    this.restService.delete('invites/unlink/' + hash).subscribe(
      result => {
        this.inviteLinks.splice(this.inviteLinks.indexOf(item), 1);
        this.toastService.createSuccessToast(result.message);
      },
      error => {
        this.toastService.createErrorToast(error.message);
      }
    );
  }

  selectText(input: HTMLInputElement) {
    input.select();
  }

  copyInviteLink(url: string) {
    navigator.clipboard.writeText(url);
  }
}
