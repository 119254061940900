<input
  [type]="type"
  [name]="name || ''"
  [placeholder]="placeholder"
  autocapitalize="off"
  spellcheck="false"
  [(ngModel)]="value"
  autocorrect="off"
  [disabled]="disabled"
  [id]="rootId"
  [autocomplete]="autocomplete ? name : 'off'"
/>

<button class="btn btn-xs btn-solid secondary btn-full" (click)="setInputType($event)">
  <ion-icon [name]="type == 'text' ? 'eye-off' : 'eye'"></ion-icon>
</button>
