import { KeyValue } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { RestService } from 'src/app/shared/services/rest/rest.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { MediaImageCropperComponent } from 'src/app/ui/media-cropper/image-cropper/image-cropper.component';
import {
  FormBuilderInput,
  FormBuilderOption,
  FormBuilderTab,
  FormBuilderTagResult,
} from '../form-builder-options';

@Component({
  selector: 'app-form-builder-elements',
  templateUrl: './form-builder-elements.component.html',
  styleUrls: ['./form-builder-elements.component.scss'],
})
export class FormBuilderElementsComponent {
  @Input() formGroup!: UntypedFormGroup | null;
  @Input() tab: FormBuilderTab = {
    sections: {},
  };
  @Input() public errors: any = null;

  constructor(
    private restService: RestService,
    private modalController: ModalController,
    private translate: TranslocoService,
    private toastService: ToastService
  ) {}
  copy(key: string) {
    if (this.formGroup != null) {
      const value = this.formGroup.controls[key].value;

      navigator.clipboard.writeText(value).then(
        () => {
          this.toastService.createSuccessToast(this.translate.translate('clipboard.sucess'));
        },
        () => {
          this.toastService.createErrorToast(this.translate.translate('clipboard.error'));
        }
      );
    }
  }
  @ViewChild('dataScroller') dataScrollerRef!: ElementRef<any>;

  charsLeft(inputKey: string): number {
    var maxLength = 0;
    for (let sectionId in this.tab.sections) {
      let section = this.tab.sections[sectionId];
      for (let inputId in section.inputs) {
        if (inputId != inputKey) {
          continue;
        }
        let input = section.inputs[inputId];
        maxLength = input.maxlength || 0;
      }
    }

    var input = this.formGroup?.get(inputKey);
    if (
      input != null &&
      input.value != null &&
      (typeof input.value === 'string' || input.value instanceof String)
    ) {
      maxLength = maxLength - input.value.length;
    }
    return maxLength;
  }

  isTagActivated(inputKey: string, option: any, mutliSelect: boolean): boolean {
    if (option == null || option == undefined) {
      return false;
    }

    if (mutliSelect) {
      const values = (this.formGroup?.get(inputKey)?.value as any[]) || [];
      return values.map(df => df.key).includes(option);
    } else {
      let findOption = this.formGroup?.get(inputKey)?.value;
      if (findOption != null && findOption != undefined) {
        return option == findOption;
      } else {
        return false;
      }
    }
  }

  isFormSelectBoxActive(option: FormBuilderOption, input: KeyValue<string, FormBuilderInput>) {
    const currentField = this.formGroup?.get(input.key);
    const value = currentField?.value || [];
    if (input.value.multipleSelection) {
      if (value && value.length > 0) {
        return value.includes(option.key);
      } else {
        return false;
      }
    } else {
      return currentField?.value == option.key;
    }
  }

  isActiveDataView(id: any, input: KeyValue<string, FormBuilderInput>) {
    const currentField = this.formGroup?.get(input.key);
    const value = currentField?.value || [];
    if (input.value.multipleSelection) {
      if (value && value.length > 0) {
        return value.includes(id);
      } else {
        return false;
      }
    } else {
      return currentField?.value == id;
    }
  }

  setValueForDataView(id: any, input: KeyValue<string, FormBuilderInput>) {
    const currentField = this.formGroup?.get(input.key);
    if (input.value.multipleSelection) {
      let currentData = (currentField?.value as any[]) || [];
      if (currentData && currentData.length > 0) {
        if (currentData.includes(id)) {
          currentData = currentData.filter(df => df != id);
        } else {
          currentData.push(id);
        }
      } else {
        currentData = [id];
      }

      currentField?.setValue(currentData);
    } else {
      let currentData = currentField?.value;
      if (input.value.required) {
        currentField?.setValue(id);
      } else {
        if (currentData && currentData != null) {
          currentField?.setValue(null);
        } else {
          currentField?.setValue(id);
        }
      }
    }
  }

  async setValueForSelectBox(option: FormBuilderOption, input: KeyValue<string, FormBuilderInput>) {
    const currentField = this.formGroup?.get(input.key);
    if (input.value.multipleSelection) {
      let currentData = (currentField?.value as any[]) || [];
      if (input.value.canActivate) {
        const canSetValue = await input.value.canActivate(option.key);
        if (!canSetValue) {
          return;
        }
      }
      if (currentData && currentData.length > 0) {
        if (currentData.includes(option.key)) {
          currentData = currentData.filter(df => df != option.key);
        } else {
          currentData.push(option.key);
        }
      } else {
        currentData = [option.key];
      }

      currentField?.setValue(currentData);
    } else {
      let currentData = currentField?.value;

      if (input.value.canActivate) {
        const canSetValue = await input.value.canActivate(option.key);
        if (!canSetValue) {
          return;
        }
      }

      if (input.value.required) {
        currentField?.setValue(option.key);
      } else {
        if (currentData && currentData != null) {
          currentField?.setValue(null);
        } else {
          currentField?.setValue(option.key);
        }
      }
    }
  }

  addOrDeleteTagSingle(inputValue: any, inputKey: string, option: any, multiSelect: boolean) {
    if (option == null || option == undefined) {
      return;
    }

    let value = this.formGroup?.get(inputKey)?.value;

    if (value && value.key && JSON.stringify(value.key) === JSON.stringify(option.key)) {
      this.formGroup?.controls[inputKey].setValue(null);
    } else {
      this.formGroup?.controls[inputKey].setValue(option);
    }
  }

  addOrDeleteTag(inputValue: any, inputKey: string, option: any, multiSelect: boolean) {
    if (option == null || option == undefined) {
      return;
    }
    if (multiSelect) {
      let values = (this.formGroup?.get(inputKey)?.value as any[]) || [];
      let isDisabled = inputValue.max && inputValue.max > 0 && values.length >= inputValue.max;
      const findIndex = values.findIndex(df => df.key == option.key);

      if (findIndex > -1) {
        this.formGroup?.controls[inputKey].setValue(values.filter(df => df.key != option.key));
      } else if (!isDisabled) {
        values.push(option);
        this.formGroup?.controls[inputKey].setValue(values);
      }
    } else {
      this.formGroup?.controls[inputKey].setValue(option.key);
    }
  }

  getValue(inputKey: string): any {
    return this.formGroup?.get(inputKey)?.value;
  }

  isValid(inputKey: string): boolean {
    return this.formGroup?.get(inputKey)?.valid || false;
  }

  hasValidator(inputKey: string): boolean {
    return this.formGroup?.get(inputKey)?.hasValidator != null || false;
  }

  getValueAny(inputKey: string): any[] {
    return this.formGroup?.get(inputKey)?.value || [];
  }

  getValueSingle(inputKey: string): any[] {
    const value = this.formGroup?.get(inputKey)?.value;
    const newValue: FormBuilderOption[] = value ? [value] : [];

    return newValue;
  }

  async uploadImage(key: string, input: FormBuilderInput) {
    if (input.dataUri == null || input.dataUri == undefined) {
      const modal = await this.modalController.create({
        component: MediaImageCropperComponent,
        componentProps: {
          maxResizeInPx: 1024,
          roundCropper: false,
          noUpload: true,
          enableAspectRatio: input.ratio ? true : false,
          aspectRatio: input.ratio || 4 / 4,
        },
      });

      await modal.present();
      const { data } = await modal.onWillDismiss();
      if (data != undefined && data != null && data.data != undefined && data.data != null) {
        this.formGroup?.controls[key].setValue(data.data);
      }
      return;
    } else {
      const modal = await this.modalController.create({
        component: MediaImageCropperComponent,
        componentProps: {
          uri: input.dataUri,
          maxResizeInPx: 1024,
          roundCropper: false,
          enableAspectRatio: input.ratio ? true : false,
          aspectRatio: input.ratio || 4 / 4,
        },
      });

      await modal.present();
      const { data } = await modal.onWillDismiss();
      if (data.path != undefined && data.path != null) {
        this.formGroup?.controls[key].setValue(data.path);
      }
    }
  }
  async uploadAvatar(key: string, uri: string | null | undefined) {
    if (uri == null || uri == undefined) {
      const modal = await this.modalController.create({
        component: MediaImageCropperComponent,
        componentProps: {
          maxResizeInPx: 1024,
          roundCropper: true,
          noUpload: true,
          aspectRatio: 4 / 4,
        },
      });

      await modal.present();
      const { data } = await modal.onWillDismiss();
      if (data != undefined && data != null && data.data != undefined && data.data != null) {
        this.formGroup?.controls[key].setValue(data.data);
      }
    } else {
      const modal = await this.modalController.create({
        component: MediaImageCropperComponent,
        componentProps: {
          uri: uri,
          maxResizeInPx: 1024,
          roundCropper: true,
          aspectRatio: 4 / 4,
        },
      });

      await modal.present();
      const { data } = await modal.onWillDismiss();
      if (data != undefined && data != null && data.path != undefined && data.path != null) {
        this.formGroup?.controls[key].setValue(data.path);
      }
    }
  }

  resetImage(key: string, input: FormBuilderInput) {
    if (input.dataUri == null || input.dataUri == undefined) {
      this.formGroup?.controls[key].setValue(null);
    } else {
      this.restService.post(input.dataUri, { image: null }).subscribe(
        result => {
          this.formGroup?.controls[key].setValue(null);
        },
        error => {}
      );
    }
  }

  unsorted(a: any, b: any) {
    return a;
  }
}
