import { Component, ElementRef, Injector, Input } from '@angular/core';
import { RestService } from 'src/app/shared/services/rest/rest.service';

import { PopoverController } from '@ionic/angular';
import domtoimage from 'html2canvas';
import { ToastService } from 'src/app/shared/services/toast.service';
import { LangService } from 'src/app/shared/services/lang/lang.service';

@Component({
  selector: 'app-issue-tracker',
  templateUrl: './issue-tracker.component.html',
  styleUrls: ['./issue-tracker.component.scss'],
})
export class IssueTrackerComponent {
  isValid: boolean = true;
  onSubmit: boolean = false;
  desc: string = '';
  type: string = 'issue';
  public process = 0.0;
  @Input() screen: HTMLElement | undefined = undefined;
  isPrivacyChecked: boolean = false;

  constructor(
    injector: Injector,
    private popover: PopoverController,
    private elRef: ElementRef,
    private restService: RestService,
    private toastService: ToastService,
    private langService: LangService
  ) {}

  dismiss(event: any) {
    this.popover.dismiss();
  }

  togglePrivacyCheckbox(event: any) {
    this.isPrivacyChecked = event.detail.checked;
  }

  submit(event: any) {
    if (this.screen == undefined) {
      return;
    }

    if (this.onSubmit) {
      return;
    }

    this.process = 0;
    this.onSubmit = true;

    this.elRef.nativeElement.style.display = 'none';

    domtoimage(this.screen, {
      useCORS: true,
      imageTimeout: 1000,
      logging: false,
    })
      .then((canvas: any) => {
        const img = canvas.toDataURL('image/png', 0.6);
        if (img == null || img.length <= 0) {
          this.elRef.nativeElement.style.display = 'block';
          this.onSubmit = false;
          return;
        }
        this.restService
          .uploadBase64('track/issue', {
            image: img,
            desc: this.desc,
            type: this.type,
            path: window.location.pathname,
            url: window.location.href,
            lang: this.langService.currentLangSlug,
          })
          .subscribe(
            (res: any) => {
              this.elRef.nativeElement.style.display = 'block';
              if (res.status == 'response') {
                this.process = 100;
                this.popover.dismiss();
                this.onSubmit = false;
                this.toastService.createSuccessToast(res.message);
              }
              if (res.status == 'progress') {
                this.process = parseFloat(res.message) / 100;
              }
            },
            (error: any) => {
              this.elRef.nativeElement.style.display = 'block';
              this.toastService.createErrorToast(error.message);
              this.process = 0;
              this.onSubmit = false;
            }
          );
      })
      .catch((error: any) => {
        this.elRef.nativeElement.style.display = 'block';
        this.toastService.createErrorToast('Something went wrong: ' + error);
        this.popover.dismiss();
      });
  }
}
