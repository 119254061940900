<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'image.upload_title' | transloco }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-button color="danger" fill="clear" class="close-button" (click)="dismiss()">
  <ion-icon name="close-outline" slot="icon-only"> </ion-icon>
</ion-button>
<ion-content scrollbar class="ion-padding ion-text-center">
  <file-upload [control]="control">
    <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
      <div *ngIf="control.size === 0">
        <div class="icon">
          <ion-icon name="cloud-upload-outline"></ion-icon>
        </div>

        <div class="upload-text">
          <ng-container *ngIf="isFileDragDropAvailable; else isNotDragDropAvailable">
            <b>Drag and drop</b> files<br />
            or click here

            <p class="notice">Supported files:</p>
            <div class="file-list">
              <ion-badge>jpg</ion-badge>
              <ion-badge>png</ion-badge>
            </div>
          </ng-container>
          <ng-template #isNotDragDropAvailable>
            <b>Click here</b> to<br />
            choose a files
          </ng-template>
        </div>
      </div>
    </ng-template>

    <ng-template let-i="index" let-file="file" let-control="control" #item>
      <div class="overlay">
        <ion-button
          [disabled]="onSubmit"
          fill="clear"
          color="danger"
          class="remove-image"
          (click)="control.removeFile(file)"
        >
          <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
        </ion-button>

        <image-cropper
          [disabled]="onSubmit"
          *ngIf="control.valid && uploadedFile"
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="enableAspectRatio"
          [aspectRatio]="aspectRatio"
          format="png"
          [imageFile]="uploadedFile"
          [roundCropper]="roundCropper"
          (imageCropped)="imageCropped($event)"
          (cropperReady)="cropperReady()"
          [resizeToWidth]="maxResizeInPx"
          [resizeToHeight]="maxResizeInPx"
          [onlyScaleDown]="true"
          (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
      </div>
    </ng-template>
  </file-upload>
</ion-content>
<ion-footer class="modal-footer">
  <ion-grid>
    <ion-row>
      <ion-col style="align-self: center" size="6">
        <ion-progress-bar [value]="process" color="tertiary" *ngIf="onSubmit"></ion-progress-bar>
      </ion-col>
      <ion-col size="6" class="flex-right">
        <ion-button
          transloco="image.upload_submit"
          (click)="submit()"
          [disabled]="!control.valid || !uploadedFile || onSubmit"
        >
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
