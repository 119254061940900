import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';

@Injectable()
export class AuthenticationGuard  {
  private previousState = false;
  isBrowser = false;

  private previousUrl: string | null = null;
  constructor(
    private router: Router,
    private authState: AuthStateService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.authState.userAuthed.subscribe(df => {
        if (
          this.previousUrl &&
          this.previousState == true &&
          df == false &&
          this.router.url.startsWith(this.previousUrl)
        ) {
          this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.previousUrl } });
        }
      });
    }
  }

  /**
   * Check if the user is logged in before calling http
   *
   * @param route
   * @param state
   * @returns {boolean}
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (this.isBrowser) {
      return this.authState.userAuthed.pipe(
        map(isAuthed => {
          if (isAuthed) {
            this.previousState = true;
            this.previousUrl = state.url;
            return true;
          } else {
            this.previousState = false;
            this.previousUrl = state.url;
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
            return false;
          }
        })
      );
    } else {
      return this.authState.userAuthed.pipe(
        map(isAuthed => {
          if (isAuthed) {
            this.previousState = true;
            this.previousUrl = state.url;
            return true;
          } else {
            this.previousState = false;
            this.previousUrl = state.url;
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
            return false;
          }
        })
      );
    }
  }
}
