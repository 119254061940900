<div
  class="main-frame"
  [ngClass]="{
    'closed-side': (appService.mainMobileMenuSub | async) === false
  }"
>
  <div
    class="app-left-container dark"
    [style.left.px]="sideLeftWidth"
    [ngClass]="{
      abs: (appService.mainMobileMenuSub | async) === true
    }"
  >
    <div class="app-left-shortcuts favorites">
      <div class="app-left-shortcuts-inner">
        <div class="app-left-shortcuts-outer">
          <div class="outer-content scrollable">
            <app-home-navigation-icons></app-home-navigation-icons>
            <app-shortcuts *ngIf="(authState.userAuthed | async) === true"></app-shortcuts>
          </div>
        </div>
      </div>
      <app-user-navigation-icons></app-user-navigation-icons>
    </div>
    <div class="app-left-shortcuts">
      <div class="app-left-shortcuts-inner">
        <div class="app-left-shortcuts-outer">
          <div class="outer-content scrollable">
            <app-chat-navigation-icons></app-chat-navigation-icons>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="menu-menu-backdrop"
    (click)="closeSideMenus($event)"
    [ngClass]="searchService.showMenu ? 'show' : ''"
  ></div>
  <div class="app-content-container undraggable">
    <div class="outer-content scrollable" #pageContent [windowResizeTrigger]="'body'">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
