<div
  class="widget-list-item widget-list-item-product"
  #anchor
  [ngClass]="{ 'widget-spacer': !isLast }"
>
  <div class="widget-list-product-image">
    <a
      class="widget-img-overlay"
      routerDirection="forward"
      [routerLink]="url"
      (click)="onClick.next($event)"
    >
      <ui-lazy-img
        *ngIf="thumbnail"
        [src]="thumbnail"
        [width]="thumbnailWidth"
        [height]="thumbnailHeight"
        [alt]="title"
        [radius]="thumbnailBorderRadius"
      ></ui-lazy-img
    ></a>
  </div>
  <div class="widget-list-product">
    <div class="widget-list-item-outer-cont">
      <div class="widget-list-item-left">
        <div class="widget-list-item-header">
          <a
            routerDirection="forward"
            [routerLink]="url"
            *ngIf="title"
            (click)="onClick.next($event)"
            >{{ title }}</a
          >
          <ion-skeleton-text *ngIf="!title"></ion-skeleton-text>

          <div class="widget-list-item-inner">
            <ng-content></ng-content>
          </div>
        </div>
      </div>

      <div class="actions" #actionChilds *ngIf="hasActions"></div>

      <button
        *ngIf="hasContextMenu && hasContextItems"
        (click)="openContextMenu($event)"
        class="btn btn-xxs btn-chat btn-solid btn-only-icon secondary uppercase"
      >
        <ion-icon name="ellipsis-vertical"></ion-icon>
      </button>
    </div>
  </div>
</div>

<ui-popover
  #popover
  hasBackdrop
  (backdropClicked)="popover.close()"
  verticalAlign="start"
  openAnimationStartAtScale="0"
  closeAnimationEndAtScale="0"
  [anchor]="anchor"
  *ngIf="hasContextMenu"
  horizontalAlign="end"
>
  <div class="side-menu side-menu-dropdown" (click)="popover.close()">
    <ui-navigation-root><div #contextChilds></div></ui-navigation-root>
  </div>
</ui-popover>
