import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilderBaseComponent } from './form-builder.base.component';

/**
 * @description Form Builder Component
 * @author Stefan Boronczyk <stefan@strikd.com>
 */
@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],
})
export class FormBuilder extends FormBuilderBaseComponent {
  protected onLoad(): void {}

  protected afterLoad(): void {}
}
