import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { RestService } from 'src/app/shared/services/rest/rest.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { FormBuilderOption, FormBuilderOptions } from './form-builder-options';

export type PrePareDataFunc = (data: FormData) => FormData;

export interface FormResultCallback {
  key: string;
  result: any;
}

export interface FormData {
  [key: string]: any;
}
export interface FormGroupModels {
  [key: string]: UntypedFormGroup;
}

@Component({
  template: '',
})
export abstract class FormBuilderBaseComponent implements /* OnChanges, */ OnDestroy, OnInit {
  @Input() uri: string | null = null;
  @Input()
  options: FormBuilderOptions = {
    tabs: {},
  };

  @Output() onChange = new EventEmitter<FormResultCallback>();
  @Output() beforeSubmit = new EventEmitter<void>();
  @Output() afterSubmit = new EventEmitter<any>();
  @Output() success = new EventEmitter<any>();
  @Output() isReady = new EventEmitter<boolean>();
  private subs: Subscription[] = [];

  public formGroups: FormGroupModels = {};
  public isInit: boolean = false;
  public errors: any = null;
  public onSubmit = false;
  public process = 0;

  protected maxLengths: Map<string, number> = new Map<string, number>();
  protected minLengths: Map<string, number> = new Map<string, number>();
  protected formOptionsResolves: Map<string, FormBuilderOption[]> = new Map<
    string,
    FormBuilderOption[]
  >();
  protected restService: RestService;
  protected toastService: ToastService;
  protected modalController: ModalController;
  constructor(injector: Injector, protected ref: ChangeDetectorRef) {
    this.restService = injector.get(RestService);
    this.modalController = injector.get(ModalController);
    this.toastService = injector.get(ToastService);
  }

  ngOnInit(): void {
    if (!this.isInit) {
      this.initalize();
    }
  }

  /**
   * @description Determines if form for given key exist
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param key
   * @returns true if form
   */
  hasForm(key: string): boolean {
    if (this.isInit == false) return false;

    return key in this.formGroups;
  }

  /**
   * @description Get an form by given key
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param key
   * @returns form
   */
  getForm(key: string): UntypedFormGroup {
    return this.formGroups[key];
  }

  /**
   * @description Get all given form data
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @returns form data
   */
  public getFormData(): FormData {
    var values: FormData = {};
    for (let formId in this.formGroups) {
      var form = this.formGroups[formId];

      for (const controlName in form.controls) {
        const control = form.controls[controlName];

        //handle key values
        let value = control.value;
        if (value instanceof Object && 'key' in value) {
          value = value.key;
        }

        //handle key values arrays
        var arrayValues: any[] = [];
        if (value instanceof Array) {
          for (let arrayValue of value) {
            if (arrayValue instanceof Object && 'key' in arrayValue) {
              arrayValues.push(arrayValue.key);
            } else {
              arrayValues.push(arrayValue);
            }
          }
          value = arrayValues;
        }

        values[controlName] = value;
      }
    }

    return values;
  }

  /**
   * Submits form builder base component
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param prePareData Submit to rest api
   */
  public submit(prePareData: PrePareDataFunc | undefined = undefined) {
    if (this.canSubmit() && this.uri && !this.onSubmit) {
      //disable all previous errors
      this.errors = [];
      this.onSubmit = true;
      this.activateElements(false);
      this.onLoad();
      var data = this.getFormData();

      if (prePareData != undefined) {
        data = prePareData(data);
      }

      this.beforeSubmit.next();
      this.process = 0;

      this.restService.uploadBase64(this.uri, data).subscribe(
        (res: any) => {
          if (res.status == 'response') {
            this.onSubmit = false;
            this.activateElements(true);
            this.afterLoad();
            this.afterSubmit.next(data);
            this.success.next(res.message);

            if (res.message.message != null) {
              this.toastService.createSuccessToast(res.message.message);
            }
          }

          if (res.status == 'progress') {
            this.process = parseFloat(res.message) / 100;
          }
        },
        (error: any) => {
          console.log(error);
          this.errors = error.errors;
          this.onSubmit = false;
          if (error.message != null) {
            this.toastService.createErrorToast(error.message);
          }
          this.activateElements(true);
          this.afterLoad();
          this.afterSubmit.next(null);
        }
      );
    }
  }

  public activateElements(activate: boolean = true) {
    for (let formId in this.formGroups) {
      let form = this.formGroups[formId];
      if (activate) {
        form.enable();
      } else {
        form.disable();
      }

      for (let controlId in form.controls) {
        let control = form.controls[controlId];

        if (activate && !this.isDisabled(controlId)) {
          control.enable();
        } else {
          control.disable();
        }
      }
    }

    this.ref.detectChanges();
  }

  /**
   * @description Reset the form builder
   * @author Stefan Boronczyk <stefan@strikd.com>
   */
  reset() {
    this.noTempInit = {};
    this.maxLengths.clear();
    this.minLengths.clear();
    this.formGroups = {};
    this.formOptionsResolves.clear();
    this.isInit = false;

    for (let sub of this.subs) {
      sub.unsubscribe();
    }
    this.subs = [];

    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.reset();
  }

  /**
   * @description Initalize the form builder
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @returns
   */
  initalize() {
    if (this.options == null) {
      return;
    }
    for (var tabKey in this.options.tabs) {
      var formGroup = new UntypedFormGroup({});
      var tab = this.options.tabs[tabKey];

      for (var sectionKey in tab.sections) {
        var section = tab.sections[sectionKey];

        for (var inputKey in section.inputs) {
          var input = section.inputs[inputKey];

          var validators = [];
          if (input.required != undefined && input.required == true) {
            validators.push(Validators.required);
          }

          if (input.maxlength != undefined && input.maxlength > 0) {
            validators.push(Validators.maxLength(input.maxlength));
            this.maxLengths.set(inputKey, input.maxlength);
          }

          if (input.minlength != undefined && input.minlength > 0) {
            validators.push(Validators.minLength(input.minlength));
            this.minLengths.set(inputKey, input.minlength);
          }

          let control = new UntypedFormControl(input.value || null, validators);
          if (input.disabled) {
            control.disable();
          }
          formGroup.addControl(inputKey, control);

          //add resolvers
          if (input.options != null && input.options.length > 0) {
            this.formOptionsResolves.set(inputKey, input.options);
          }
        }
      }

      this.formGroups[tabKey] = formGroup;

      for (let inputKey in this.formGroups[tabKey].controls) {
        const control = this.formGroups[tabKey].controls[inputKey];
        let sub = control.valueChanges.subscribe(data =>
          this.onChange.next({ key: inputKey, result: data })
        );
        this.subs.push(sub);
      }
    }

    this.isInit = true;
    this.setValues(this.noTempInit);
    this.ref.detectChanges();
    this.isReady.next(true);
  }

  /**
   * @description Check if all forms in form builder are valid
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @returns true if valid
   */
  public isValid(): boolean {
    if (!this.isInit) return false;
    let valid = true;
    for (let tabId in this.options.tabs) {
      var group = this.formGroups[tabId];
      if (!group?.valid) {
        valid = false;
      }
    }

    return valid;
  }

  protected canSubmit(): boolean {
    let valid = true;
    for (let tabId in this.options.tabs) {
      if (!(tabId in this.formGroups)) {
        valid = false;
        break;
      }

      var group = this.formGroups[tabId];
      if (!group.valid) {
        valid = false;
      }
    }

    return valid;
  }

  private noTempInit: any = {};

  /**
   * @description Set values form form builder
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param data
   */
  public setValues(data: any) {
    for (let key in data) {
      this.setValue(key, data[key]);
    }
  }

  /**
   * @description Check if in given key is read only
   * @author Stefan Boronczyk <stefan@strikd.com>
   */
  public isDisabled(key: string): boolean {
    for (var tabKey in this.options.tabs) {
      var tab = this.options.tabs[tabKey];

      for (var sectionKey in tab.sections) {
        var section = tab.sections[sectionKey];

        for (var inputKey in section.inputs) {
          if (inputKey == key) {
            var input = section.inputs[inputKey];
            return input.disabled != undefined ? input.disabled : false;
          }
        }
      }
    }

    return false;
  }

  /**
   * @description Set a given value by an given key
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param key
   * @param value
   */
  public setValue(key: string, value: any) {
    if (!this.isInit) {
      this.noTempInit[key] = value;
    } else {
      for (var formKey in this.formGroups) {
        var form = this.formGroups[formKey];

        for (var controlName in form.controls) {
          if (controlName != key) {
            continue;
          }
          form.controls[controlName].setValue(value);
        }
      }

      this.ref.detectChanges();
    }
  }

  public unsorted(a: any, b: any) {
    return a;
  }

  protected abstract onLoad(): void;
  protected abstract afterLoad(): void;
}
