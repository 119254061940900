import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { CoreSubLevelPage } from './core-sub-level-page';

/**
 * @description Second level page
 * @author Stefan Boronczyk <stefan@strikd.com>
 */
@Component({ template: '' })
export abstract class SubLevelPage
  extends CoreSubLevelPage
  implements OnInit, OnDestroy, AfterViewInit
{
  ngOnInit(): void {
    super.ngOnInit();
    if (!this.isBrowser) {
      this.onActivation();
      this.changeDetectorRef.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      this.onActivation();
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.topLevel = null;
    this.onDeactivation();
    super.ngOnDestroy();
  }

  public abstract onActivation(): void;
  public abstract onDeactivation(): void;
}
