import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { EventEmitter, Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { from, of, Subscription } from 'rxjs';
import { concatMap, delay, map } from 'rxjs/operators';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { ResourceList } from 'src/app/shared/libs/resources';
import { RestService } from 'src/app/shared/services/rest/rest.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { InviteModalComponent } from '../../friends/components/invite-modal/invite-modal.component';
import { ChatMember, ChatMessage } from '../models/chat.message';
import { ChatRoomInstance, ChatRoomType } from '../models/chat.room';

/**
 * @description Injectable chat service
 * @author Stefan Boronczyk <stefan@strikd.com>
 * @author Tristan Kreuziger <tristan@strikd.com>
 * @todo Must be documented
 */
@Injectable({
  providedIn: 'root',
})
export class ChatService implements OnDestroy {
  private isBrowser = false;
  public lastKnownChatRoom: number = -1;

  private activeRoomid = -1;
  public onNewMessageReceive: EventEmitter<ChatMessage> = new EventEmitter<ChatMessage>();

  public onMemberJoin$: EventEmitter<any> = new EventEmitter<any>();
  public onMemberLeave$: EventEmitter<any> = new EventEmitter<any>();
  public onMemberTyping$: EventEmitter<any> = new EventEmitter<any>();
  public onChatUpdate$: EventEmitter<any> = new EventEmitter<any>();
  public onUserStateChanged$: EventEmitter<any> = new EventEmitter<any>();

  public onLeaveRoom: EventEmitter<number> = new EventEmitter<number>();
  public chats: ResourceList<ChatRoomInstance> = new ResourceList<ChatRoomInstance>();

  public get unreadedChats() {
    return this.chats.values.pipe(map(items => items.filter(df => df.unreaded > 0)));
  }

  public get activeRoom(): number {
    return this.activeRoomid;
  }

  public setActiveRoom(roomId: number) {
    this.activeRoomid = roomId;
    this.lastKnownChatRoom = this.activeRoom;

    const room = this.chats.get(this.activeRoomid);
    if (room != null) {
      room.unreaded = 0;
      this.chats.updateOrInsert(room);
    }
  }

  public resetActiveRoomId() {
    this.activeRoomid = -1;
  }

  private paramSub: Subscription | null = null;
  private authSub: Subscription | null = null;
  private socketSub: Subscription | null = null;

  constructor(
    private restService: RestService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthStateService,
    private toastService: ToastService,
    private modalController: ModalController,
    private translate: TranslocoService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private nav: NavController
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.authSub = this.authService.userAuthed.subscribe(result => {
      if (result === true) {
        this.fetchChatRooms();
      } else {
        this.destroyChatRooms();
      }
    });

    if (this.isBrowser) {
      this.socketSub = this.authService.userConnected.subscribe(result => {
        if (result === true) {
          this.subscribeSocket();
        } else {
          this.unsubscribeSocket();
        }
      });
    }

    this.paramSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.parseRouterEvent(event.url);
      }
    });

    this.parseRouterEvent(this.router.url);
  }

  parseRouterEvent(url: string) {
    const reg = /\/chat\/room\/([0-9]*)*/;
    const regexp = new RegExp(reg),
      test = regexp.test(url);

    if (test == true) {
      var result = url.match(reg);
      if (result == null) {
        this.resetActiveRoomId();
      } else {
        this.setActiveRoom(parseInt(result[1]));
      }
    } else {
      this.resetActiveRoomId();
    }
  }

  ngOnDestroy(): void {
    if (this.isBrowser) {
      this.unsubscribeSocket();
    }
    this.socketSub?.unsubscribe();
    this.paramSub?.unsubscribe();
    this.authSub?.unsubscribe();
  }

  playSound() {
    const element = this.document.getElementById('notification') as any;
    if (element != null) {
      element.click(); //important to prevent chrome security rules for sound files
      element.muted = false;
      element.play();
    }
  }

  /**
   * Invite people to room
   *
   * @param event
   */
  async invitePeopleToRoom(roomId: number) {
    const room = this.chats.get(roomId);

    if (room == null) {
      return;
    }
    const modal = await this.modalController.create({
      component: InviteModalComponent,
      componentProps: {
        inviteFriends: true,
        multipleSelect: true,
        useRest: false,
        disableUserIds: room.members.map(df => df.id),
        title: this.translate.translate('chats.invite_user'),
      },
    });

    await modal.present();
    const data = await modal.onDidDismiss();
    if (data?.data?.friends) {
      const friends: { [id: string]: string } = data?.data?.friends;

      if (room.id == null) {
        return;
      }
      let friendIds: string[] = Object.keys(friends);
      if (friendIds.length == 0) {
        this.toastService.createErrorToast(
          'At least one friend has to be selected for a chat room.'
        );
        return new Promise((resolve, reject) => {
          resolve(false);
        });
      } else {
        return this.inviteFriends(
          room.id,
          friendIds.map(df => parseInt(df))
        );
      }
    }
  }

  /**
   * Send direct message
   *
   * @param userId
   * @returns
   */
  public async sendDM(userId: number): Promise<number | null> {
    return new Promise((resolve, reject) => {
      if (!this.authService.isConnectionActive()) {
        return;
      }

      const possibleChats = this.chats
        .getList()
        .filter(
          df => df.type == ChatRoomType.User && df.members.filter(df => df.id == userId).length > 0
        );

      if (possibleChats.size > 0) {
        var firstChat = possibleChats.first();
        if (firstChat != null) {
          this.nav.navigateForward(['/app/chat/room/', firstChat.id]);

          resolve(firstChat.id);
        } else {
          resolve(null);
        }
      } else {
        this.restService
          .post('chats/rooms/join', {
            type: 'user',
            invitees: [userId],
          })
          .subscribe(
            result => {
              this.toastService.createSuccessToast(result.message);
              this.nav.navigateForward(['/app/chat/room/', result.data]);

              resolve(result.data);
            },
            error => {
              this.toastService.createErrorToast(error.message);
              resolve(null);
            }
          );
      }
    });
  }

  async createChatRoom(userIds: number[], title: string | null): Promise<boolean> {
    if (userIds.length == 1) {
      var result = await this.sendDM(userIds[0]);

      return new Promise((resolve, reject) => {
        if (result != null && result > 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    } else {
      return new Promise((resolve, reject) => {
        this.restService
          .post('chats/rooms/join', {
            type: 'group',
            title: title,
            invitees: userIds,
          })
          .subscribe(
            result => {
              this.nav.navigateForward(['/app/chat/room/', result.data]);
              resolve(true);
            },
            error => {
              this.toastService.createErrorToast(error.message);
              resolve(false);
            }
          );
      });
    }
  }

  async inviteFriends(roomId: number, userIds: number[]): Promise<boolean> {
    return await this.doRestPOSTCall('chats/rooms/invite', {
      chat_room: roomId,
      invitees: userIds,
    });
  }

  /*
  sendMessage(roomId: number, message: string) {
    const room = this.chats.get(roomId);
    if (room != null) {
      this.chats.delete(roomId);
      this.chats.unshift([room]);
    }
  }
*/

  renameChatRoom(roomId: number, title: string) {
    this.doRestPOSTCall('chats/rooms/rename', {
      chat_room: roomId,
      title: title,
    });
  }

  leaveChatRoom(roomId: number) {
    this.doRestGETCall('chats/rooms/leave/' + roomId);
  }

  kickUser(roomId: number, userId: number) {
    this.doRestGETCall('chats/rooms/' + roomId + '/kick/' + userId);
  }

  private doRestGETCall(url: string) {
    this.restService.get(url).subscribe(
      result => {
        this.toastService.createSuccessToast(result.message);
      },
      error => {
        this.toastService.createErrorToast(error.message);
      }
    );
  }

  private async doRestPOSTCall(url: string, data: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.restService.post(url, data).subscribe(
        result => {
          this.toastService.createSuccessToast(result.message);
          resolve(true);
        },
        error => {
          this.toastService.createErrorToast(error.message);
          resolve(false);
        }
      );
    });
  }

  /**
   * Subscribe sockets
   *
   */
  private subscribeSocket() {
    this.authService.listenOnAuthChannel('JoinChat', this.updateChatRoom, this);
    this.authService.listenOnAuthChannel('LeaveChat', this.deleteChatRoom, this);

    var values = this.chats.getList().map(df => df.id);
    this.subscribeRooms(values.toArray());
  }

  /**
   * Unsubsribe sockets
   */
  private unsubscribeSocket() {
    var values = this.chats.getList().map(df => df.id);

    this.unsubscribeRooms(values.toArray());

    this.authService.unlistenAuthChannel('JoinChat', this.updateChatRoom);
    this.authService.unlistenAuthChannel('LeaveChat', this.deleteChatRoom);
  }

  /**
   * @todo Use casts insteand of assigns
   */
  private updateChatRoom(res: any, args: any) {
    const df = res.data;

    var messageService: ChatService = args;
    const data = df as ChatRoomInstance;
    messageService.chats.updateOrInsert(data, true);
    messageService.subscribeRooms([data.id]);
  }

  private deleteChatRoom(e: any, args: any) {
    var messageService: ChatService = args;
    const roomid = parseInt(e.data);

    if (roomid > 0) {
      messageService.unjoinRoom([parseInt(e.data)]);
      messageService.onLeaveRoom.emit(roomid);

      if (messageService.lastKnownChatRoom == roomid) {
        messageService.lastKnownChatRoom = -1;
      }
    }
  }

  /**
   * Unjoin and delete rooms
   * @param roomIds
   */
  public unjoinRoom(roomIds: number[]) {
    for (let roomid of roomIds) {
      this.unsubscribeRooms([roomid]);
      this.chats.delete(roomid);
    }
  }

  /** Create and subscribe rooms */
  public subscribeRooms(roomIds: number[]) {
    if (this.authService.isConnectionActive()) {
      for (let roomId of roomIds) {
        var room = this.chats.get(roomId);

        if (room && room.channelSubscription == undefined) {
          room.channelSubscription = this.authService.subscribePrivateChannel(
            'timeline.chat.' + room.id
          );
          room.commandSub = room.channelSubscription.commands$.subscribe(commands => {
            for (let command of commands) {
              if (command.cmd == 'message') {
                this.onNewChatMessage(command.data);
              }
              if (command.cmd == 'join') {
                this.onUserJoin(command.data);
              }
              if (command.cmd == 'user_status') {
                this.OnUserState(command.data);
              }
              if (command.cmd == 'leave') {
                this.onUserLeave(command.data);
              }
              if (command.cmd == 'update-data') {
                this.onUpdateData(command.data);
              }
              if (command.cmd == 'typing') {
                this.onTyping(command.data);
              }
            }
          });
        }
      }
    }
  }

  public unsubscribeRooms(roomIds: number[]) {
    for (let roomId of roomIds) {
      var room = this.chats.get(roomId);
      if (room && room.channelSubscription != undefined) {
        room.commandSub?.unsubscribe();
        room.channelSubscription.unsubscribe();
        room.channelSubscription = undefined;
      }
    }
  }

  private onUpdateData(res: any) {
    const room = this.chats.get(res.room);
    if (room != null) {
      if (res['title'] != undefined) {
        room.title = res['title'];
      }

      if (res['image'] != undefined) {
        room.image = res['image'];
      }

      this.onChatUpdate$.next(res);
    }
  }

  private OnUserState(res: any) {
    const room = this.chats.get(res.room);
    if (room != null) {
      const index = room.members.findIndex(df => df.id == parseInt(res.user));

      if (index > -1) {
        room.members[index].onlineState = res.onlineState;
      }
      this.onUserStateChanged$.next(res);
    }
  }

  private onTyping(res: any) {
    var userId = parseInt(res.user);

    if (res.table != 'chat') {
      return;
    }

    if (res.tableId != this.activeRoom) {
      return;
    }
    const room = this.chats.get(parseInt(res.tableId));

    if (userId == this.authService.userData.id) {
      return;
    }
    if (room != null) {
      this.onMemberTyping$.next(res);
    }
  }

  private onUserJoin(res: any) {
    var chatMember: ChatMember = res.user;

    const room = this.chats.get(parseInt(res.room));
    if (room != null) {
      if (room.members.findIndex(df => df.id == chatMember.id) <= -1) {
        room.members.push(chatMember);
      }
      this.chats.updateOrInsert(room);
      this.onMemberJoin$.next(res);
    }
  }

  private onUserLeave(res: any) {
    var userId = parseInt(res.user);

    const room = this.chats.get(parseInt(res.room));
    if (room != null && room.type != ChatRoomType.User) {
      var index = room.members.findIndex(df => df.id == userId);
      if (index > -1) {
        room.members.splice(index, 1);
        this.chats.updateOrInsert(room);
      }

      this.onMemberLeave$.next(res);
    }
  }

  private onNewChatMessage(res: any) {
    var message = res as ChatMessage;
    this?.onNewMessageReceive.emit(message);
    const room = this.chats.get(message.table_id);

    //inactive rooms needs to ping users
    if (
      room != null &&
      this.activeRoom != message.table_id &&
      message.author &&
      message.author?.id != this.authService.userData.id
    ) {
      room.unreaded += 1;
      this.chats.updateOrInsert(room);
      this.playSound();
    }

    //set the chat room as top of list
    if (message.author && message.author?.id == this.authService.userData.id) {
      let chatRooms = this.chats.getList().toArray();
      let index = chatRooms.findIndex(df => df.id == message.table_id);
      if (index > -1) {
        chatRooms.unshift(chatRooms.splice(index, 1)[0]);
        this.chats.setValues(chatRooms);
      }
    }
  }

  private fetchChatRooms() {
    this.chats.reset();
    this.lastKnownChatRoom = -1;

    this.restService.getList('chats/rooms').subscribe(
      result => {
        const results = result as any[];
        for (let item of results) {
          const room = item as ChatRoomInstance;
          if (this.lastKnownChatRoom == -1) {
            this.lastKnownChatRoom = room.id;
          }
          this.chats.updateOrInsert(room);
        }

        if (this.isBrowser) {
          this.subscribeRooms(results.map(df => parseInt(df.id)));
        }
      },
      error => {
        this.destroyChatRooms();
      }
    );
  }

  private destroyChatRooms() {
    this.unsubscribeRooms(
      this.chats
        .getList()
        .toArray()
        .map(df => df.id)
    );
    this.chats.reset();
    this.lastKnownChatRoom = -1;
  }

  routeToPreviousChat() {
    if (this.lastKnownChatRoom != -1) {
      this.router.navigate(['/app/chat/room/' + this.lastKnownChatRoom]);
    } else {
      this.router.navigate(['/app/chat']);
    }
  }

  get allChatRooms() {
    return this.chats.values.pipe(map(items => items));
  }
}
