import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
  imports: [CommonModule],
  providers: [],
  declarations: [TooltipDirective],
  exports: [TooltipDirective],
})
export class TooltipModule {}
