import { Injector } from '@angular/core';
import { ModalController } from '@ionic/angular';

export abstract class ModalComponent {
  modalController: ModalController;

  constructor(injector: Injector) {
    this.modalController = injector.get(ModalController);
  }

  /**
   * @description Dismiss the modal
   * @author Stefan Boronczyk <stefan@strikd.com>
   */
  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
      openMode: null,
    });
  }
}
