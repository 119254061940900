import { Component, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModalComponent } from '../../framework/modal-component';

@Component({
  selector: 'app-launcher-download-modal',
  templateUrl: './launcher-download-modal.component.html',
  styleUrls: ['./launcher-download-modal.component.scss'],
})
export class LauncherDownloadModalComponent extends ModalComponent {
  constructor(protected injector: Injector) {
    super(injector);
  }

  get downloadUrl(): string {
    return environment.apiUrl;
  }

  download(event: any, type: string, platform: string) {
    event.preventDefault();
    window.open(this.downloadUrl + 'launcher/download/' + platform + '/' + type, '_blank');
    this.dismiss();
  }
}
