<button
  (click)="increase($event)"
  *ngIf="this.min ? this.value > this.min : true"
  class="btn btn-xs btn-solid secondary btn-decrease"
>
  <ion-icon name="remove"></ion-icon>
</button>

<input
  #input
  type="float"
  [id]="rootId"
  *ngIf="name"
  (change)="changed($event)"
  [name]="name || ''"
  [disabled]="disabled"
  [pattern]=""
  [min]="min"
  [max]="max"
  pattern="[0-9].,+"
  [placeholder]="placeholder"
  [value]="value | number : '1.2-2'"
  [autocomplete]="autocomplete ? name : 'nope'"
/>

<div class="currency" *ngIf="currency">
  <span>{{ currency }}</span>
</div>

<button
  (click)="decrease($event)"
  *ngIf="this.max ? this.value < this.max : true"
  class="btn btn-xs btn-solid secondary btn-increase"
>
  <ion-icon name="add"></ion-icon>
</button>
