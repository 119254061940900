<button
  (click)="increase($event)"
  *ngIf="this.min ? prepareNumber() > this.min : true"
  class="btn btn-xs btn-solid secondary btn-decrease"
>
  <ion-icon name="remove"></ion-icon>
</button>

<input
  #input
  type="number"
  [id]="rootId"
  *ngIf="name"
  [name]="name || ''"
  [disabled]="disabled"
  [min]="min"
  [size]="maxlength"
  [max]="max"
  [placeholder]="placeholder"
  [(ngModel)]="value"
  [autocomplete]="autocomplete ? name : 'nope'"
/>
<button
  (click)="decrease($event)"
  *ngIf="this.max ? prepareNumber() < this.max : true"
  class="btn btn-xs btn-solid secondary btn-increase"
>
  <ion-icon name="add"></ion-icon>
</button>
