import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { LangService } from 'src/app/shared/services/lang/lang.service';
import { RestService } from 'src/app/shared/services/rest/rest.service';
import { environment } from 'src/environments/environment';
import { LoginData } from '../models/login.data.model';
import { RegistrationDataModel } from '../models/registration.data.model';
import { UserData } from '../models/userData.model';

/**
 * @description Authentication Rest Service
 * @author Stefan Boronczyk <stefan@strikd.com>
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _userStateData: UserData = {
    name: '',
    custom_id: '',
    id: 0,
    slug: '',
    onlineState: 'offline',
    image: 'users/unknown.png',
  };

  public get userStateData(): UserData {
    return this._userStateData;
  }

  public set userStateData(value: UserData) {
    if (value.language != null) {
      this.langService.setLang(value.language);
    }
    this._userStateData = value;
  }

  constructor(
    private rest: RestService,
    private http: HttpClient,
    private langService: LangService
  ) {}

  /**
   * @description Register a user via rest call
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param data
   * @returns register
   */
  register(data: RegistrationDataModel): Observable<any> {
    return this.rest.post('auth/register', data);
  }

  /**
   * @description Sign in a user via rest call
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param user
   * @returns signin
   */
  signin(user: LoginData): Observable<any> {
    return this.rest.post<any>('auth/login', user);
  }

  /**
   * @description Sign out a user via rest call
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @returns logout
   */
  logout(): Observable<any> {
    return this.rest.get<any>('auth/logout');
  }

  /**
   * @description Get profile datas of an logged user
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @returns user
   */
  profileUser(): Observable<any> {
    return this.rest.get('auth/user');
  }

  /**
   * @desctription Autologin with secret
   * @author Nicki K.
   * @param secret
   * @returns autologin
   */
  autologin(secret: string): Observable<any> {
    return this.rest.post<any>('auth/autologin', { secret });
  }

  /**
   * @desctription Autologin with secret
   * @author Stefan B.
   * @param secret
   * @returns autologin
   */
  socialLogin(secret: string): Observable<any> {
    return this.rest.post<any>('auth/social/login', { secret });
  }
}
