import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Injector, OnDestroy, Optional, PLATFORM_ID } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { ResourceList } from '../libs/resources';
import { CoreModel, CoreModelInterface } from '../models/core.model';
import { RestService } from './rest/rest.service';

@Injectable()
export abstract class CoreSocketService<T extends CoreModelInterface> implements OnDestroy {
  public records: ResourceList<T> = new ResourceList<T>();
  protected socketSubscribed = true;

  protected isBrowser = false;
  protected restService: RestService;

  private authSub: Subscription | null = null;
  private socketSub: Subscription | null = null;
  protected authService: AuthStateService;

  public isList = false;

  public totalListAmount = 0;

  constructor(
    @Inject('') private readonly apiUrl: string,
    @Inject('') private readonly createSocketChannel: string,
    @Inject('') private readonly deleteSocketChannel: string,
    protected injector: Injector,
    @Optional() isList: boolean = false
  ) {
    this.isList = isList;
    this.authService = injector.get(AuthStateService);
    this.restService = injector.get(RestService);
    let browserObject = injector.get(PLATFORM_ID);
    this.isBrowser = isPlatformBrowser(browserObject);
    this.authSub = this.authService.userAuthed.subscribe(result => {
      if (result) {
        this.fetchItems();
      } else {
        this.destroyList();
      }
    });

    if (this.isBrowser) {
      this.socketSub = this.authService.userConnected.subscribe(result => {
        if (result === true) {
          this.subscribeSocket();
        } else {
          this.unsubscribeSocket();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.authSub?.unsubscribe();
    this.socketSub?.unsubscribe();
    this.unsubscribeSocket();
  }

  private fetchItems() {
    if (this.apiUrl == null) {
      return;
    }

    this.restService.getList(this.apiUrl).subscribe(
      result => {
        this.records.reset();
        if (this.isList) {
          this.records.setValues((result.items as any[]).map(df => df as T));
          this.totalListAmount = result.total;
        } else {
          const values = (result as any[]).map(df => df as T);
          this.records.setValues(values);
        }
      },
      error => {
        this.records.reset();
        this.destroyList();
      }
    );
  }

  private destroyList() {
    this.records.reset();
  }

  private subscribeSocket() {
    if (this.socketSubscribed == false) {
      if (this.createSocketChannel != null) {
        this.authService.listenOnAuthChannel(this.createSocketChannel, this.OnSocketCreate, this);
      }
      if (this.deleteSocketChannel != null) {
        this.authService.listenOnAuthChannel(this.deleteSocketChannel, this.OnDeleteCompany, this);
      }
      this.socketSubscribed = true;
    }
  }

  private unsubscribeSocket() {
    if (this.socketSubscribed == true) {
      if (this.createSocketChannel != null) {
        this.authService.unlistenAuthChannel(this.createSocketChannel, this.OnSocketCreate);
      }
      if (this.deleteSocketChannel != null) {
        this.authService.unlistenAuthChannel(this.deleteSocketChannel, this.OnDeleteCompany);
      }
      this.socketSubscribed = false;
    }
  }

  private OnSocketCreate(df: any, opts: any) {
    console.debug('[Service][Socket][Update] ' + opts.constructor.name);
    var obj = df.data as T;
    (opts.records as ResourceList<T>).updateOrInsert(obj);
  }

  private OnDeleteCompany(e: any, opts: any) {
    console.debug('[Service][Socket][Delete] ' + opts.constructor.name);
    (opts.records as ResourceList<T>).delete(parseInt(e.data.id));
  }
}
