import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-invite-routing',
  template: '',
})
export class InviteRoutingComponent implements OnInit {
  isBrowser = false;
  constructor(
    public router: Router,
    public nav: NavController,
    private stateService: AuthStateService,

    @Inject(PLATFORM_ID) private platformId: Object,

    public toastService: ToastService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      let inviteHash = location.pathname.split('/').reverse()[0];

      if (this.stateService.isLoggedIn) {
        this.stateService.handleInvites([inviteHash]);
      } else {
        let cache = this.stateService.getInvitesFromCache();
        if (!cache.includes(inviteHash)) {
          cache.push(inviteHash);
        }
        this.stateService.storeInvitesToCache(cache);
        this.router.navigateByUrl('/auth/login');
      }
    }
  }
}
