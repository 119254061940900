<ui-navigation-root *ngFor="let tab of tabs">
  <ui-navigation-header>{{ tab.name }}</ui-navigation-header>

  <ui-navigation-element
    *ngFor="let record of tab.actions"
    [iconName]="record.icon"
    (onClick)="switchTo(record)"
    [isActive]="record.key == activeTab?.key"
  >
    <span [transloco]="record.name"></span>
  </ui-navigation-element>
</ui-navigation-root>
