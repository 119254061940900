import {
  AfterContentChecked,
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';

@Component({ template: '' })
export abstract class UIPage implements OnInit, OnDestroy {
  authState: AuthStateService;
  public navCtrl: NavController;
  protected route: ActivatedRoute;
  protected meta: Meta;
  protected title: Title;
  protected changeDetectorRef: ChangeDetectorRef;

  public params: any = {};

  constructor(protected injector: Injector) {
    this.navCtrl = injector.get(NavController);
    this.authState = injector.get(AuthStateService);
    this.route = injector.get(ActivatedRoute);
    this.meta = injector.get(Meta);
    this.title = injector.get(Title);
    this.title = injector.get(Title);
    this.changeDetectorRef = injector.get(ChangeDetectorRef);
  }

  ngOnInit(): void {
    console.debug('[Component][' + this.constructor.name + '] Loaded');
    this.setParams(this.route.snapshot.params);
  }

  private setParams(params: Params): any {
    let obj: any = {};

    for (let paramId in params) {
      obj[paramId] = params[paramId];
    }

    this.params = obj;
  }

  ngOnDestroy(): void {
    console.debug('[Component][' + this.constructor.name + '] Destroyed');
  }

  onReload() {}
}
