import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UILazyLoadModule } from '../lazy-load/lazy-load.module';
import { UIPopoverModule } from '../ui-popover/ui-popover.module';
import { UIListItemActionDirective } from './ui-list-item/ui-list-item-action.directive';
import { UiListItemComponent } from './ui-list-item/ui-list-item.component';
import { UiListComponent } from './ui-list/ui-list.component';
import { UINavigationModule } from '../navigation/ui-navigation.module';

@NgModule({
  imports: [
    CommonModule,
    UIPopoverModule,
    RouterModule,
    IonicModule,
    UILazyLoadModule,
    UINavigationModule,
  ],
  declarations: [UiListItemComponent, UiListComponent, UIListItemActionDirective],
  exports: [UiListItemComponent, UiListComponent, UIListItemActionDirective],
})
export class UIListModule {}
