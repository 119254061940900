import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NoopValueAccessorDirective,
  UICoreInputComponent,
  UICoreInputProvider,
  injectNgControl,
} from '../ui-input-root.component';
import { IonicModule } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ui-input',
  standalone: true,
  templateUrl: 'ui-input.component.html',
  styleUrls: ['ui-input.component.scss'],
  hostDirectives: [NoopValueAccessorDirective],
  providers: [UICoreInputProvider(UIInputComponent)],
  imports: [ReactiveFormsModule, CommonModule, IonicModule, FormsModule],
})
export class UIInputComponent extends UICoreInputComponent implements OnInit, OnDestroy {
  @Input() type: string | undefined = 'text';
  @Input() name: string | undefined = undefined;

  @Input() placeholder: string | undefined = undefined;
  @Input() autocomplete: boolean = false;
  private _value: string | undefined = undefined;
  rootId: string | undefined = undefined;
  ngControl = injectNgControl();

  isDirty = false;
  private inputSub: Subscription | undefined = undefined;

  private _disabled: boolean = false;

  ngOnInit(): void {
    if (this.ngControl?.control) {
      this._value = this.ngControl.control.value;
      this.inputSub = this.ngControl.valueChanges?.subscribe(_ => {
        this._value = _;
      });
    }
  }

  @Input() get value(): string | undefined {
    return this._value;
  }

  set value(value: string | undefined) {
    this._value = value;
    this.ngControl?.control.setValue(this.value);
  }

  @Input() get disabled(): boolean {
    if (this._disabled) {
      return true;
    }

    if (this.ngControl) {
      return this.ngControl.isDisabled;
    }

    return false;
  }

  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  ngOnDestroy(): void {
    this.inputSub?.unsubscribe();
  }
  clear(event: any) {
    event.stopPropagation();
    event.preventDefault();

    this.value = undefined;
  }
}
