import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { UiFormListComponent } from './ui-form-list/ui-form-element.component';
import { UiLabelComponent } from './ui-label/ui-label.component';
import { UiPasswordComponent } from './ui-password/ui-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiFormErrorComponent } from './ui-form-error/ui-form-error.component';
import { UIInputComponent } from './ui-input/ui-input.component';
import { UINumberInputComponent } from './ui-number-input/ui-number-input.component';
import { UIDecimalInputComponent } from './ui-decimal-input/ui-decimal-input.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    UIInputComponent,
    UiPasswordComponent,
    UINumberInputComponent,
    UIDecimalInputComponent,
    UiLabelComponent,
  ],
  declarations: [UiFormErrorComponent, UiFormListComponent],
  exports: [
    UiLabelComponent,
    UiFormErrorComponent,
    UIDecimalInputComponent,
    UiFormListComponent,
    UiPasswordComponent,
    UIInputComponent,
    UINumberInputComponent,
  ],
})
export class UIFormModule {}
