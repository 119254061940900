import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonReorderGroup, ModalController, PopoverController } from '@ionic/angular';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { RestService } from 'src/app/shared/services/rest/rest.service';
import { DesktopService } from '../../../shared/services/electron.service';
import { CreateGroupModalComponent } from '../../spaces/components/create-group-modal/create-group-modal.component';
import { StreamPrepareModalComponent } from '../../streams/components/stream-prepare-modal/stream-prepare-modal.component';
import { FavoriteRecord } from '../models/favorite.record';
import { FavoriteService } from '../services/favorite.service';

@Component({
  selector: 'app-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.scss'],
})
export class ShortcutsComponent implements OnInit {
  constructor(
    public router: Router,
    public authService: AuthStateService,
    public rest: RestService,
    public popoverController: PopoverController,
    public favoriteService: FavoriteService,
    public modalController: ModalController,
    public desktopService: DesktopService
  ) {}
  @ViewChild(IonReorderGroup) reorderGroup: IonReorderGroup | undefined = undefined;

  items: FavoriteRecord[] = [];
  trackItems(index: number, itemObject: any) {
    if (!itemObject) return null;
    return itemObject.id;
  }
  onClick = false;
  wasMoving = false;
  currentItem: FavoriteRecord | null = null;

  async createGroup(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.popoverController.dismiss();
    if (this.authService.isLoggedIn) {
      const modal = await this.modalController.create({
        component: CreateGroupModalComponent,
        cssClass: 'auto-height ',
      });
      modal.present();
    } else {
      this.router.navigateByUrl('/auth/login');
    }
  }
  async createStream(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.popoverController.dismiss();
    if (this.authService.isLoggedIn) {
      const modal = await this.modalController.create({
        component: StreamPrepareModalComponent,
        cssClass: ' full-modal',
        componentProps: {
          apiUrl: 'streams/prepare?t=' + new Date().getTime(),
        },
      });
      modal.present();
    } else {
      this.router.navigateByUrl('/auth/login');
    }
  }

  doReorder(event: any) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);

    const items = this.items.map(df => df.id);
    this.rest.post('favorites/sort', { favorites: items }).subscribe();
  }

  ngOnInit() {
    this.items = FavoriteService.records.getList().toArray();
    FavoriteService.records.values.subscribe(df => {
      this.items = df.toArray();
    });
  }
}
