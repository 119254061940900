<ng-template>
  <div
    class="sat-popover-container"
    #focusTrapElement
    [ngClass]="_classList"
    [@transformPopover]="{ value: state, params: params }"
    (@transformPopover.done)="_onAnimationDone($event)"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
