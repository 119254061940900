<a
  [routerLink]="url"
  #anchor
  (contextmenu)="openContextMenu($event)"
  (click)="onClick.next($event)"
  [queryParams]="urlParams"
  [fragment]="urlFragment"
  [routerLinkActiveOptions]="{ exact: exactUrl }"
  class="ui-navigation-element {{ iconColor ? 'icon-' + iconColor : '' }} "
  routerLinkActive="active"
  [ngClass]="{
    active: isActive,
    second: isSecond,
    trashed: isTrashed,
    highlite: isHighliting,
    centered: isCentered
  }"
>
  <div class="ui-navigation-image" *ngIf="thumbnail">
    <ui-lazy-img
      [width]="thumbnailSize"
      [height]="thumbnailSize"
      [alt]="thumbnailLabel"
      [src]="thumbnail"
    ></ui-lazy-img>
    <ng-container *ngIf="hasImageContent">
      <ng-content select="[imageContent]"></ng-content>
    </ng-container>
  </div>

  <ion-icon class="menu-icon" [ngClass]="iconSlot" *ngIf="iconName" [name]="iconName"></ion-icon>
  <ion-icon
    class="menu-icon"
    [ngClass]="iconSlot"
    *ngIf="iconSrc"
    [src]="iconSrc"
    [color]="iconColor"
  ></ion-icon>

  <div class="ui-navigation-element-inner"><ng-content></ng-content></div>
  <div class="btn-action-button" *ngIf="hasActionButton && hasContextItems">
    <button
      (click)="openContextMenu($event)"
      class="btn btn-xxs btn-chat btn-solid btn-only-icon secondary uppercase"
    >
      <ion-icon name="ellipsis-vertical"></ion-icon>
    </button>
  </div>
</a>

<ui-popover
  #popover
  hasBackdrop
  (backdropClicked)="popover.close()"
  verticalAlign="start"
  openAnimationStartAtScale="0"
  closeAnimationEndAtScale="0"
  [anchor]="anchor"
  *ngIf="hasContextMenu || hasActionButton"
  horizontalAlign="end"
>
  <div class="side-menu side-menu-dropdown" (click)="popover.close()">
    <ui-navigation-root><div #holdContextChilds></div></ui-navigation-root>
  </div>
</ui-popover>
