import { countBy } from 'lodash';
import { finalize, Observable, Subject, Subscriber } from 'rxjs';
import { Socket, SocketOptions } from 'socket.io-client';
import { Channel } from './channel';
import { NetworkCommand } from './command';
import { EventFormatter } from './event-formatter';

export interface EventSubscription {
  mainClass: any;
  callback: EventSubscriptionCallback;
}

export type EventSubscriptionCallback = (data: any, classObject: any) => any;

/**
 * This class represents a Socket.io channel.
 */
export class SocketChannel extends Channel {
  /**
   * The Socket.io client instance.
   */
  socket: Socket;

  /**
   * The name of the channel.
   */
  name: any;

  /**
   * Channel options.
   */
  options: any;

  /**
   * The event formatter.
   */
  eventFormatter: EventFormatter;

  /**
   * The event callbacks applied to the socket.
   */
  events: any = {};

  /**
   * User supplied callbacks for events on this channel.
   */
  private listeners: { [id: string]: EventSubscription[] } = {};

  /**
   * Create a new class instance.
   */
  constructor(socket: any, name: string, options: any) {
    super();

    this.name = name;
    this.socket = socket;
    this.options = options;
    this.eventFormatter = new EventFormatter(this.options.namespace);

    this.subscribe();
  }

  /**
   * Subscribe to a Socket.io channel.
   */
  subscribe(): void {
    console.debug('[Service][Socket][Sub][' + this.name + ']');
    this.socket.emit('subscribe', {
      channel: this.name,
      auth: this.options.auth || {},
    });
    console.debug('[Service][Socket][Listen][' + this.name + '] OnCommand');
    this.socket.on(this.eventFormatter.format('\\' + 'OnCommand'), this.onCommand.bind(this));
  }

  onCommand(channel: string, data: any) {
    if (this.name == channel && data && data.data) {
      let commands = data.data as NetworkCommand[];
      if (commands && commands.length > 0) {
        this._commands?.next(commands);
      }
    }
  }

  /**
   * Unsubscribe from channel and ubind event callbacks.
   */
  unsubscribe(): void {
    this.socket.removeListener(this.eventFormatter.format('\\' + 'OnCommand'), this.onCommand);
    console.debug('[Service][Socket][Unlisten][' + this.name + '] OnCommand');

    this.unbind();

    console.debug('[Service][Socket][UnSub][' + this.name + ']');
    this.socket.emit('unsubscribe', {
      channel: this.name,
      auth: this.options.auth || {},
    });
  }

  /**
   * Listen for an event on the channel instance.
   */
  listen(event: string, callback: EventSubscriptionCallback, opt: any = null): SocketChannel {
    console.debug('[Service][Socket][Listen][' + this.name + '] ' + event);
    this.on(this.eventFormatter.format(event), callback, opt);
    return this;
  }

  /**
   * Stop listening for an event on the channel instance.
   */
  stopListening(event: string, callback?: Function): SocketChannel {
    console.debug('[Service][Socket][Unlisten][' + this.name + '] ' + event);
    this.unbindEvent(this.eventFormatter.format(event), callback);
    return this;
  }

  /**
   * Register a callback to be called anytime a subscription succeeds.
   */
  subscribed(callback: Function, opt: any = null): SocketChannel {
    this.on('connect', (socket: any) => {
      callback(socket, opt);
    });

    return this;
  }

  /**
   * Register a callback to be called anytime an error occurs.
   */
  error(callback: Function): SocketChannel {
    return this;
  }

  /**
   * Bind the channel's socket to an event and store the callback.
   */
  on(event: string, callback: EventSubscriptionCallback, opt: any = null): SocketChannel {
    this.listeners[event] = this.listeners[event] || [];

    if (!this.events[event]) {
      this.events[event] = (channel: any, data: any) => {
        if (this.name === channel && this.listeners[event]) {
          this.listeners[event].forEach((cb: EventSubscription) => cb.callback(data, cb.mainClass));
        }
      };

      this.socket.on(event, this.events[event]);
    }

    this.listeners[event].push({ callback: callback, mainClass: opt });

    return this;
  }

  /**
   * Unbind the channel's socket from all stored event callbacks.
   */
  unbind(): void {
    Object.keys(this.events).forEach(event => {
      this.unbindEvent(event);
    });
  }

  /**
   * Unbind the listeners for the given event.
   */
  protected unbindEvent(event: string, callback?: Function): void {
    this.listeners[event] = this.listeners[event] || [];

    if (callback) {
      this.listeners[event] = this.listeners[event].filter((cb: any) => cb.callback !== callback);
    }

    if (!callback || this.listeners[event].length === 0) {
      if (this.events[event]) {
        this.socket.removeListener(event, this.events[event]);

        delete this.events[event];
      }

      delete this.listeners[event];
    }
  }
}
