import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Platform, PopoverController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthStateService } from './auth/services/auth-state.service';
import { IssueTrackerComponent } from './shared/components/issue-tracker/issue-tracker/issue-tracker.component';
import { AppService } from './shared/services/app.service';
import { DesktopService } from './shared/services/electron.service';
import { LangService } from './shared/services/lang/lang.service';
import { WaitingService } from './shared/services/rest/waiting.service';
import { PreRouteService } from './shared/services/router/router.service';
import { StorageService } from './shared/services/storage.service';
import { ToastService } from './shared/services/toast.service';
const HANDSHAKE = String.fromCodePoint(0x1f91d);
const MAG = String.fromCodePoint(0x1f50e);
const ROCKET = String.fromCodePoint(0x1f680);

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
  private splashScreen: SplashScreen | null = null;
  private statusBar: StatusBar | null = null;

  isBrowser = false;
  isLoading = false;
  loaderSub: Subscription | null = null;
  constructor(
    public authState: AuthStateService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private storage: StorageService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslocoService,
    private platform: Platform,
    private injector: Injector,
    public waiting: WaitingService,
    public appService: AppService,
    private toastService: ToastService,
    private langService: LangService,
    public preRouteService: PreRouteService,
    private popoverController: PopoverController,
    public desktopService: DesktopService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    this.desktopService.onMessage.subscribe((message: string) => {
      if (message !== '') {
        this.toastService.createInfoToast(message);
      }
    });

    this.route.queryParams.subscribe(params => {
      const lang = params['lang'];
      if (lang != undefined) {
        console.log('Override language to ' + lang);
        this.langService.setLang(lang).subscribe();
      }
    });

    this.waiting.isLoading.subscribe(df => setTimeout(() => (this.isLoading = df > 0)));

    if (this.isBrowser) {
      const welcomeStyle = ['font-size: 20px', 'color: orange'].join(';');
      const welcomeMessage = ROCKET + ' Welcome to Striked ' + ROCKET + '\n' + '\n';

      const issueMessage =
        'Does this page need fixes or improvements? Open an issue ticket to help make Striked more lovable.' +
        '\n' +
        '\n' +
        '🤝 Create a new Striked or feature request issue: https://git.join-striked.com/striked/launcher/-/issues';

      const issueStyle = [].join(';');
      console.log('%c%s%c%s', welcomeStyle, welcomeMessage, issueStyle, issueMessage);

      this.splashScreen = this.injector.get(SplashScreen);
      this.statusBar = this.injector.get(StatusBar);
      this.initializeApp();

      this.loaderSub = this.router.events
        .pipe(
          filter(
            event =>
              event instanceof NavigationStart ||
              event instanceof NavigationEnd ||
              event instanceof NavigationCancel ||
              event instanceof NavigationError
          )
        )
        .subscribe(event => {
          if (event instanceof NavigationStart) {
            this.waiting.add('component');
          } else {
            this.waiting.remove('component');
            this.appService.visibleLeftUiMenu = false;
          }
        });
    }
  }

  /**
   * @description Open create issue modal
   * @author Stefan Boronczyk <stefan@striked.de>
   * @param event
   */
  public async createIssueModal(event: any) {
    const modal = await this.popoverController.create({
      component: IssueTrackerComponent,
      cssClass: ' report-popover',
      showBackdrop: false,
      event: event,
      backdropDismiss: false,
      trigger: 'report-trigger',
      side: 'bottom',
      alignment: 'end',
      componentProps: {
        screen: document.body.parentNode,
      },
    });
    await modal.present(event);
    await modal.onWillDismiss();
  }

  initializeApp() {
    if (this.platform.is('mobile')) {
      this.splashScreen?.show();
    }
    this.platform.ready().then(() => {
      if (this.platform.is('mobile')) {
        this.splashScreen?.hide();
        this.statusBar?.styleLightContent();
      }
      this.desktopService.isReady();
    });
  }

  ngOnDestroy(): void {
    this.loaderSub?.unsubscribe();
    this.authState.closeSession();
  }
}
