import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { tap } from 'rxjs/operators';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { ResourceList } from 'src/app/shared/libs/resources';
import { MenuAction } from 'src/app/shared/models/menu/menu.records';
import { RestService } from 'src/app/shared/services/rest/rest.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { FavoriteRecord } from '../models/favorite.record';

@Injectable({
  providedIn: 'root',
})
export class FavoriteService implements OnDestroy {
  public static records: ResourceList<FavoriteRecord> = new ResourceList<FavoriteRecord>();

  isBrowser = false;

  constructor(
    private restService: RestService,
    @Inject(PLATFORM_ID) private platformId: Object,
    public authService: AuthStateService,
    public router: Router,
    public toastService: ToastService,
    protected translation: TranslocoService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    this.authService.userAuthed.subscribe(result => {
      if (result == true) {
        this.fetchFavoriteRecords(true);
      } else {
        FavoriteService.records.reset();
      }
    });

    if (this.isBrowser) {
      this.authService.userConnected.subscribe(result => {
        if (result === true) {
          this.subscribeSocket();
        } else {
          this.unsubscribeSocket();
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.isBrowser) {
      this.unsubscribeSocket();
    }
  }

  get favorites() {
    return FavoriteService.records.values.pipe(
      tap(results => {
        results.sort((n1, n2) => n1.sort - n2.sort);
      })
    );
  }

  /**
   * Delete an existing favorite record
   *
   * @param userId
   */
  delete(type: string, typeId: number) {
    const records = FavoriteService.records
      .getList()
      .filter(df => df.type == type && df.typeId == typeId);

    for (let record of records) {
      this.restService.delete('favorites/' + record.id).subscribe(
        result => {
          this.toastService.createSuccessToast(result.message);
        },
        error => {
          this.toastService.createErrorToast(error.message);
        }
      );
    }
  }

  reduceUnread(type: string, id: number, unread: number) {
    const records = FavoriteService.records
      .getList()
      .filter(df => df.type == type && df.typeId == id);
    for (let record of records) {
      if (record.card.readed) {
        record.card.readed -= unread;
        if (record.card.readed < 0) {
          record.card.readed = 0;
        }

        FavoriteService.records.update(record.id, record);
      }
    }
  }
  /**
   * create an favorite record
   *
   * @param itemId
   */
  create(type: string, id: number) {
    if (FavoriteService.isInList(type, id)) {
      return;
    }

    if (!this.authService.isLoggedIn) {
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.router.url } });
      return;
    }

    this.restService.post('favorites', { type: type, id: id }).subscribe(
      result => {
        if (!this.authService.isConnectionActive()) {
          const data = result.data as FavoriteRecord;
          if (!FavoriteService.isInList(type, id)) {
            FavoriteService.records.add(data);
          }
        }
        this.toastService.createSuccessToast(result.message);
      },
      error => {
        this.toastService.createErrorToast(error.message);
      }
    );
  }

  static isInList(type: string, typeId: number): boolean {
    return (
      FavoriteService.records.getList().filter(df => df.type == type && df.typeId == typeId).size >
      0
    );
  }

  generateActionRecords(type: string, id: number): MenuAction | null {
    if (!this.authService.isLoggedIn) {
      return null;
    }
    if (FavoriteService.isInList(type, id)) {
      return {
        name: this.translation.translate('shortcut.delete'),
        icon: 'unlink',
        callback: () => {
          this.delete(type, id);
        },
      };
    } else {
      return {
        name: this.translation.translate('shortcut.create'),
        icon: 'link',

        callback: () => {
          this.create(type, id);
        },
      };
    }
  }

  private subscribeSocket() {
    this.authService.listenOnAuthChannel('NewFavoriteEvent', this.NewFavoriteEvent);
    this.authService.listenOnAuthChannel('DeleteFavoriteEvent', this.deleteFavoriteEvent);
  }

  private unsubscribeSocket() {
    this.authService.unlistenAuthChannel('NewFavoriteEvent', this.NewFavoriteEvent);
    this.authService.unlistenAuthChannel('DeleteFavoriteEvent', this.deleteFavoriteEvent);
  }

  private NewFavoriteEvent(df: any) {
    if (!df.data.card) {
      return;
    }
    if (FavoriteService.isInList(df.data.type, df.data.id)) {
      return;
    }

    const data = df.data as FavoriteRecord;
    FavoriteService.records.add(data);
  }

  private deleteFavoriteEvent(e: any) {
    FavoriteService.records.delete(parseInt(e.data.id));
  }

  /**
   * Fetch the current basket list after user is logged in
   *
   * @returns
   */
  private fetchFavoriteRecords(authed: boolean = false) {
    this.restService.getList(authed ? 'favorites' : 'favorites/recommended').subscribe(
      result => {
        FavoriteService.records.reset();
        const results = result as any[];
        FavoriteService.records.setValues(
          results.filter(df => df.card).map(df => df as FavoriteRecord)
        );
      },
      error => {
        FavoriteService.records.reset();

        this.destroyFavoriteList();
      }
    );
  }

  /**
   * Destroy basket list after user is logged out
   */
  private destroyFavoriteList() {
    FavoriteService.records.reset();
  }
}
