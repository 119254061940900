import { Injectable, EventEmitter } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
declare var electron: any;

@Injectable({
  providedIn: 'root',
})
export class DesktopService {
  private onMessageState = new BehaviorSubject<string>('');
  onMessage = this.onMessageState.asObservable();

  constructor(private _platform: Platform) {
    if (this.isDesktop()) {
      (window as any).api?.receive('message', (message: string) => {
        this.onMessageState.next(message);
      });
    }
  }

  public isDesktop() {
    return this._platform.is('electron');
  }

  public isReady() {
    if (this.isDesktop()) {
      (window as any).api.send('ready', null);
    }
  }

  public maximize() {
    (window as any).api.send('maximize', null);
  }

  public minimize() {
    (window as any).api.send('minimize', null);
  }

  public close() {
    (window as any).api.send('close', null);
  }

  public openChat() {
    if (this.isDesktop()) {
      (window as any).api.openNewWindow('friends.html');
    }
  }
}
