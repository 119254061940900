import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { map, Observable, Subscription } from 'rxjs';
import { ModalComponent } from 'src/app/shared/framework/modal-component';
import { RestService } from 'src/app/shared/services/rest/rest.service';
import {
  FormBuilderOption,
  FormBuilderOptions,
  FormBuilderTagResult,
} from 'src/app/ui/form-builder/form-builder-options';
import { FormBuilderSteps } from 'src/app/ui/form-builder/form-builder-wizard/form-builder-wizard.component';
import { GroupForm } from '../../forms/group.form';

@Component({
  selector: 'app-create-group-modal',
  templateUrl: './create-group-modal.component.html',
  styleUrls: ['./create-group-modal.component.scss'],
})
export class CreateGroupModalComponent extends ModalComponent implements OnInit, OnDestroy {
  public form: FormBuilderOptions = Object.assign({}, GroupForm);
  public topicsLoaded = false;
  public steps: FormBuilderSteps | null = null;
  private gameSearchSubscription: Subscription | null = null;

  constructor(injector: Injector, public restService: RestService, private nav: NavController) {
    super(injector);
  }

  setSteps(event: FormBuilderSteps) {
    this.steps = event;
  }

  ngOnInit(): void {
    this.form.tabs.details.sections.general.inputs.apps.tagSearchCallback = this.searchGames;

    if (!this.topicsLoaded) {
      this.topicsLoaded = false;
      this.restService.getList('spaces/topics').subscribe(
        res => {
          this.topicsLoaded = true;
          this.form.tabs.details.sections.general.inputs.topics.options = this.generateTopics(
            res as any[]
          );
        },
        error => {
          this.topicsLoaded = false;
        }
      );
    }
  }

  /**searchGames
   * @description Searchs in games
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param event
   * @returns
   */
  public searchGames(term: string, rest: RestService): Observable<FormBuilderTagResult[]> {
    if (term == '' || term == null || term.length <= 0) {
      return new Observable(observer => {
        observer.next([]);
        observer.complete();
      });
    }

    return rest.post('search/apps', { term: term }).pipe(
      map((values: any) => {
        if (values.data && values.data.length > 0) {
          return values.data;
        } else {
          return [];
        }
      })
    );
  }

  /**
   * @description After wizard success
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param data
   */
  success(data: any) {
    this.dismiss();
    this.nav.navigateForward(['/', 'app', 'space', data.data.slug]);
  }

  /**
   * @description Override dissmiss
   * @author Stefan Boronczyk <stefan@strikd.com>
   */
  dismiss(): void {
    this.gameSearchSubscription?.unsubscribe();
    super.dismiss();
  }

  /**
   * @description Split topics into a single array
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param topics
   * @returns topics
   */
  private generateTopics(topics: any[]): FormBuilderOption[] {
    let options: FormBuilderOption[] = [];

    if (topics != null && topics != undefined) {
      for (const topic of topics) {
        options.push({ key: topic.id, value: topic.title });

        if (topic.children != undefined && topic.children != null) {
          for (const topicId in topic.children) {
            var child = topic.children[topicId];
            options.push({ key: child.id, value: topic.title + ' > ' + child.title });
          }
        }
      }
    }

    return options;
  }

  ngOnDestroy(): void {
    this.gameSearchSubscription?.unsubscribe();
  }
}
