<div class="side-navigation">
  <div class="header-intro left-icon" *ngIf="title">
    <button
      *ngIf="backButtonUrl"
      [routerLink]="backButtonUrl"
      class="btn btn-xxs btn-solid btn-only-icon secondary"
    >
      <ion-icon slot="start" name="arrow-back-outline"></ion-icon>
    </button>
    <span>{{ title }}</span>
  </div>
  <ng-content></ng-content>
</div>
