import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Injector, Optional, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { LoadingController, ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

import { finalize, from, mergeMap, Observable, ReplaySubject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { OnlineState } from 'src/app/b2c/friends/models/friendship';
import { RestService } from 'src/app/shared/services/rest/rest.service';
import { Channel } from 'src/app/shared/services/socket/client/channel';
import Echo from 'src/app/shared/services/socket/client/echo';
import { PresenceChannel } from 'src/app/shared/services/socket/client/presence-channel';
import { SocketPrivateChannel } from 'src/app/shared/services/socket/client/socket-private-channel';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { environment } from 'src/environments/environment';
import { DesktopService } from '../../shared/services/electron.service';
import { OnboardModalComponent } from '../components/onboard-modal/onboard-modal.component';
import { UserData } from '../models/userData.model';
import { AuthService } from './auth.service';
import { TokenService } from './token.service';
import { LangService } from 'src/app/shared/services/lang/lang.service';
import { AppService } from 'src/app/shared/services/app.service';
export interface SystemMessage {
  color: string;
  message: string;
  ms?: number;
  timeout?: NodeJS.Timeout;
}
@Injectable({
  providedIn: 'root',
})
export class AuthStateService {
  isBrowser = false;

  private client: Echo = new Echo();
  private socketState = new BehaviorSubject<boolean>(false);
  private userState = new ReplaySubject<boolean>(1);
  public currentMessage: SystemMessage | null = null;
  private isUserLoggedIn = false;
  private activeSocketConnection = false;
  private privateChannel: Channel | null = null;
  private onlineStateChannel: PresenceChannel | null = null;
  private userChannel: Channel | null = null;

  userAuthed = this.userState.asObservable();
  userConnected = this.socketState.asObservable();
  private swPush: SwPush | null = null;

  private loadingElement: HTMLIonLoadingElement | undefined = undefined;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private token: TokenService,
    private authService: AuthService,
    public toastService: ToastService,
    private desktop: DesktopService,
    private appService: AppService,
    private route: ActivatedRoute,
    private langService: LangService,
    private modalCtrl: ModalController,
    private storageService: StorageService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,

    private rest: RestService,
    private injector: Injector,
    private loading: LoadingController,
    private translate: TranslocoService,

    @Optional() @Inject(REQUEST) protected serverRequest?: Request
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    //do login in or logout on all browser tabs
    if (this.isBrowser) {
      this.handleParams();
      this.swPush = this.injector.get(SwPush);
    }
  }

  public storeInvitesToCache(invites: string[]) {
    this.storageService.set('invites', JSON.stringify(invites));
  }

  public getInvitesFromCache(): string[] {
    let inviteArray: string[] = [];
    const items = this.storageService.get('invites');
    if (items) {
      let parsed = JSON.parse(items);
      if (parsed != null) {
        inviteArray = parsed as string[];
      }
    }
    return inviteArray;
  }

  public handleInvites(inviteArray: string[]) {
    let setInviteUrl = '';
    if (inviteArray.length > 0 && this.isBrowser) {
      from(inviteArray)
        .pipe(
          mergeMap(hash => this.rest.get('invites/accept/' + hash)),
          finalize(() => {
            if (setInviteUrl == '') {
              this.router.navigateByUrl('/app/dashboard/feed');
            } else {
              this.router.navigateByUrl(setInviteUrl);
            }
            this.storageService.set('invites', null);
          })
        )
        .subscribe(
          df => {
            this.toastService.createInfoToast(df.message);
            setInviteUrl = df.data.redirect;
          },
          error => {
            this.toastService.createErrorToast(error.message);
          }
        );
    }
  }

  public setOnlineState(state: string) {
    this.rest
      .post('settings/onlineState', {
        onlineState: state,
      })
      .subscribe(df => {});
  }

  public get myOnlineState(): OnlineState {
    if (!this.isConnectionActive()) {
      return OnlineState.Offline;
    }
    if (this.userData.onlineState == 'online') {
      return OnlineState.Online;
    } else if (this.userData.onlineState == 'busy') {
      return OnlineState.Busy;
    } else {
      return OnlineState.Offline;
    }
  }
  /**
   * Subscribe push notifications (chat messages, timeline posts)
   */
  private subscribePushNotifications() {
    if (this.isBrowser && this.swPush && environment.production) {
      this.swPush
        .requestSubscription({
          serverPublicKey: environment.pushPubliyKey,
        })
        .then((sub: any) => this.rest.post('auth/notifications/store', sub).subscribe())
        .catch((err: any) => console.error('Could not subscribe to notifications', err));
    }
  }

  handleParams() {
    this.route.queryParams.subscribe(params => {
      //autologin
      if (params.al !== null && params.al !== undefined) {
        this.authService.autologin(params.al).subscribe(
          result => {
            this.handleAutoLogin(result);
          },
          error => {
            const errors = error.data;
            if (errors && errors.error) {
              this.toastService.createErrorToast(errors.error, 20000);
            }

            this.router.navigate(['/auth/login']);
          }
        );
      }

      //social login
      else if (params.social !== null && params.social !== undefined) {
        this.authService.socialLogin(params.social).subscribe(
          result => {
            this.handleAutoLogin(result);
          },
          error => {
            const errors = error.data;
            if (errors && errors.error) {
              this.toastService.createErrorToast(errors.error, 20000);
            }

            this.router.navigate(['/auth/login']);
          }
        );
      }
      //verify
      else if (params.verify !== null && params.verify !== undefined) {
        let decoded: string | null = null;

        try {
          decoded = atob(params.verify);
        } catch {
          this.router.navigate(['/auth/login']);

          return;
        }

        if (decoded != null) {
          this.rest.get(decoded).subscribe(
            result => {
              this.handleAutoLogin(result);
            },
            error => {
              const errors = error.data;
              if (errors && errors.error) {
                this.toastService.createErrorToast(errors.error, 20000);
              }

              this.router.navigate(['/auth/login']);
            }
          );
        }
      }
    });
  }

  /**
   * Handle rest response
   * @param data
   */
  private async handleAutoLogin(data: any) {
    this.token.handleData(data.data);

    await this.toastService.createSuccessToast(data.message, 20000);

    await this.setAuthState(true).subscribe(df => {
      const url = '/app/dashboard/feed';
      this.router.navigate([url]);
    });
  }

  public GetChannel(name: string): Channel {
    return this.client.channel(name);
  }

  /**
   * User have a valid socket connection
   * @returns boolean
   */
  public isConnectionActive(): boolean {
    return this.activeSocketConnection;
  }

  /**
   * User is loggedin or not
   * @returns boolean
   */
  public get isLoggedIn(): boolean {
    return this.isUserLoggedIn;
  }

  /**
   * @description Start auth session
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @returns session
   */
  public startSession(): Observable<boolean> {
    return new Observable(observer => {
      const token = this.token.isValidToken();

      if (token) {
        this.setAuthState(true).subscribe(
          df => {
            observer.next(df);
            observer.complete();
          },
          error => {
            observer.next(false);
            observer.complete();
          }
        );
      } else {
        this.userState.next(false);
        this.onboardInUse = false;
        observer.next(false);
        observer.complete();
      }
    });
  }

  /**
   * Do a logout
   */
  logout() {
    this.authService.logout().subscribe(
      result => {
        this.toastService.createSuccessToast(result.message);
        this.closeSession();
        this.token.removeToken();
        this.currentMessage = null;
        this.router.navigated = false;
      },
      error => {
        this.closeSession();
        this.token.removeToken();
        this.router.navigated = false;
        this.currentMessage = null;
      }
    );
  }

  /**
   * Get the user datas
   */
  get userData(): UserData {
    return this.authService.userStateData;
  }

  /**
   * Get the user datas
   */
  set userData(value: UserData) {
    this.authService.userStateData = value;

    if (value.language) {
      this.langService.setLang(value.language).subscribe();
    }

    if (value.theme) {
      this.appService.setTheme(value.theme);
    }
  }

  subscribePrivateChannel(name: string): SocketPrivateChannel {
    return this.client.private(name);
  }

  unsubscribePrivateChannel(name: string) {
    if (this.isConnectionActive()) {
      console.debug('[Service][Socket][Private][Unsub] private-' + name);
      this.client.leaveChannel('private-' + name);
    }
  }

  unsubscribePublicChannel(name: string) {
    if (this.isConnectionActive()) {
      console.debug('[Service][Socket][Public][Unsub] ' + name);
      this.client.leaveChannel(name);
    }
  }

  subscribePresenceChannel(name: string): PresenceChannel {
    console.debug('[Service][Socket][Presence][Sub] ' + name);
    return this.client.join(name);
  }

  unsubscribePresenceChannel(name: string) {
    if (this.isConnectionActive()) {
      console.debug('[Service][Socket][Presence][Unsub] presence-' + name);
      this.client.leaveChannel('presence-' + name);
    }
  }

  /**
   * Subscribe an auth channel action
   * @param name
   * @param callback
   */
  listenOnAuthChannel(name: string, callback: Function, opt: any = null) {
    this.listenChannel(this.privateChannel, name, callback, opt);
  }

  /**
   * Listen to a channel action
   * @param name
   * @param callback
   */
  listenChannel(channel: Channel | null, name: string, callback: Function, opt: any = null) {
    if (channel == null) {
      throw Error('channel cant be null');
    }
    channel?.listen('\\' + name, callback, opt);
  }

  /**
   * Unlisten an channel action
   * @param name
   * @param callback
   */
  unlistenChannel(channel: Channel | null, name: string, callback: Function, opt: any = null) {
    if (this.isConnectionActive()) {
      if (channel == null) {
        throw Error('channel cant be null');
      }

      channel.stopListening('\\' + name, callback);
      this.client.leave(name);
    }
  }

  /**
   * Unsubscribe an auth channel action
   * @param name
   * @param callback
   */
  unlistenAuthChannel(name: string, callback: Function) {
    this.unlistenChannel(this.privateChannel, name, callback);
  }

  /**
   * Set the authenfication state and try to get user datas
   * @param value
   */
  setAuthState(value: boolean): Observable<boolean> {
    return new Observable(observer => {
      if (this.isLoggedIn) {
        observer.next(true);
        observer.complete();
      } else if (value === false) {
        this.closeSession();

        observer.next(false);
        observer.complete();
      } else {
        console.debug('[Auth] Start session: ' + this.token.isValidToken());

        //try to get user datas and then trigger the login signal (just on client side)
        this.authService.profileUser().subscribe({
          next: result => {
            this.isUserLoggedIn = true;
            this.activeSocketConnection = false;
            this.authService.userStateData = result.data as UserData;

            this.userState.next(true);

            this.initSocket();
            this.subscribePushNotifications();
            this.handleInvites(this.getInvitesFromCache());

            if (this.userData.theme) {
              this.appService.setTheme(this.userData.theme);
            }

            if (this.userData.language) {
              this.langService.setLang(this.userData.language).subscribe(
                result => {
                  observer.next(true);
                  observer.complete();
                },
                error => {
                  observer.next(true);
                  observer.complete();
                }
              );
            } else {
              observer.next(true);
              observer.complete();
            }
          },
          error: (error: any) => {
            this.closeSession();

            observer.error(error);
            observer.complete();
          },
        });
      }
    });
  }

  onboardInUse = false;
  async doOnboarding(url: string) {
    if (!this.isBrowser || this.onboardInUse || !this.isLoggedIn) {
      return;
    }
    if (!this.authService.userStateData.onboarded && url.startsWith('/app')) {
      this.onboardInUse = true;
      const modal = await this.modalCtrl.create({
        component: OnboardModalComponent,
        cssClass: 'auto-height',
        backdropDismiss: false,
      });
      modal.present();
      await modal.onWillDismiss();
    }
  }

  drawSystemMessage(message: SystemMessage) {
    if (this.isBrowser) {
      if (this.currentMessage != null && this.currentMessage.timeout) {
        clearTimeout(this.currentMessage.timeout);
        this.currentMessage.timeout = undefined;
      }

      if (message.ms && message.ms > 0) {
        message.timeout = setTimeout(() => {
          this.currentMessage = null;
        }, message.ms);
      }

      this.currentMessage = message;
    }
  }

  /**
   * Try to connect with socket
   */
  async initSocket() {
    if (!this.isBrowser) {
      return;
    }
    console.debug('[Service][Socket] Init');

    this.drawSystemMessage({
      message: this.translate.translate('general.try_connect'),
      color: 'info',
    });

    this.client.options = {
      broadcaster: 'socket.io',
      host: environment.socketUrl,
      auth: {
        headers: {
          Authorization: 'Bearer ' + this.token.getToken(),
        },
      },
    };

    this.client.connect();

    if (this.client.connector) {
      this.client.connector.socket?.on('disconnect', this.onDisconnect.bind(this));
      this.client.connector.socket?.on('connect_error', this.onConnectionError.bind(this));
      this.client.connector.socket?.on('connect', this.onConnection.bind(this));
    }
  }
  onDisconnect(reason: string) {
    console.debug('[Service][Socket] Disconnect');
    this.activeSocketConnection = false;
    this.socketState.next(false);

    if (this.isLoggedIn) {
      this.drawSystemMessage({
        message: this.translate.translate('general.disconnected'),
        color: 'danger',
      });
    }
  }
  onConnectionError(error: object) {
    console.error('[Service][Socket]' + error);
    this.activeSocketConnection = false;
    this.socketState.next(false);
  }
  onConnection() {
    console.debug('[Service][Socket] Connected');

    this.connectSub();
    this.activeSocketConnection = true;
    this.socketState.next(true);

    this.drawSystemMessage({
      message: this.translate.translate('general.connected'),
      color: 'success',
      ms: 3000,
    });
  }

  /**
   * Destroy connection with socket
   */
  private destroySocket() {
    if (!this.isBrowser) {
      return;
    }

    this.activeSocketConnection = false;
    this.disconnectSub();
    this.socketState.next(false);
    this.client.disconnect();
    // this._currentMessage = null;
    // this.currentMessage.next(null);

    console.debug('[Service][Socket] Destroy');
  }

  /**
   * Close session and remove token from browser cache
   */
  public closeSession() {
    if (this.isBrowser) {
      console.debug('[Auth] Close session');
      this.destroySocket();
      this.authService.userStateData = {
        name: '',
        onlineState: 'offline',
        id: 0,
        custom_id: '',
        slug: '',
        image: 'users/unknown.png',
      };
      this.isUserLoggedIn = false;
      this.userState.next(false);
      this.onboardInUse = false;
    }
  }

  private connectSub() {
    this.privateChannel = this.subscribePrivateChannel('user.' + this.authService.userStateData.id);

    this.onlineStateChannel = this.subscribePresenceChannel(
      'online.' + this.authService.userStateData.id
    );
    this.userChannel = this.GetChannel('user.settings.update.' + this.authService.userStateData.id);
    this.listenOnAuthChannel('UpdateSettings', this.updateUserDataOnListen, this);
  }

  private disconnectSub() {
    this.unsubscribePresenceChannel('online.' + this.authService.userStateData.id);
    this.unlistenAuthChannel('UpdateSettings', this.updateUserDataOnListen);
  }

  private updateUserDataOnListen(df: any, classObject: any) {
    classObject.userData = df.data as UserData;
  }
}

export function initializeAuthApp(authService: AuthStateService): () => Observable<any> {
  return () => authService.startSession();
}
