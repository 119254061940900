<div class="btn-list" *ngIf="(authService.userAuthed | async) === true">
  <button
    class="btn btn-xxs btn-block btn-solid secondary btn-radius-50"
    (click)="createGroup($event)"
  >
    <ion-icon slot="start" name="people"></ion-icon>
    <span transloco="shortcuts.actions.create_group"></span>
  </button>

  <button
    (click)="openPopover($event)"
    id="create-popover"
    class="btn btn-xxs btn-block btn-solid secondary btn-only-icon"
  >
    <ion-icon name="ellipsis-horizontal"></ion-icon>
  </button>
</div>
<ion-popover
  animated="false"
  triggerAction="click"
  [showBackdrop]="false"
  [dismissOnSelect]="true"
  animated="false"
  #popover
  alignment="end"
  class="select-popover"
>
  <ng-template>
    <div class="side-menu side-menu-dropdown" *ngIf="(authService.userAuthed | async) === true">
      <ui-navigation-root>
        <ui-navigation-element (onClick)="createGroup($event, true)" iconName="people">
          <span transloco="shortcuts.actions.create_group"></span>
        </ui-navigation-element>

        <ui-navigation-element (onClick)="createStream($event, true)" iconName="videocam">
          <span transloco="shortcuts.actions.create_stream"></span>
        </ui-navigation-element>
      </ui-navigation-root>
    </div>
  </ng-template>
</ion-popover>
