import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  isBrowser = false;

  constructor(private injector: Injector, @Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  public set(key: string, value: any) {
    if (!this.isBrowser) {
    } else {
      localStorage.setItem(key, value);
    }
  }

  public get(key: string): any {
    if (!this.isBrowser) {
      return null;
    } else {
      return localStorage.getItem(key);
    }
  }
}
