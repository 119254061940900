import { AfterContentInit, Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { ModalTabPageRest } from 'src/app/shared/framework/modal/modal-tab-page-rest';
import { UIModalRest } from 'src/app/shared/framework/modal/ui-modal-rest';
import { RestService } from 'src/app/shared/services/rest/rest.service';
import {
  FormBuilderOption,
  FormBuilderOptions,
  FormBuilderTagResult,
} from 'src/app/ui/form-builder/form-builder-options';
import { FormBuilderSteps } from 'src/app/ui/form-builder/form-builder-wizard/form-builder-wizard.component';
import { FormBuilder } from 'src/app/ui/form-builder/form-builder.component';
import { CreateStreamForm } from '../create.form';

@Component({
  selector: 'app-stream-prepare-settings',
  templateUrl: './stream-prepare-settings.component.html',
  styleUrls: ['./stream-prepare-settings.component.scss'],
})
export class StreamPrepareSettingsComponent
  extends ModalTabPageRest<any>
  implements OnInit, AfterContentInit
{
  public getUrl(): string | null {
    return 'streams/prepare?t=' + new Date().getTime();
  }

  public onActivation(): void {
    this.updateForm();
  }
  public form: FormBuilderOptions = Object.assign({}, CreateStreamForm);
  public topicsLoaded = false;
  public steps: FormBuilderSteps | null = null;

  setSteps(event: FormBuilderSteps) {
    this.steps = event;
  }
  @ViewChild('wizard', { static: true }) builder: FormBuilder | undefined = undefined;
  constructor(protected injector: Injector, private authState: AuthStateService) {
    super(injector);
  }
  ngOnInit(): void {
    this.form.tabs.settings.sections.general.inputs.app.tagSearchCallback = this.searchGames;

    if (!this.topicsLoaded) {
      this.topicsLoaded = false;
      this.rest.getList('spaces/topics').subscribe(
        res => {
          this.topicsLoaded = true;
          this.form.tabs.settings.sections.general.inputs.topics.options = this.generateTopics(
            res as any[]
          );
        },
        error => {
          this.topicsLoaded = false;
        }
      );
    }
  }

  /**
   * @description Split topics into a single array
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param topics
   * @returns topics
   */
  private generateTopics(topics: any[]): FormBuilderOption[] {
    let options: FormBuilderOption[] = [];

    if (topics != null && topics != undefined) {
      for (const topic of topics) {
        options.push({ key: topic.id, value: topic.title });

        if (topic.children != undefined && topic.children != null) {
          for (const topicId in topic.children) {
            var child = topic.children[topicId];
            options.push({ key: child.id, value: topic.title + ' > ' + child.title });
          }
        }
      }
    }

    return options;
  }

  /**searchGames
   * @description Searchs in games
   * @author Stefan Boronczyk <stefan@strikd.com>
   * @param event
   * @returns
   */
  public searchGames(term: string, rest: RestService): Observable<FormBuilderTagResult[]> {
    if (term == '' || term == null || term.length <= 0) {
      return new Observable(observer => {
        observer.next([]);
        observer.complete();
      });
    }

    return rest.post('search/apps', { term: term }).pipe(
      map((values: any) => {
        if (values.data && values.data.length > 0) {
          return values.data;
        } else {
          return [];
        }
      })
    );
  }

  ngAfterContentInit(): void {
    super.ngAfterContentInit();
    this.updateForm();
  }

  updateForm() {
    if (this.data) {
      this.form.tabs.access.visible = true;
      this.builder?.setValues({
        server: this.data.server,
        title: this.data.title,
        desc: this.data.desc,
        token: this.data.token,
        topics: this.data.topics.map((df: any) => {
          return { key: df.id, value: df.title };
        }),
        app: this.data.app ? [this.data.app] : [],
      });
    } else {
      this.form.tabs.access.visible = false;

      this.builder?.setValues({
        server: null,
        title: null,
        desc: null,
        token: null,
        topics: [],
        app: [],
      });
    }
  }

  afterSubmit(event: any) {
    this.data = event.data;
    this.updateForm();
  }
  start() {
    this.rest.post('streams/start?t=' + new Date().getTime(), {}).subscribe(df => {
      this.data = df.data;
      this.updateForm();
    });
  }

  clearStream() {
    this.data = null;
  }

  stop() {
    this.rest.get('streams/stop?t=' + new Date().getTime()).subscribe(df => {
      this.clearStream();
      this.updateForm();
    });
  }
}
