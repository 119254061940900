import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { LoadingContentComponent } from './loading-content.component';

@NgModule({
  imports: [TranslocoModule, IonicModule, CommonModule],
  providers: [],
  declarations: [LoadingContentComponent],
  exports: [LoadingContentComponent],
})
export class LoadingContentModule {}
