import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { UiSearchbarComponent } from './ui-searchbar.component';

@NgModule({
  imports: [TranslocoModule, FormsModule, CommonModule, IonicModule],
  providers: [],
  declarations: [UiSearchbarComponent],
  exports: [UiSearchbarComponent],
})
export class UISearchbarModule {}
