<div cdkDropList (cdkDropListDropped)="doReorder($event)" *ngIf="items.length > 0">
  <div cdkDrag *ngFor="let item of items; trackBy: trackItems; let index = index">
    <div class="shortcut-placeholder" *cdkDragPlaceholder></div>
    <app-shortcut-item
      [draggable]="true"
      class="cdk-drag-animating"
      [item]="item"
    ></app-shortcut-item>
  </div>
</div>
