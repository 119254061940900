<ion-content scrollbar>
  <div class="inner-content-modal" [windowResizeTrigger]="windowSize">
    <div class="modal-inner">
      <div class="modal-content">
        <h2 class="modal-title" *ngIf="title">{{ title | transloco }}</h2>
        <ng-content></ng-content>
      </div>
    </div>
    <div class="modal-close" (click)="doClose()" *ngIf="hasClose">
      <ion-icon class="close-icon" slot="icon-only" name="close-circle-outline"></ion-icon>
    </div>
  </div>
</ion-content>
