<div class="tags-input-bubble">
  <div class="tags-input">
    <div
      class="selected-tag"
      [ngClass]="{
        'input-text': !multipleSelection
      }"
      *ngFor="let option of allOptions"
    >
      <span class="title">{{ option.value || '' }}</span>
      <span class="remove" (click)="emitValue(option)"><ion-icon name="close"></ion-icon></span>
    </div>
    <input
      type="text"
      #coverTrigger
      [disabled]="isDisabled()"
      (keyup)="doTagSearch($event)"
      (keydown)="doDelete($event)"
      [style.display]="isDisabled() ? 'none' : 'block'"
      [placeholder]="value.placeholder && !isDisabled() ? (value.placeholder | transloco) : ''"
    />
  </div>
  <div class="select-dropdown" *ngIf="tagResults && tagResults.length > 0 && !isDisabled()">
    <div class="select-dropdown-inner scrollable">
      <ui-navigation-root>
        <ui-navigation-element
          (onClick)="emitValue(result)"
          [thumbnail]="result && result.image ? (result.image | cloudUrl : '50x50') : undefined"
          *ngFor="let result of tagResults || []"
        >
          <span>{{ result.value }}</span>
        </ui-navigation-element>
      </ui-navigation-root>
    </div>
  </div>
</div>
