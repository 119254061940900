<div class="app-mobile-nav undraggable">
  <div class="chat-item">
    <div
      class="shortcut-icon shortcut-action-button"
      [routerLink]="'/app/browse/popular/all'"
      [ngClass]="{ 'shortcut-active': isActive() }"
    >
      <img alt="Striked" width="24" src="./assets/logo/striked-rocket.svg" />
    </div>
  </div>
</div>
