<ui-page [leftOnlyOnMobile]="false" [hasLeftSide]="true" windowResize="main">
  <div class="dashboard-navigation" left>
    <ion-popover
      trigger="more-navigation"
      [dismissOnSelect]="true"
      [showBackdrop]="false"
      triggerAction="click"
    >
      <ng-template>
        <div class="side-menu side-menu-dropdown">
          <ui-navigation-root>
            <ui-navigation-element url="/app/game-studios">
              <span transloco="publisher.title"></span>
            </ui-navigation-element>
            <ui-navigation-element url="/help">
              <span transloco="help.title"></span>
            </ui-navigation-element>
            <ui-navigation-element url="/b2b/about-us">
              <span transloco="about_us.title"></span>
            </ui-navigation-element>
            <ui-navigation-element url="/page/imprint">
              <span transloco="footer.imprint"></span>
            </ui-navigation-element>
            <ui-navigation-element url="/page/privacy">
              <span transloco="footer.privacy"></span>
            </ui-navigation-element>
            <ui-navigation-element url="/page/tos">
              <span transloco="footer.tos"></span>
            </ui-navigation-element>
          </ui-navigation-root>
        </div>
      </ng-template>
    </ion-popover>

    <div class="outer-content scrollable">
      <div class="wrapper">
        <div class="side-menu">
          <ui-navigation-root>
            <ui-navigation-header>Navigation</ui-navigation-header>

            <ui-navigation-element
              iconName="home"
              [exactUrl]="true"
              url="/"
              *ngIf="!authState.isLoggedIn"
            >
              <span transloco="home.title"></span>
            </ui-navigation-element>

            <ui-navigation-element iconName="apps" url="/app/browse/popular/all">
              <span transloco="store.title"></span>
            </ui-navigation-element>

            <ui-navigation-element iconName="people" url="/app/spaces">
              <span transloco="spaces.title"></span>
            </ui-navigation-element>

            <ui-navigation-element iconName="videocam" url="/app/streams">
              <span transloco="streams.overview.title"></span>
            </ui-navigation-element>

            <ui-navigation-element iconName="dice" url="/app/realms">
              <span transloco="realms.overview.title"></span>
            </ui-navigation-element>

            <ui-navigation-element iconName="storefront" url="/app/market">
              <span transloco="market.title"></span>
            </ui-navigation-element>

            <ui-navigation-element id="more-navigation" iconName="ellipsis-horizontal">
              <span transloco="show_more_records"></span>
            </ui-navigation-element>
          </ui-navigation-root>
        </div>
      </div>
    </div>
    <div class="dashboard-navigation-quick">
      <app-shortcut-quick-action></app-shortcut-quick-action>
    </div>
  </div>
  <router-outlet center></router-outlet>
</ui-page>
