import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastController: ToastController, private translate: TranslocoService) {}

  /**
   * Creating a toast with error message
   * @param message
   */
  async createErrorToast(message: string, duration: number = 5000) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: 'bottom',
      cssClass: 'desktop-toast',
      buttons: [
        {
          text: this.translate.translate('general.okay'),
          role: 'cancel',
          cssClass: 'desktop-toast-button',
        },
      ],
      color: 'danger',
    });

    toast.present();
  }
  /**
   * Creating a toast with error message
   * @param message
   */
  async createSuccessToast(message: string, duration: number = 5000) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      color: 'success',
      icon: 'checkmark-circle-outline',
      cssClass: 'desktop-toast',
      position: 'bottom',
      buttons: [
        {
          text: this.translate.translate('general.okay'),
          role: 'cancel',
          cssClass: 'desktop-toast-button',
        },
      ],
    });

    toast.present();
  }

  /**
   * Creating a toast with error message
   * @param message
   */
  async createInfoToast(message: string, duration: number = 5000) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      color: 'info',
      position: 'bottom',
      cssClass: 'desktop-toast',
      icon: 'information-circle-outline',
      buttons: [
        {
          text: this.translate.translate('general.okay'),
          role: 'cancel',
          cssClass: 'desktop-toast-button',
        },
      ],
    });

    toast.present();
  }
}
