import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UILazyLoadModule } from '../lazy-load/lazy-load.module';
import { UIPopoverModule } from '../ui-popover/ui-popover.module';
import { UINavigationModule } from '../navigation/ui-navigation.module';
import { UiTileComponent } from './ui-tile/ui-tile.component';

@NgModule({
  imports: [
    CommonModule,
    UIPopoverModule,
    RouterModule,
    IonicModule,
    UILazyLoadModule,
    UINavigationModule,
  ],
  declarations: [UiTileComponent],
  exports: [UiTileComponent],
})
export class UITileModule {}
