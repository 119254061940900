import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-label',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ui-label.component.html',
  styleUrls: ['./ui-label.component.scss'],
})
export class UiLabelComponent {
  rootId: string | undefined = undefined;
}
