import { Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { B2CComponent } from './b2c/b2c.component';
import { InviteRoutingComponent } from './b2c/friends/pages/invite-routing/invite-routing.component';
import { MainPageComponent } from './b2c/main/pages/main-page/main-page.component';
import { AuthenticationGuard } from './shared/guards/auth.guard';
import { NotFoundComponent } from './shared/pages/errors/404/not-found.component';

export const B2C_ROUTE: Routes = [
  {
    path: 'app',
    component: B2CComponent,
    children: [
      {
        path: 'jobs',
        loadChildren: () =>
          import('./b2b/jobs/pages/overview/jobs.module').then(m => m.JobsOverviewModule),
      },

      {
        path: 'b2b',
        children: [
          {
            path: 'company/:id',
            loadChildren: () =>
              import('./b2c/game-center/pages/company/company.page.module').then(
                m => m.CompanyPageModule
              ),
            canActivate: [AuthenticationGuard],
          },
          {
            path: 'product/:id',
            loadChildren: () =>
              import('./b2c/game-center/pages/products/app.module').then(m => m.AppPageModule),
            canActivate: [AuthenticationGuard],
          },
          {
            path: 'game-centre',
            loadChildren: () =>
              import('./b2c/game-center/pages/dashboard/dashboard.module').then(
                m => m.DashboardPageModule
              ),
            canActivate: [AuthenticationGuard],
          },
        ],
      },

      {
        path: '',
        component: MainPageComponent,
        children: [
          {
            path: 'browse',
            loadChildren: () =>
              import('./b2c/products/pages/overview/store.module').then(m => m.StorePageModule),
          },
          {
            path: 'prototypes',
            loadChildren: () =>
              import('./b2c/products/pages/prototypes/prototypes.module').then(
                m => m.PrototypesPageModule
              ),
          },
          {
            path: 'realms',
            loadChildren: () =>
              import('./b2c/realms/pages/overview/realms-overview.module').then(
                m => m.RealmsOverviewPageModule
              ),
          },
          {
            path: 'game-studios',
            loadChildren: () =>
              import('./b2c/game-studios/game-studios.module').then(m => m.GameStudiosRootModule),
          },
          {
            path: 'community',
            loadChildren: () =>
              import('./b2c/community/community.module').then(m => m.CommunityPageModule),
          },
          {
            path: 'spaces',
            loadChildren: () =>
              import('./b2c/spaces/pages/overview/groups.module').then(m => m.GroupsPageModule),
          },
          {
            path: 'streams',
            loadChildren: () =>
              import('./b2c/streams/pages/overview/streams.module').then(m => m.StreamPageModule),
          },
          {
            path: 'stream',
            loadChildren: () =>
              import('./b2c/streams/pages/single/single.module').then(m => m.StreamSingleModule),
          },

          {
            path: 'game-studio/:slug',
            loadChildren: () =>
              import('./b2c/company/pages/company.module').then(m => m.CompanyPageModule),
          },
          {
            path: 'user/:slug',
            loadChildren: () => import('./b2c/users/user.module').then(m => m.UserPageModule),
          },

          {
            path: 'product/:slug',
            loadChildren: () =>
              import('./b2c/products/pages/product/product.module').then(m => m.ProductPageModule),
          },
          {
            path: 'realm/:slug',
            loadChildren: () =>
              import('./b2c/realms/pages/realm-page/realm-page.module').then(
                m => m.RealmPageModule
              ),
          },
          {
            path: 'manufacturer/:slug',
            loadChildren: () =>
              import('./b2c/realms/pages/realm-company-page/company.module').then(
                m => m.RealmCompanyPageModule
              ),
          },
          {
            path: 'market',
            loadChildren: () => import('./b2c/market/market.module').then(m => m.MarketPageModule),
          },
          {
            path: 't/:id',
            loadChildren: () =>
              import('./b2c/timeline/timeline.module').then(m => m.TimelinePageModule),
          },
          {
            path: '',
            redirectTo: '/app/dashboard/feed',
            pathMatch: 'full',
          },
        ],
      },

      {
        path: 'dashboard',
        loadChildren: () =>
          import('./b2c/dashboard/dashboard.module').then(m => m.DashboardPageModule),
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'library',
        loadChildren: () => import('./b2c/library/library.module').then(m => m.LibraryRootModule),
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./b2c/search/search-results/search-results.module').then(
            m => m.SearchResultsModule
          ),
      },
      {
        path: 'friends',
        loadChildren: () => import('./b2c/friends/pages/friends.module').then(m => m.FriendsModule),
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'missions',
        loadChildren: () =>
          import('./b2c/missions/pages/missions.module').then(m => m.MissionsModule),
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./b2c/notifications/pages/notifications.module').then(m => m.NotificationsModule),
        canActivate: [AuthenticationGuard],
      },
      {
        path: 'basket',
        loadChildren: () => import('./b2c/basket/pages/basket.module').then(m => m.BasketModule),
      },
      {
        path: 'space',
        loadChildren: () =>
          import('./b2c/spaces/pages/single/group.page.module').then(m => m.GroupPageModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./b2c/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthenticationGuard],
      },

      {
        path: 'chat',
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
          import('./b2c/chat/pages/chatroom/chatroom.module').then(m => m.ChatroomPageModule),
      },

      { path: '404', component: NotFoundComponent },
      { path: '**', component: NotFoundComponent },
    ],
  },
  { path: 'user/:slug', redirectTo: 'user/:slug', pathMatch: 'full' },

  {
    path: '',
    loadChildren: () => import('./b2c/landing/landing-root.module').then(m => m.LandingRootModule),
  },
];

export const APP_ROUTES: Routes = [
  { path: 'invite/:hash', component: InviteRoutingComponent },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./auth/pages/login/login.module').then(m => m.LoginPageModule),
      },
      {
        path: 'register',
        loadChildren: () =>
          import('./auth/pages/registration/registration.module').then(
            m => m.RegistrationPageModule
          ),
      },
    ],
  },
];
