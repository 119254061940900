import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-settings-modal-content',
  templateUrl: './settings-modal-content.component.html',
  styleUrls: ['./settings-modal-content.component.scss'],
})
export class SettingsModalContentComponent implements OnInit {
  @Input() hasClose = true;
  @Input() windowSize = 'ui-modal';
  @Input() title: string | null | undefined = null;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter(false);
  constructor() {}

  ngOnInit(): void {}

  doClose() {
    this.onClose.emit(true);
  }
}
