import { APP_BASE_HREF, isPlatformBrowser } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { makeStateKey, StateKey, TransferState } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { TokenService } from 'src/app/auth/services/token.service';
import { urlJoin } from 'src/app/shared/libs/urljoin';
import { environment } from '../../../../environments/environment';
import { restLog } from '../../log.helper';
import { RestError } from '../../models/rest/error.model';
import { WaitingService } from './waiting.service';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  protected isBrowser = false;

  constructor(
    private http: HttpClient,
    private waitingService: WaitingService,
    private transferState: TransferState,
    private tokenService: TokenService,
    @Optional() @Inject(APP_BASE_HREF) origin: string,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  prepareUrl(uri: string, lang = null) {
    const pattern = /^((http|https):\/\/)/;

    if (pattern.test(uri)) {
      return uri;
    }

    let apiUri = environment.apiUrl;

    if (!this.isBrowser) {
      apiUri = environment.internalApiUrl;
    }

    uri = urlJoin(apiUri, uri);
    return uri;
  }

  /**
   * Do a get request without mapping
   *
   * @param url
   */
  getKey(): string {
    const date = new Date();
    const timestamp = Math.round(date.getTime() / 1000);

    return 'data-load-' + timestamp;
  }

  /**
   *
   * Post blob multi upload method
   * @param url
   * @param data
   */
  multiUpload<T>(url: string, name: string, files: any[], formData: any = {}): Observable<T> {
    const data: FormData = new FormData();
    if (files != null) {
      let i = 0;
      for (let file of files) {
        data.append(name + '[' + i + ']', file);
        i++;
      }
    }

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        data.append(key, formData[key]);
      }
    }

    const generatedUrl = this.prepareUrl(url);
    const options = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      reportProgress: true,
      observe: 'events' as 'body',
    };

    return this.http.post<T>(generatedUrl, data, options);
  }

  /**
   *
   * Post blob upload method
   * @param url
   * @param data
   */
  upload<T>(url: string, name: string, file: any, formData: any = {}): Observable<T> {
    const data: FormData = new FormData();
    if (file != null) {
      data.append(name, file);
    }

    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        data.append(key, formData[key]);
      }
    }

    const generatedUrl = this.prepareUrl(url);
    const options = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      reportProgress: true,
      observe: 'events' as 'body',
    };

    return this.http.post<T>(generatedUrl, data, options);
  }

  private attachLoader(url: string) {
    if (this.isBrowser) {
      this.waitingService.add(url);
    }
  }
  private detachLoader(url: string) {
    if (this.isBrowser) {
      this.waitingService.remove(url);
    }
  }

  /**
   * Base 64 blod upload method
   *
   * @param url
   * @param data
   */
  uploadBase64(url: string, data: any): any {
    const generatedUrl = this.prepareUrl(url);
    const options = {
      headers: new HttpHeaders({ Accept: 'application/json' }),
      reportProgress: true,
      observe: 'events' as 'body',
    };

    this.attachLoader(generatedUrl);

    return this.http.post<any>(generatedUrl, data, options).pipe(
      restLog(`[Upload64] ` + url),
      map(event => {
        return this.getEventMessage(event);
      }),
      catchError(error => {
        return this.handleError('post', error);
      }),
      finalize(() => {
        this.detachLoader(generatedUrl);
      })
    );
  }

  getList(url: string): Observable<any> {
    return this.getCore<any>(url, true);
  }

  getCore<T>(url: string, withoutData: boolean = false): Observable<T> {
    const generatedUrl = this.prepareUrl(url);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    const key: StateKey<T> = makeStateKey<T>(generatedUrl);
    if (!this.isBrowser) {
      return this.http.get<T>(this.prepareUrl(generatedUrl)).pipe(
        restLog(`[Get] ` + url),
        map((item: any) => {
          if (withoutData) {
            this.transferState.set<any>(key, item.data as T);
            return item.data as T;
          } else {
            this.transferState.set<any>(key, item as T);
            return item as T;
          }
        }),
        catchError(error => {
          return this.handleError('[Get]', error);
        })
      );
    } else {
      const result = this.transferState.get<T | null>(key, null);
      if (result != null) {
        this.transferState.set<T | null>(key, null);
        return new Observable(observer => {
          observer.next(result);
          observer.complete();
        });
      } else {
        return this.http.get<T>(this.prepareUrl(generatedUrl), options).pipe(
          tap({
            next: () => this.attachLoader(generatedUrl),
            complete: () => this.detachLoader(generatedUrl),
            error: () => this.detachLoader(generatedUrl),
          }),
          restLog(`[Get] ` + url),
          map((item: any) => {
            if (withoutData) {
              return item.data as T;
            } else {
              return item as T;
            }
          }),
          catchError(error => {
            return this.handleError('get', error);
          }),
          finalize(() => {
            this.detachLoader(generatedUrl);
          })
        );
      }
    }
  }

  /**
   * Do a post request
   *
   * @param url
   * @param data
   */
  post<T = any>(url: string, data: any): Observable<T> {
    const generatedUrl = this.prepareUrl(url);

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.post<any>(generatedUrl, JSON.stringify(data), options).pipe(
      tap({
        next: () => this.attachLoader(generatedUrl),
        complete: () => this.detachLoader(generatedUrl),
        error: () => this.detachLoader(generatedUrl),
      }),
      restLog(`[Post] ` + url),
      map(result => {
        return result as T;
      }),
      catchError(error => {
        return this.handleError('post', error);
      }),
      finalize(() => {
        this.detachLoader(generatedUrl);
      })
    );
  }

  /**
   * Get request
   *
   * @param url
   */
  get<T = any>(url: string): Observable<T> {
    return this.getCore<T>(url, false);
  }

  /**
   * Delete request
   * @param url
   */
  delete<T = any>(url: string): Observable<T> {
    const generatedUrl = this.prepareUrl(url);
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.delete<any>(this.prepareUrl(generatedUrl), options).pipe(
      tap({
        next: () => this.attachLoader(generatedUrl),
        complete: () => this.detachLoader(generatedUrl),
        error: () => this.detachLoader(generatedUrl),
      }),
      map(result => {
        return result as T;
      }),
      restLog(`[Delete] ` + url),

      catchError(error => {
        return this.handleError('get', error);
      }),
      finalize(() => {
        this.detachLoader(generatedUrl);
      })
    );
  }

  /**
   * Handle http errors
   *
   * @param operation
   * @param error
   */
  private handleError(operation = 'operation', error: HttpErrorResponse, showMessageBox = true) {
    // TODO: send the error to remote logging infrastructure
    let errorMessage = '';
    const restErrorObject: RestError = new RestError();

    if (error.status == 404) {
      errorMessage = `${error.statusText} at ${error.url}`;
      Object.assign(restErrorObject, error);
    } else if (error.error != null && error.error.message != null) {
      errorMessage = `${error.error.message}`;
      Object.assign(restErrorObject, error.error);
    } else if (error.message != null) {
      errorMessage = `${error.message}`;
      Object.assign(restErrorObject, error);
    } else {
      errorMessage = `${error}`;
      Object.assign(restErrorObject, error);
    }

    return throwError(restErrorObject);
  }

  /** Log a message with the MessageService */
  private log(message: string) {
    if (this.isBrowser) {
      console.debug(`[HTTP]${message}`);
    }
  }
  /** Log a message with the MessageService */
  private logError(message: string) {
    console.error(message);
  }

  private getEventMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        const total = event.total === undefined ? 0 : event.total;
        const progress = Math.round((100 * event.loaded) / total);
        return { status: 'progress', message: progress };
      case HttpEventType.Response:
        return { status: 'response', message: event.body };
      default:
        return { status: 'event', message: event.type };
    }
  }
}
