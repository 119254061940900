import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Routes } from '@angular/router';
import 'moment/locale/de';
import 'moment/locale/en-gb';

import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { APP_ROUTES, B2C_ROUTE } from 'src/app/app-routes';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class PreRouteService {
  protected isBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private _platform: Platform,
    @Optional() @Inject(REQUEST) protected serverRequest?: Request,
    @Optional() @Inject(DOCUMENT) protected document?: Document
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  detectRoutes(): Routes {
    let routes: Routes = [];
    if (this._platform.is('electron')) {
      routes.push({
        path: '',
        redirectTo: '/app/dashboard/feed',
        pathMatch: 'full',
      });
    }

    routes.push(...APP_ROUTES);
    routes.push(...B2C_ROUTE);

    return routes;
  }
}
export function initializePreRouteFactory(
  preRouteQuery: PreRouteService
): Routes | Promise<Routes> {
  return preRouteQuery.detectRoutes();
}
