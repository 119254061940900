import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-tile',
  templateUrl: './ui-tile.component.html',
  styleUrls: ['./ui-tile.component.scss'],
})
export class UiTileComponent {
  @Input() title: string | undefined | null = undefined;
  @Input() hasFooter: boolean = true;
  @Input() active: boolean = false;
  @Input() url: string | undefined | null = undefined;
  @Input() thumbnail: string | undefined | null = undefined;
  @Input() tileRatio: string = '6x8';
  @Output() onClick = new EventEmitter<MouseEvent>();
  imageLoaded($event: any) {
    $event.target.classList.add('loaded');
  }
}
