import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormBuilderModule } from 'src/app/ui/form-builder/form-builder.module';
import { OnboardModalComponent } from './onboard-modal.component';
import { MediaModule } from 'src/app/shared/directives/media/media.module';
import { TranslocoModule } from '@ngneat/transloco';
import { UITileModule } from 'src/app/ui/tile/ui-tile.module';

@NgModule({
  declarations: [OnboardModalComponent],
  exports: [OnboardModalComponent],
  imports: [
    IonicModule,
    CommonModule,
    UITileModule,
    TranslocoModule,
    FormBuilderModule,
    MediaModule,
  ],
})
export class OnboardModule {}
