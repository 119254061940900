<div class="app-mobile-nav undraggable">
  <div class="chat-item" *ngIf="(authService.userAuthed | async) === true">
    <div
      placement="right"
      [tooltip]="authService.userData.name"
      class="shortcut-icon shortcut-action-button"
      routerLinkActive="shortcut-active"
      (click)="presentPopover($event)"
    >
      <div class="avatar-outer">
        <ion-img class="avatar" [src]="authService.userData.image | cloudUrl : '50x50'"></ion-img>
      </div>
    </div>
  </div>

  <div class="chat-item" *ngIf="(authService.userAuthed | async) === false">
    <div
      class="shortcut-icon shortcut-action-button icon-primary"
      routerLink="/auth/login"
      placement="right"
      [tooltip]="'title.login' | transloco"
    >
      <ion-icon name="arrow-forward-circle-outline"></ion-icon>
    </div>
  </div>
</div>

<ion-popover [dismissOnSelect]="true" [showBackdrop]="false" #actions side="top" alignment="center">
  <ng-template>
    <div class="side-menu side-menu-dropdown" *ngIf="(authService.userAuthed | async) === true">
      <ui-navigation-root>
        <ui-navigation-element
          iconName="person-circle"
          [url]="'/app/user/' + authService.userData.slug"
        >
          <span transloco="user_options.show_profile"></span>
        </ui-navigation-element>

        <ui-navigation-element iconName="cog" url="/app/settings">
          <span transloco="user_options.settings"></span>
        </ui-navigation-element>

        <ui-navigation-element iconName="log-out" (onClick)="doLogout()">
          <span transloco="logout"></span>
        </ui-navigation-element>
      </ui-navigation-root>
    </div>
  </ng-template>
</ion-popover>
