import { Connector } from './connector';
import { EventSubscriptionCallback, SocketChannel } from './socket-channel';
import { SocketPresenceChannel } from './socket-presence-channel';
import { SocketPrivateChannel } from './socket-private-channel';
import { io, Socket } from 'socket.io-client';

/**
 * This class creates a connnector to a Socket.io server.
 */
export class SocketConnector extends Connector {
  /**
   * The Socket.io connection instance.
   */
  socket: Socket | null = null;

  /**
   * All of the subscribed channel names.
   */
  channels: { [name: string]: SocketChannel } = {};

  /**
   * Create a fresh Socket.io connection.
   */
  connect(): Socket | null {
    let io = this.getSocketIO();

    this.socket = io(this.options.host, this.options);

    this.socket?.on('reconnect', () => {
      Object.values(this.channels).forEach(channel => {
        channel.subscribe();
      });
    });

    return this.socket;
  }

  /**
   * Get socket.io module from global scope or options.
   */
  getSocketIO(): any {
    if (typeof this.options.client !== 'undefined') {
      return this.options.client;
    }

    if (typeof io !== 'undefined') {
      return io;
    }

    throw new Error('Socket.io client not found. Should be globally available or passed via options.client');
  }

  /**
   * Listen for an event on a channel instance.
   */
  listen(name: string, event: string, callback: EventSubscriptionCallback, opt: any): SocketChannel {
    return this.channel(name).listen(event, callback, opt);
  }

  /**
   * Get a channel instance by name.
   */
  channel(name: string): SocketChannel {
    if (!this.channels[name]) {
      this.channels[name] = new SocketChannel(this.socket, name, this.options);
    }

    return this.channels[name];
  }

  /**
   * Get a private channel instance by name.
   */
  privateChannel(name: string): SocketPrivateChannel {
    if (!this.channels['private-' + name]) {
      this.channels['private-' + name] = new SocketPrivateChannel(this.socket, 'private-' + name, this.options);
    }

    return this.channels['private-' + name] as SocketPrivateChannel;
  }

  /**
   * Get a presence channel instance by name.
   */
  presenceChannel(name: string): SocketPresenceChannel {
    if (!this.channels['presence-' + name]) {
      this.channels['presence-' + name] = new SocketPresenceChannel(this.socket, 'presence-' + name, this.options);
    }

    return this.channels['presence-' + name] as SocketPresenceChannel;
  }

  /**
   * Leave the given channel, as well as its private and presence variants.
   */
  leave(name: string): void {
    let channels = [name, 'private-' + name, 'presence-' + name];

    channels.forEach(name => {
      this.leaveChannel(name);
    });
  }

  /**
   * Leave the given channel.
   */
  leaveChannel(name: string): void {
    if (this.channels[name]) {
      this.channels[name].unsubscribe();

      delete this.channels[name];
    }
  }

  /**
   * Get the socket ID for the connection.
   */
  socketId(): string | null {
    if (this.socket == null) {
      return null;
    }

    return this.socket.id;
  }

  /**
   * Disconnect Socketio connection.
   */
  disconnect(): void {
    this.socket?.disconnect();
  }
}
