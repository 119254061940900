import { BehaviorSubject, Observable, Subscription, from, of } from 'rxjs';
import {
  bufferCount,
  concatMap,
  delay,
  distinctUntilChanged,
  filter,
  map,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { urlJoin } from 'src/app/shared/libs/urljoin';
import { RestService } from 'src/app/shared/services/rest/rest.service';

export const resize_array_right = (array: any[], length: number, fill_with: any) =>
  array.concat(new Array(length).fill(fill_with)).slice(0, length);
export const resize_array_left = (array: any[], length: number, fill_with: any) =>
  new Array(length).fill(fill_with).concat(array).slice(-length);

const log = (message: string) => tap(data => console.log(message, data));
export class InfiniteScrollDataAdapter {
  private dataURL: string = '';
  private _offset$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private cache: any[] = [];
  private _dataCache$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(this.cache);
  public prefetchEnabled = true;
  public delay = 100;
  public delayBufferCount = 5;
  public delayBetweenRequest = 150;
  public totalPage = 1;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isOnEnd$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private restService: RestService) {}

  private offsetDistrinct$ = this._offset$.pipe(
    map(_ => _),
    filter(df => df > 0),
    distinctUntilChanged(),
    tap(_ => {
      if (_ <= 1) {
        this.cache = [];
        this._dataCache$.next(this.cache);
      }
    })
  );

  onDataUrl$: Observable<any[]> = this.offsetDistrinct$.pipe(
    tap(_ => this.isLoading$.next(true)),
    concatMap(offset => {
      const newUrl = urlJoin(this.dataURL, '?page=' + offset);
      return this.restService
        .getList(newUrl)
        .pipe(delay(offset <= 1 ? 0 : this.delayBetweenRequest));
    }),
    takeWhile(_ => {
      return _.currentPage <= _.lastPage;
    }),
    tap(_ => {
      const nextSize = Math.min(_.total, _.perPage * _.currentPage);
      this.cache = resize_array_right(this.cache, nextSize, null);
      this._dataCache$.next(this.cache);

      if (_.lastPage == _.currentPage) {
        this.isOnEnd$.next(true);
      }
    }),
    tap(_ => this.isLoading$.next(false)),
    map(_ => {
      const items = (_.items as any[]) || [];
      return items;
    }),
    concatMap(_ =>
      from(_).pipe(
        bufferCount(this.delayBufferCount),
        concatMap(item => of(item).pipe(delay(this.delay)))
      )
    ),
    map(_ => {
      for (const item of _) {
        const overrideIndex = this.cache.findIndex(df => df == null);
        if (overrideIndex > -1) {
          this.cache[overrideIndex] = item;
        }
      }
      return this.cache;
    })
  );

  getSource$ = this._dataCache$.pipe();
  oldSub: Subscription | undefined = undefined;

  setUrl(url: string, limit: number) {
    this.oldSub?.unsubscribe();
    this.isOnEnd$.next(false);
    this.dataURL = url + '?limit=' + limit;
    this.totalPage = 1;
    this._offset$.next(1);
    this.oldSub = this.onDataUrl$.subscribe(df => {
      this._dataCache$.next(df);
    });
  }

  loadNext() {
    this._offset$.next(this._offset$.getValue() + 1);
  }
}
