import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { MediaModule } from 'src/app/shared/directives/media/media.module';
import { ScrollbarModule } from 'src/app/shared/directives/scrollbar/scrollbar.module';
import { TooltipModule } from 'src/app/shared/directives/tooltip/tooltip.module';
import { UserLikeModule } from 'src/app/shared/directives/user/user.like.module';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { FormBuilderModule } from 'src/app/ui/form-builder/form-builder.module';
import { UIModule } from 'src/app/ui/ui.module';
import { StreamPrepareModalComponent } from './stream-prepare-modal.component';
import { StreamPrepareSettingsComponent } from './stream-prepare-settings/stream-prepare-settings.component';

@NgModule({
  imports: [
    IonicModule,
    ReactiveFormsModule,
    ScrollbarModule,
    TooltipModule,
    UserLikeModule,
    FormsModule,
    CommonModule,
    PipesModule,
    FormBuilderModule,
    MediaModule,
    UIModule,
    RouterModule,
    TranslocoModule,
  ],
  declarations: [StreamPrepareModalComponent, StreamPrepareSettingsComponent],
  exports: [StreamPrepareModalComponent],
})
export class StreamPrepareModule {}
