import { NgModule } from '@angular/core';
import { WindowSizeDirective } from './window-size.directive';

@NgModule({
  declarations: [WindowSizeDirective],
  imports: [],
  providers: [],
  exports: [WindowSizeDirective],
})
export class WindowSizeModule {}
