<ng-template #defaultTemplate> </ng-template>
<ng-content></ng-content>

<app-no-content-found
  *ngIf="(itemResults$?.getSource$ | async)?.length === 0 && !(itemResults$?.isLoading$ | async)"
></app-no-content-found>

<div
  #container
  [ngClass]="mode == 'list' ? 'item-list' : 'item-grid'"
  [ngStyle]="{
    'grid-gap': gap + 'px',
    'grid-row-gap': rowGap + 'px',
    'grid-template-columns': cssGrid$ | async
  }"
>
  <div
    class="fade-in"
    [ngClass]="mode == 'list' ? 'list-item' : 'grid-item'"
    *ngFor="let item of itemResults$?.getSource$ | async; trackBy: trackItems"
  >
    <ng-container
      [ngTemplateOutlet]="dataTemplate || defaultTemplate"
      [ngTemplateOutletContext]="{ $implicit: item, mode: mode, data: extraData }"
    >
    </ng-container>
  </div>
</div>

<div
  class="loader"
  *ngIf="(itemResults$?.isLoading$ | async) || (itemResults$?.isOnEnd$ | async) == false"
>
  <ion-spinner name="crescent"></ion-spinner>
  <span [transloco]="'loading.more.data'"></span>
</div>
<div
  infiniteScroll
  [infiniteScrollDisabled]="
    (itemResults$?.isLoading$ | async) || (itemResults$?.isOnEnd$ | async) || false
  "
  [infiniteScrollDistance]="1"
  [infiniteScrollThrottle]="300"
  [infiniteScrollContainer]="scrollElement"
  [fromRoot]="true"
  *ngIf="isBrowser"
  (scrolled)="loadNext()"
></div>
