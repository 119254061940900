import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { from, of } from 'rxjs';
import { concatMap, delay, map } from 'rxjs/operators';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { ResourceList } from '../../../shared/libs/resources';
import { RestService } from '../../../shared/services/rest/rest.service';
import { ToastService } from '../../../shared/services/toast.service';
import { ChatService } from '../../chat/services/chat.service';
import { Friendship, FriendshipState, OnlineState } from '../models/friendship';

export interface UserAction {
  name: string;
  icon?: string;
  callback: Function;
}

@Injectable({
  providedIn: 'root',
})
export class FriendService implements OnDestroy {
  public friends: ResourceList<Friendship> = new ResourceList<Friendship>();

  isBrowser = false;

  constructor(
    private restService: RestService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private authService: AuthStateService,
    private translate: TranslocoService,
    private chatService: ChatService,
    public toastService: ToastService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.authService.userAuthed.subscribe(result => {
      if (result === true) {
        this.fetchFriendList();
      } else {
        this.destroyFiendList();
      }
    });

    if (this.isBrowser) {
      this.authService.userConnected.subscribe(result => {
        if (result === true) {
          this.subscribeSocket();
        } else {
          this.unsubscribeSocket();
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.isBrowser) {
      this.unsubscribeSocket();
      this.destroyFiendList();
    }
  }

  /**
   * Get the current state of a friendship, in case it exist
   *
   * @param userId
   * @returns
   */
  getState(userId: number) {
    if (this.authService.isLoggedIn && userId === this.authService.userData.id) {
      return FriendshipState.OwnProfile;
    }

    const value = this.friends
      .getList()
      .filter(df => df.userData.id === userId)
      .first();
    if (value == null) {
      return FriendshipState.None;
    } else {
      return value.state;
    }
  }

  /**
   * Get the current state of a friendship, in case it exist
   *
   * @param userId
   * @returns
   */
  getActions(userId: number) {
    var actions: UserAction[] = [];
    var state = this.getState(userId);

    if (state === FriendshipState.None) {
      actions.push({
        name: this.translate.translate('user.friends.invite'),
        icon: 'person-add',
        callback: () => this.invite(userId),
      });
    } else if (state === FriendshipState.Pending) {
      actions.push({
        icon: 'checkmark-circle',
        name: this.translate.translate('user.friends.accept'),
        callback: () => this.accept(userId),
      });
      actions.push({
        icon: 'close-circle',
        name: this.translate.translate('user.friends.decline'),
        callback: () => this.decline(userId),
      });
    } else if (state === FriendshipState.PendingSend) {
      actions.push({
        icon: 'close-circle',
        name: this.translate.translate('user.friends.revoke'),
        callback: () => this.decline(userId),
      });
    } else if (state === FriendshipState.Accepted) {
      actions.push({
        name: this.translate.translate('user.friends.message'),
        icon: 'chatbox',
        callback: () => {
          this.chatService.sendDM(userId);
        },
      });
      actions.push({
        icon: 'person-remove',
        name: this.translate.translate('user.friends.delete'),
        callback: () => this.delete(userId),
      });
    }

    if (state !== FriendshipState.OwnProfile) {
      actions.push({
        name: this.translate.translate('user.friends.block'),
        icon: 'ban',
        callback: () => this.block(userId),
      });
    }

    return actions;
  }

  /**
   * Get the current state of a friendship, in case it exist
   *
   * @param userId
   * @returns
   */
  getOnlineState(userId: number): OnlineState {
    if (userId == this.authService.userData.id) {
      return this.authService.myOnlineState;
    }

    const value = this.friends
      .getList()
      .filter(df => df.userData.id === userId)
      .first();

    if (value == null) {
      return OnlineState.Offline;
    } else {
      return value.onlineState;
    }
  }

  /** Check is user in friendlist */
  hasFriend(userId: number) {
    const value = this.friends
      .getList()
      .filter(df => df.userData.id === userId)
      .first();

    return value != null;
  }

  /**
   * Invite a user as an friend
   *
   * @param userId
   */
  invite(userId: number) {
    this.doRestCall('friends/invite/' + userId);
  }

  /**
   * Accept a friendship request
   *
   * @param userId
   */
  accept(userId: number) {
    this.doRestCall('friends/accept/' + userId);
  }

  /**
   * Decline a friendship request
   *
   * @param userId
   */
  decline(userId: number) {
    this.doRestCall('friends/remove/' + userId);
  }

  /**
   * Abort a friendship request, in case that user is the requester
   *
   * @param userId
   */
  abortRequest(userId: number) {
    this.doRestCall('friends/remove/' + userId);
  }

  /**
   * Delete an existing friendship
   *
   * @param userId
   */
  delete(userId: number) {
    this.doRestCall('friends/remove/' + userId);
  }

  /**
   * Block a user for lifetime
   *
   * @param userId
   */
  block(userId: number) {
    this.doRestCall('friends/block/' + userId);
  }

  /**
   * Unblock a user
   *
   * @param userId
   */
  unblock(userId: number) {
    this.doRestCall('friends/unblock/' + userId);
  }

  /**
   * Rest call helper
   *
   * @param url
   */
  private doRestCall(url: string) {
    this.restService.get(url).subscribe(
      result => {
        this.toastService.createSuccessToast(result.message);
      },
      error => {
        this.toastService.createErrorToast(error.message);
      }
    );
  }

  /**
   * Subscribe sockets
   *
   */
  private subscribeSocket() {
    if (this.authService.isConnectionActive()) {
      this.authService.listenOnAuthChannel('UpdateFriendship', this.updateFriendship, this);
      this.authService.listenOnAuthChannel('DeleteFriendship', this.deleteFriendship, this);
      this.authService.listenOnAuthChannel('UpdateOnlineState', this.UpdateOnlineState, this);
    }
  }

  /**
   * @todo: Unsubscribe notifications
   *
   */
  private unsubscribeSocket() {
    this.authService.unlistenAuthChannel('UpdateFriendship', this.updateFriendship);
    this.authService.unlistenAuthChannel('DeleteFriendship', this.deleteFriendship);
    this.authService.unlistenAuthChannel('UpdateOnlineState', this.UpdateOnlineState);
  }

  private UpdateOnlineState(df: any, dt: any) {
    const service: FriendService = dt;
    const friendshipId = df.data.friendship;
    const userState = df.data.state;

    var friendship = service.friends.get(friendshipId);
    if (friendship != null) {
      friendship.setOnlineState(userState);
      service.friends.updateOrInsert(friendship);
    }
  }

  private updateFriendship(df: any, dt: any) {
    const service: FriendService = dt;

    const data = new Friendship(
      df.data.friendship_id,
      df.data.onlineState,
      df.data.userData,
      df.data.state,
      Boolean(df.data.isRequest),
      df.data.requested_at
    );

    service.friends.updateOrInsert(data);
  }

  private deleteFriendship(e: any, dt: any) {
    const service: FriendService = dt;
    service.friends.delete(e.data);
  }

  get requests() {
    return this.friends.values.pipe(
      map(items =>
        items
          .filter(item => item.state === FriendshipState.Pending && item.userData != null)
          .sort((a, b) =>
            a.userData.name.toLowerCase().localeCompare(b.userData.name.toLowerCase())
          )
          .sort((a, b) => b.timestamp - a.timestamp)
      )
    );
  }

  get pendingsRequests() {
    return this.friends.values.pipe(
      map(items =>
        items
          .filter(item => item.state === FriendshipState.PendingSend && item.userData != null)
          .sort((a, b) =>
            a.userData.name.toLowerCase().localeCompare(b.userData.name.toLowerCase())
          )
          .sort((a, b) => b.timestamp - a.timestamp)
      )
    );
  }

  get allFriends() {
    return this.friends.values.pipe(
      map(items =>
        items
          .filter(item => item.state === FriendshipState.Accepted && item.userData != null)
          .sort((a, b) =>
            a.userData.name.toLowerCase().localeCompare(b.userData.name.toLowerCase())
          )
      )
    );
  }

  /**
   * Fetch the current friend list after user is logged in
   *
   * @returns
   */
  private fetchFriendList() {
    this.destroyFiendList();
    this.restService.getList('friends').subscribe(
      result => {
        const results = result as any[];

        from(results)
          .pipe(concatMap(df => of(df).pipe(delay(this.isBrowser ? 25 : 0))))
          .subscribe(
            (df: any) => {
              this.friends.add(
                new Friendship(
                  df.friendship_id,
                  df.onlineState,
                  df.userData,
                  df.state,
                  Boolean(df.isRequest),
                  df.requested_at,
                  df.timestamp
                )
              );
            },
            () => {},
            () => {}
          );
      },
      error => {
        this.destroyFiendList();
      }
    );
  }

  /**
   * Destroy friendship after user is logged out
   */
  private destroyFiendList() {
    this.friends.reset();
  }
}
