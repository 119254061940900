import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { LauncherDownloadModalComponent } from './launcher-download-modal.component';

@NgModule({
  imports: [IonicModule, CommonModule, TranslocoModule],
  declarations: [LauncherDownloadModalComponent],
  exports: [LauncherDownloadModalComponent],
})
export class DownloadLauncherModule {}
