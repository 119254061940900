import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { BehaviorSubject, Subscriber, Subscription } from 'rxjs';
import { AppService } from '../services/app.service';
import { RestService } from '../services/rest/rest.service';
import { ToastService } from '../services/toast.service';
import { CoreTopLevelPage } from './core-top-level-page';

/**
 * @description Main Page (Top level) with Rest
 * @author Stefan Boronczyk <stefan@strikd.com>
 * @template T
 */
@Component({ template: '' })
export abstract class TopLevelRestPage<T>
  extends CoreTopLevelPage
  implements OnInit, OnDestroy, AfterViewInit
{
  protected childDataLoaded = false;
  protected rest: RestService;
  protected toast: ToastService;
  protected appService: AppService;
  private dataSub: Subscription | null = null;

  constructor(protected injector: Injector) {
    super(injector);
    this.rest = injector.get(RestService);
    this.appService = injector.get(AppService);
    this.toast = injector.get(ToastService);
  }

  private isAlreadyInit = false;
  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.isAlreadyInit = true;
  }
  ngOnInit(): void {
    this.data = this.route.snapshot.data.data as T;
    this.updateMeta();
    super.ngOnInit();

    if (this.isBrowser) {
      this.dataSub = this.route.data.subscribe(data => {
        if (this.isAlreadyInit) {
          this.data = data.data as T;
          this.update();
        }
      });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.dataSub?.unsubscribe();
    this.childDataLoaded = false;
    this.isAlreadyInit = false;
  }
}
