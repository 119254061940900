import { Directive, Injectable, forwardRef, inject } from '@angular/core';
import {
  ControlValueAccessor,
  FormControlDirective,
  FormControlName,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel,
} from '@angular/forms';
export function injectNgControl() {
  const ngControl = inject(NgControl, { self: true, optional: true });

  if (
    ngControl &&
    (ngControl instanceof FormControlDirective ||
      ngControl instanceof FormControlName ||
      ngControl instanceof NgModel)
  ) {
    return ngControl;
  }
  return undefined;
}

@Directive({
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NoopValueAccessorDirective,
    },
  ],
})
export class NoopValueAccessorDirective implements ControlValueAccessor {
  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {}
  registerOnTouched(fn: any): void {}
}

export interface ICoreInput {
  name: string | undefined;
  rootId: string | undefined;
}

@Injectable()
export abstract class UICoreInputComponent {}

export const UICoreInputProvider = <T extends ICoreInput>(component: new () => T) => {
  return { provide: UICoreInputComponent, useExisting: forwardRef(() => component) };
};
