import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonModal, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { LauncherDownloadModalComponent } from 'src/app/shared/components/launcher-download-modal/launcher-download-modal.component';
import { DesktopService } from 'src/app/shared/services/electron.service';
import { BasketService } from '../../basket/services/basket.service';
import { ChatService } from '../../chat/services/chat.service';
import { FriendService } from '../../friends/services/friends.service';
import { NotificationService } from '../../notifications/services/notification.service';

@Component({
  selector: 'app-chat-navigation-icons',
  templateUrl: './chat-navigation-icons.component.html',
  styleUrls: ['./chat-navigation-icons.component.scss'],
})
export class ChatNavigationIconsComponent implements OnInit, OnDestroy {
  public unreadChats: number = 0;
  public unreadFeed: number = 0;
  public friendRequests: number = 0;
  private friendSub: Subscription | null = null;
  @ViewChild('modal', { static: true }) modal: IonModal | undefined = undefined;

  private chatSub: Subscription | null = null;
  constructor(
    public authState: AuthStateService,
    public chatservice: ChatService,
    public router: Router,
    public basketService: BasketService,
    public desktopService: DesktopService,
    public notifyService: NotificationService,
    public authService: AuthStateService,
    public modalController: ModalController,
    public friendService: FriendService
  ) {}
  ngOnDestroy(): void {
    this.chatSub?.unsubscribe();
    this.friendSub?.unsubscribe();
  }
  async download(event: any) {
    event.preventDefault();
    event.stopPropagation();
    const modal = await this.modalController.create({
      component: LauncherDownloadModalComponent,
      cssClass: 'auto-height',
    });
    await modal.present();
    await modal.onWillDismiss();
  }
  async showCentre(event: any) {
    event.preventDefault();
    event.stopPropagation();

    await this.modal?.present();
    await this.modal?.onWillDismiss();
  }
  public get basketAmount(): number {
    return BasketService.records.length;
  }
  public get missionsXP(): number {
    return 0;
  }
  ngOnInit(): void {
    this.chatSub = this.chatservice.unreadedChats.subscribe(df => {
      this.unreadChats = df.count();
    });
    this.friendSub = this.friendService.requests.subscribe(df => {
      this.friendRequests = df.count();
    });
  }

  isActive() {
    return this.router.url.startsWith('/app/chat');
  }

  isDownloadsActive() {
    return this.router.url.startsWith('/app/library');
  }

  isFriendsActive() {
    return this.router.url.startsWith('/app/friends');
  }

  isSettingsActive() {
    return this.router.url.startsWith('/app/settings');
  }

  isNotificationsActive() {
    return this.router.url.startsWith('/app/notifications');
  }

  isBasketActive() {
    return this.router.url.startsWith('/app/basket');
  }
  isMissionsActive() {
    return this.router.url.startsWith('/app/missions');
  }
  i2BSBActive() {
    return this.router.url.startsWith('/app/b2b');
  }

  isFeedActive() {
    return this.router.url.startsWith('/app/dashboard');
  }

  routeToChat(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.chatservice.routeToPreviousChat();
  }
}
