import { Pipe, PipeTransform } from '@angular/core';
import { urlJoin } from 'src/app/shared/libs/urljoin';
import { environment } from 'src/environments/environment';
import isURL from 'validator/lib/isURL';

@Pipe({
  name: 'cloudUrl',
  pure: false,
})
export class CloudUriPipe implements PipeTransform {
  constructor() {}

  transform(url: string, key: string | null = null): null | string {
    if (url == null && url == undefined) {
      return null;
    }

    if (url != null && url != undefined && url.length > 0 && url.startsWith('data:')) {
      return url;
    } else if (isURL(url)) {
      if (key != null) {
        const lastUrlPart = url.substring(url.lastIndexOf('/') + 1);
        return url.replace(lastUrlPart, key + '-' + lastUrlPart);
      } else {
        return url;
      }
    } else {
      if (key != null) {
        const lastUrlPart = url.substring(url.lastIndexOf('/') + 1);
        return environment.mediaUrl + url.replace(lastUrlPart, key + '-' + lastUrlPart);
      } else {
        return environment.mediaUrl + url;
      }
    }
  }
}
