import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UILazyLoadModule } from '../lazy-load/lazy-load.module';
import { UINavigationContextDirective } from './ui-navigation-context.directive';
import { UiNavigationElementComponent } from './ui-navigation-element/ui-navigation-element.component';
import { UiNavigationHeaderComponent } from './ui-navigation-header/ui-navigation-header.component';
import { UiNavigationRootComponent } from './ui-navigation-root/ui-navigation-root.component';
import { UIPopoverModule } from '../ui-popover/ui-popover.module';
import { DEFAULT_TRANSITION } from '../ui-popover/tokens';
import { UiNavigationSpacerComponent } from './ui-navigation-spacer/ui-navigation-spacer.component';

@NgModule({
  imports: [CommonModule, UIPopoverModule, RouterModule, IonicModule, UILazyLoadModule],
  providers: [{ provide: DEFAULT_TRANSITION, useValue: '0ms ease' }],
  declarations: [
    UiNavigationElementComponent,
    UiNavigationRootComponent,
    UiNavigationHeaderComponent,
    UINavigationContextDirective,
    UiNavigationSpacerComponent,
  ],
  exports: [
    UiNavigationElementComponent,
    UiNavigationRootComponent,
    UiNavigationHeaderComponent,
    UiNavigationSpacerComponent,
    UINavigationContextDirective,
  ],
})
export class UINavigationModule {}
