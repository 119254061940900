import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Injector,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Params, Router, RouterOutlet } from '@angular/router';
import { NavController } from '@ionic/angular';
import { MenuDetails } from '../models/menu/menu.records';
import { RestService } from '../services/rest/rest.service';
import { TopLevelPage } from './top-level-page';
import { UIPage } from './ui-page';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Component({ template: '' })
export abstract class CoreSubLevelPage extends UIPage implements OnInit {
  public abstract onActivation(): void;
  public topLevel: TopLevelPage | null = null;

  protected router: Router;
  protected nav: NavController;
  protected rest: RestService;
  protected translate: TranslocoService;

  public data: any = null;

  public get menuDetailsSub(): Observable<MenuDetails> | null {
    if (this.topLevel == null) return null;
    return this.topLevel?.menuDetailsSub;
  }

  public get mainData(): any {
    return this.topLevel?.data;
  }

  public set mainData(value: any) {
    if (this.topLevel != null) {
      this.topLevel.data = value;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public isBrowser = false;
  receiveElement(element: HTMLElement | null = null) {}

  constructor(protected injector: Injector) {
    super(injector);
    this.router = injector.get(Router);
    this.nav = injector.get(NavController);
    this.rest = injector.get(RestService);
    this.translate = injector.get(TranslocoService);
    let browserObject = injector.get(PLATFORM_ID);

    this.isBrowser = isPlatformBrowser(browserObject);
  }
}
