import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { PageSearchComponent } from '../b2c/layout/page-search/page-search.component';
import { HoverModule } from '../b2c/layout/title/hover.module.ts/hover.module';
import { TitleComponent } from '../b2c/layout/title/title.component';
import { DownloadLauncherModule } from '../shared/components/launcher-download-modal/launcher-download-modal.module';
import { MediaModule } from '../shared/directives/media/media.module';
import { ScrollbarModule } from '../shared/directives/scrollbar/scrollbar.module';
import { TooltipModule } from '../shared/directives/tooltip/tooltip.module';
import { VisibilityModule } from '../shared/directives/visibility/visibility.module';
import { WindowSizeModule } from '../shared/directives/window-size/window-size.module';
import { ActionMenuSheetComponent } from './action-menu/action-menu-sheet/action-menu-sheet.component';
import { ActionMenuComponent } from './action-menu/action-menu.component';
import {
  AppUiColumnGridComponent,
  AppUiColumnLeftComponent,
  AppUiColumnRightComponent,
} from './app-ui-column-grid/app-ui-column-grid.component';
import { UIBadgeModule } from './badge/ui-badge.module';
import { BgCanvasComponent } from './bg-canvas/bg-canvas.component';
import { DisconnectedLoaderComponent } from './disconnected-loader/disconnected-loader.component';
import { FiledropComponent } from './drag-n-drop/filedrop/filedrop.component';
import { FiledropDirective } from './drag-n-drop/filedrop/filedrop.directive';
import { DrawMoreComponentsComponent } from './draw-more-components/draw-more-components.component';
import { FeatureNotAvaiableComponent } from './feature-not-avaiable/feature-not-avaiable.component';
import { FooterComponent } from './footer/footer.component';
import { FormErrorsComponent } from './form-errors/form-errors.component';
import { UILazyLoadModule } from './lazy-load/lazy-load.module';
import { UINavigationModule } from './navigation/ui-navigation.module';
import { NewsletterFormComponent } from './newsletter-form/newsletter-form.component';
import { NoContentFoundModule } from './no-content-found/no-content-found.module';
import { SearchableOptionComponent } from './searchable-option/searchable-option.component';
import { IonicSelectableModule } from './selectable/ionic-selectable.module';
import { SettingsModalContentComponent } from './settings-modal/settings-modal-content/settings-modal-content.component';
import { SettingsModalMenuComponent } from './settings-modal/settings-modal-menu/settings-modal-menu.component';
import { SettingsModalComponent } from './settings-modal/settings-modal.component';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { UiAlertComponent } from './ui-alert/ui-alert.component';
import { UiContentPageComponent } from './ui-content-page/ui-content-page.component';
import { UiDesktopMenuComponent } from './ui-desktop-menu/ui-desktop-menu.component';
import { FilterComponentComponent } from './ui-header/filter-component/filter-component.component';
import { UiHeaderComponent } from './ui-header/ui-header.component';
import { UIListModule } from './ui-list/ui-list.module';
import { UIPopoverModule } from './ui-popover/ui-popover.module';
import { UISearchbarModule } from './ui-searchbar/ui-searchbar.module';
import { UiSideMenuComponent } from './ui-side-menu/ui-side-menu.component';
import { UiTitleComponent } from './ui-title/ui-title.component';
import { UserBackgroundComponent } from './user-background/user-background.component';
import { UISectionModule } from './section/ui-section.module';
import { UITileModule } from './tile/ui-tile.module';
import { UIFormModule } from './form/ui-form.module';

@NgModule({
  imports: [
    IonicModule,
    RouterModule,
    NoContentFoundModule,
    UISearchbarModule,
    ScrollbarModule,
    ScrollbarModule,
    WindowSizeModule,
    FormsModule,
    HoverModule,
    MediaModule,
    UIPopoverModule,
    NoContentFoundModule,
    UINavigationModule,
    VisibilityModule,
    UIListModule,
    CommonModule,
    DragDropModule,
    ReactiveFormsModule,
    TranslocoModule,
    TooltipModule,
    UITileModule,
    IonicSelectableModule,
    DownloadLauncherModule,
    UILazyLoadModule,
    UISectionModule,
    UIBadgeModule,
    UIFormModule,
  ],
  declarations: [
    FiledropDirective,
    ShowHidePasswordComponent,
    FormErrorsComponent,
    FiledropComponent,
    UserBackgroundComponent,
    DisconnectedLoaderComponent,
    NewsletterFormComponent,
    ActionMenuComponent,
    ActionMenuSheetComponent,
    FooterComponent,
    SearchableOptionComponent,
    FeatureNotAvaiableComponent,
    UiHeaderComponent,
    FilterComponentComponent,
    DrawMoreComponentsComponent,
    UiDesktopMenuComponent,
    UiSideMenuComponent,
    SettingsModalComponent,
    SettingsModalMenuComponent,
    SettingsModalContentComponent,
    UiAlertComponent,
    AppUiColumnGridComponent,
    AppUiColumnLeftComponent,
    AppUiColumnRightComponent,
    UiContentPageComponent,
    TitleComponent,
    PageSearchComponent,
    UiTitleComponent,
    BgCanvasComponent,
  ],
  exports: [
    ActionMenuComponent,
    SearchableOptionComponent,
    UserBackgroundComponent,
    UITileModule,
    UISectionModule,
    AppUiColumnGridComponent,
    AppUiColumnLeftComponent,
    TitleComponent,
    AppUiColumnRightComponent,
    DisconnectedLoaderComponent,
    FiledropComponent,
    UiDesktopMenuComponent,
    FooterComponent,
    FormErrorsComponent,
    FeatureNotAvaiableComponent,
    UiHeaderComponent,
    ShowHidePasswordComponent,
    DrawMoreComponentsComponent,
    SettingsModalComponent,
    SettingsModalContentComponent,
    SettingsModalMenuComponent,
    UiSideMenuComponent,
    UiAlertComponent,
    FilterComponentComponent,
    BgCanvasComponent,
    IonicModule,
    UiTitleComponent,
    NewsletterFormComponent,
    FormsModule,
    UILazyLoadModule,
    UiContentPageComponent,
    UIPopoverModule,
    UINavigationModule,
    UIBadgeModule,
    UIListModule,
    UIFormModule,
  ],
})
export class UIModule {}
