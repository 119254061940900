import {
  AfterContentChecked,
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UIListItemActionDirective } from './ui-list-item-action.directive';
import { UINavigationContextDirective } from '../../navigation/ui-navigation-context.directive';
import { SatPopover } from '../../ui-popover/popover.component';

@Component({
  selector: 'ui-list-item',
  templateUrl: './ui-list-item.component.html',
  styleUrls: ['./ui-list-item.component.scss'],
})
export class UiListItemComponent implements AfterContentInit, AfterContentChecked, OnDestroy {
  @Input() isLast = false;
  @Input() fullHeight = false;

  @Input() url: string | undefined = undefined;
  @Input() title: string | undefined | null = undefined;
  @Input() thumbnail: string | undefined | null = undefined;
  @Input() thumbnailWidth: number = 50;
  @Input() thumbnailHeight: number = 50;
  @Input() thumbnailBorderRadius: string = '50%';
  @Input() hasActions = false;
  @Input() hasContextMenu = false;
  @ViewChild('popover', { static: false }) contextMenu: SatPopover | undefined = undefined;
  @Output() onClick = new EventEmitter<MouseEvent>();

  @HostBinding('class.full-height') get valid() {
    return this.fullHeight;
  }

  @ContentChildren(UIListItemActionDirective, {
    emitDistinctChangesOnly: true,
    descendants: true,
  })
  actionChildrens: QueryList<UIListItemActionDirective> | undefined;

  @ContentChildren(UINavigationContextDirective, {
    emitDistinctChangesOnly: true,
    descendants: true,
  })
  contextChildrens: QueryList<UINavigationContextDirective> | undefined;

  private sub: Subscription | undefined = undefined;
  private sub2: Subscription | undefined = undefined;

  constructor(private ref: ChangeDetectorRef) {}

  async openContextMenu(event: any) {
    if (!this.hasContextMenu) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    await this.contextMenu?.toggle();
  }

  @ViewChild('actionChilds', { static: false }) actionChilds:
    | ElementRef<HTMLDivElement>
    | undefined = undefined;

  @ViewChild('contextChilds', { static: false }) contextChilds:
    | ElementRef<HTMLDivElement>
    | undefined = undefined;

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.sub2?.unsubscribe();
  }

  hasContextItems = false;

  ngAfterContentChecked() {
    this.ref.detectChanges();

    if (this.actionChildrens) {
      this.actionChildrens?.forEach(_ => {
        this.detectChanges([_]);
      });
    }
    if (this.contextChildrens) {
      this.contextChildrens?.forEach(_ => {
        this.detectChangesContext([_]);
      });
    }
  }

  ngAfterContentInit(): void {
    this.sub = this.actionChildrens?.changes.subscribe(_ => {
      this.detectChanges(_.toArray());
    });
    this.sub2 = this.contextChildrens?.changes.subscribe(_ => {
      this.detectChangesContext(_.toArray());
    });
  }

  detectChanges(items: UIListItemActionDirective[]) {
    if (this.actionChilds) {
      for (let item of items) {
        if (item.isDirty) {
          item.moveToNewNode(this.actionChilds.nativeElement);
        }
      }
    }
  }

  detectChangesContext(items: UINavigationContextDirective[]) {
    if (this.contextChilds) {
      this.hasContextItems = items.length > 0;
      for (let item of items) {
        if (item.isDirty) {
          item.moveToNewNode(this.contextChilds.nativeElement);
        }
      }
    }
  }
}
