import { FormBuilderOptions } from 'src/app/ui/form-builder/form-builder-options';

export var OnBoardingForm: FormBuilderOptions = {
  tabs: {
    step1: {
      title: 'onboarding.step1.title',
      description: 'onboarding.step1.desc',
      sections: {
        general: {
          inputs: {
            type: {
              required: true,
              type: 'select-box',
              multipleSelection: true,
              boxOptions: {
                user: {
                  title: 'onboarding.types.user.title',
                  icon: 'game-controller',
                  iconColor: 'primary',
                  cssExtra: 'centered',
                  desc: 'onboarding.types.user.desc',
                },
                supporter: {
                  title: 'onboarding.types.supporter.title',
                  icon: 'heart-circle',
                  cssExtra: 'centered',
                  iconColor: 'danger',
                  desc: 'onboarding.types.supporter.desc',
                },
                artist: {
                  title: 'onboarding.types.artist.title',
                  icon: 'brush',
                  cssExtra: 'centered',
                  iconColor: 'warning',
                  desc: 'onboarding.types.artist.desc',
                },
              },
            },
          },
        },
      },
    },
    step2: {
      title: 'onboarding.step2.title',
      description: 'onboarding.step2.desc',
      sections: {
        general: {
          inputs: {
            games: {
              required: false,
              type: 'data-view',
              multipleSelection: true,
              gridData: {
                dataUrl: 'realms/search?sortBy=popular',
                searchUrl: 'realms/search',
              },
            },
          },
        },
      },
    },
    step3: {
      title: 'onboarding.step3.title',
      description: 'onboarding.step3.desc',
      sections: {
        general: {
          inputs: {
            image: {
              type: 'avatar',
              placeholder: 'placeholder/no-avatar.webp',
              centered: true,
            },
          },
        },
      },
    },

    step4: {
      title: 'onboarding.step4.title',
      description: 'onboarding.step4.desc',
      sections: {
        general: {
          inputs: {
            connect: {
              type: 'list-view',
              multipleSelection: true,
              boxOptions: {
                steam: {
                  title: 'onboarding.connect.steam',
                  src: './assets/os/steam.svg',
                  iconColor: 'steam',
                },
              },
            },
          },
        },
      },
    },
  },
};
