<ui-list-item
  [thumbnailWidth]="35"
  [thumbnailHeight]="35"
  [thumbnail]="item.image | cloudUrl : '50x50'"
  [title]="item.name"
  [isLast]="last"
  [hasActions]="true"
>
  <span uiListItemAction>
    <ion-checkbox
      mode="ios"
      [disabled]="isDisabled"
      (ionChange)="onChange($event)"
      checked="false"
    ></ion-checkbox>
  </span>
</ui-list-item>
