import { DOCUMENT, isPlatformBrowser, registerLocaleData } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import moment from 'moment-timezone';
import { languages } from 'src/app/shared/db/languages';
import { environment } from 'src/environments/environment';
import { LanguageItem } from '../../models/lang/lang.model';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { Observable } from 'rxjs';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  isBrowser: any = false;
  languages: LanguageItem[] = languages as any[] as LanguageItem[];

  constructor(
    private translateService: TranslocoService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId: Object,
    private route: ActivatedRoute,
    private router: Router,
    @Optional()
    @Inject(REQUEST)
    private request: Request
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  get direction(): string {
    return this.translateService.getActiveLang() === 'ar' ? 'rtl' : 'ltr';
  }

  get isRtl(): boolean {
    return this.direction === 'rtl';
  }

  get isLtr(): boolean {
    return !this.isRtl;
  }

  get currentLang(): LanguageItem | null {
    if (this.currentLangSlug) {
      const result = this.languages.find(
        item => this.currentLangSlug.toLocaleLowerCase() === item.iso_code2.toLocaleLowerCase()
      );
      return result == undefined ? null : result;
    } else {
      return null;
    }
  }

  get avaiableLanguages(): LanguageItem[] {
    return this.languages.filter(lang => environment.availableLanguages.includes(lang.iso_code2));
  }

  setLang(value: string): Observable<string> {
    return new Observable(observer => {
      if (!environment.availableLanguages.includes(value)) {
        value = 'en';
      }

      this.translateService.load(value).subscribe(df => {
        this.translateService.setActiveLang(value);
        console.debug('[App][Language] ' + value);

        if (this.isBrowser) {
          moment.locale(value);
        }

        observer.next(value);
        observer.complete();
      });
      if (this.document) {
        this.document.documentElement.lang = value;
      }
    });
  }

  get currentLangSlug(): string {
    if (this.translateService.getActiveLang()) {
      return this.translateService.getActiveLang();
    } else {
      return this.translateService.getDefaultLang();
    }
  }

  public detectLanguage(): Observable<string> {
    return new Observable(observer => {
      let defaultLanguage = 'en';

      if (this.isBrowser) {
        if (navigator != undefined && navigator != null) {
          let foundLanguage =
            navigator.language.length > 2 ? navigator.language.substring(0, 2) : '';
          if (foundLanguage != '' && environment.availableLanguages.includes(foundLanguage)) {
            defaultLanguage = foundLanguage;
          }
        }
      } else {
        const headers = this.request.headers as { [id: string]: any };
        const reqHeader = headers['accept-language'];
        if (reqHeader != null) {
          let foundLanguage = reqHeader.length > 2 ? reqHeader.substring(0, 2) : '';
          if (foundLanguage != '' && environment.availableLanguages.includes(foundLanguage)) {
            defaultLanguage = foundLanguage;
          }
        }
      }

      this.setLang(defaultLanguage).subscribe((res: any) => {
        observer.next(defaultLanguage);
        observer.complete();
      });
    });
  }
}

export function initializeLangService(quoteOFTheDayService: LangService) {
  return () => quoteOFTheDayService.detectLanguage();
}
