<app-form-builder (success)="afterSubmit($event)" #wizard [options]="form" [uri]="'streams/start'">
</app-form-builder>
<ion-grid>
  <ion-row>
    <ion-col size="6" class="flex-left">
      <button
        *ngIf="data"
        transloco="stream.stop"
        class="btn btn-xs btn-solid btn-primary danger"
        (click)="stop()"
      ></button>
    </ion-col>
    <ion-col size="6" class="flex-right">
      <button
        [disabled]="!builder?.isValid()"
        (click)="builder?.submit()"
        class="btn btn-xs btn-solid btn-primary primary"
        [transloco]="this.data ? 'stream.save' : 'stream.start'"
      ></button>
    </ion-col>
  </ion-row>
</ion-grid>
