import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

import { Platform } from '@ionic/angular';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { urlJoin } from 'src/app/shared/libs/urljoin';
import { RestService } from 'src/app/shared/services/rest/rest.service';
import { WindowSizeService } from 'src/app/shared/services/window-size.service';
import { InfiniteScrollDataAdapter } from './adapter';

export const resize_array_right = (array: any[], length: number, fill_with: any) =>
  array.concat(new Array(length).fill(fill_with)).slice(0, length);
export const resize_array_left = (array: any[], length: number, fill_with: any) =>
  new Array(length).fill(fill_with).concat(array).slice(-length);

@Component({
  selector: 'app-infinite-data-grid',
  templateUrl: './infinite-data-grid.component.html',

  styleUrls: ['./infinite-data-grid.component.scss'],
})
export class InfiniteDataGridComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() dataUrl: string | null = null;
  @Input() replaceMode: boolean = false;
  @Input() searchUrl: string | null | undefined = undefined;
  @Input() dataTemplate: TemplateRef<any> | undefined = undefined;
  @Input() extraData: any = null;
  @Input() trackId: string = 'id';
  public searchEnabled: boolean = false;
  searchTerm: string | null = null;
  @Input() isVertical: boolean = false;
  @Input() verticalItems: number = 5;

  @Output() dataAttributes: EventEmitter<any> = new EventEmitter<any>();
  @Output() totalAmountEvent: EventEmitter<number> = new EventEmitter<number>();
  @Input() windowResizeScroll: string | null = null;

  @Input() windowResize: ElementRef<any> | HTMLElement | string = 'body';
  @Input() elementContainer: ElementRef<any> | HTMLElement | null = null;

  public elementsPerRow = 0;

  @Input() sizeXxlValue: string = '1270px';
  @Input() sizeXxl: string | null = '5';
  @Input() sizeXlValue: string = '1140px';
  @Input() sizeXl: string | null = '4';
  @Input() sizeLgValue: string = '960px';
  @Input() sizeLg: string | null = '3';
  @Input() sizeMdValue: string = '720px';
  @Input() sizeMd: string | null = '3';
  @Input() sizeSmValue: string = '540px';
  @Input() sizeSm: string | null = '3';
  @Input() sizeXsValue: string = '380px';
  @Input() sizeXsm: string | null = '2';

  @Input() sizeValue: string = '100%';
  @Input() size: string = '1';

  @Input() minWidth: string | null = null;

  loadNext() {
    this.itemResults$?.loadNext();
  }

  itemResults$: InfiniteScrollDataAdapter | undefined = undefined;

  @Input() public mode = 'item';
  public currentPage = 0;
  public nextItems = 0;
  public nextSearchItems = 0;
  public totalItems = 0;
  public totalSearchItems = 0;
  public currentSearchPage = 0;

  public init = false;
  public initSearch = false;
  public loadedCompleted = false;
  public isLoading = false;
  public loadedSearchCompleted = false;
  @Input() public initValues = 64;
  public totalAmount = 0;

  public additional = {};
  public isBrowser = false;
  protected restService: RestService;
  public templateValues = Array(0);
  public scrollElement: ElementRef<any> | HTMLElement | undefined = undefined;

  public currentWidth = 0;

  items: any[] = [];
  searchItems: any[] = [];

  items$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  searchItems$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private searchRequest = new Subject<string | null>();
  private cssGrid: string =
    'repeat(' + (this.isVertical ? 1 : this.elementsPerRow) + ', minmax(0, 1fr))';
  cssGrid$: BehaviorSubject<string> = new BehaviorSubject(this.cssGrid);

  @Input() gap: number = 12;
  @Input() rowGap: number = 24;
  private searchSub: Subscription | null = null;
  private observer: ResizeObserver | undefined = undefined;

  constructor(
    protected injector: Injector,
    private host: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object,
    private platform: Platform,
    private changeDetectorRef: ChangeDetectorRef,
    private sizeService: WindowSizeService
  ) {
    this.restService = injector.get(RestService);
    this.itemResults$ = new InfiniteScrollDataAdapter(this.restService);
    this.isBrowser = isPlatformBrowser(platformId);
    if (!this.isBrowser) {
      this.doResizeElements(1024);
      //setup the non browser delay
      this.itemResults$.delayBetweenRequest = 0;
      this.itemResults$.delayBufferCount = 10000;
      this.itemResults$.delay = 0;
    }

    this.sizeService.currentWidthSub.subscribe(df => {});
  }

  ngOnDestroy(): void {
    this.searchSub?.unsubscribe();
    this.observer?.unobserve(this.host.nativeElement);
  }

  get windowScrollElement() {
    if (this.elementContainer) {
      return this.elementContainer;
    } else if (typeof this.windowResize === 'string') {
      const scrollTo = this.windowResizeScroll ? this.windowResizeScroll : this.windowResize;
      const element = this.sizeService.getElement(scrollTo);
      if (element != null) {
        return element;
      }
    }
    return undefined;
  }

  get windowWidth() {
    if (this.windowResize instanceof ElementRef) {
      return this.windowResize.nativeElement.width;
    } else if (this.windowResize instanceof HTMLElement) {
      return this.windowResize.clientWidth;
    } else if (typeof this.windowResize === 'string') {
      return this.sizeService.getWidth(this.windowResize);
    }
    return null;
  }

  ngAfterViewInit(): void {}

  search(term: any) {
    this.searchRequest.next(term);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataUrl) {
      if (changes.dataUrl.currentValue != changes.dataUrl.previousValue) {
        this.dataUrl = changes.dataUrl.currentValue;
        if (this.dataUrl != null && this.dataUrl.length > 0) {
          this.itemResults$?.setUrl(this.dataUrl, this.initValues);
        }
      }
    }

    if (this.isBrowser) {
      this.doResizeElements(this.windowWidth);
    } else {
      this.doResizeElements(1024);
    }
  }

  doResizeElements(width: number) {
    this.scrollElement = this.windowScrollElement;
    var possibleItems = parseInt(this.size);

    if (this.minWidth != null) {
      const minwidth = this.getInPixel(width, this.minWidth);

      if (minwidth >= width) {
        possibleItems = 1;
      } else {
        const items = Math.floor(width / minwidth);
        possibleItems = items;
      }
    } else {
      if (this.sizeXxl != null && width >= this.getInPixel(width, this.sizeXxlValue)) {
        possibleItems = parseInt(this.sizeXxl);
      } else if (this.sizeXl != null && width >= this.getInPixel(width, this.sizeXlValue)) {
        possibleItems = parseInt(this.sizeXl);
      } else if (this.sizeLg != null && width >= this.getInPixel(width, this.sizeLgValue)) {
        possibleItems = parseInt(this.sizeLg);
      } else if (this.sizeMd != null && width >= this.getInPixel(width, this.sizeMdValue)) {
        possibleItems = parseInt(this.sizeMd);
      } else if (this.sizeSm != null && width >= this.getInPixel(width, this.sizeSmValue)) {
        possibleItems = parseInt(this.sizeSm);
      } else if (this.sizeXsm != null && width >= this.getInPixel(width, this.sizeXsValue)) {
        possibleItems = parseInt(this.sizeXsm);
      }
    }

    this.currentWidth = width;

    if (this.isVertical) {
      this.elementsPerRow = this.verticalItems;
    } else {
      this.elementsPerRow = possibleItems;
    }

    const cssGrid = 'repeat(' + (this.isVertical ? 1 : this.elementsPerRow) + ', minmax(0, 1fr))';
    if (cssGrid != this.cssGrid) {
      this.cssGrid = cssGrid;
      this.cssGrid$.next(this.cssGrid);
    }
  }
  getInPixel(valueMax: number, value: string): number {
    if (value.includes('px')) {
      return parseInt(value.replace('px', ''));
    } else if (value.includes('%')) {
      let percent = parseInt(value.replace('%', '')) / 100;

      return valueMax * percent;
    } else {
      return parseInt(value);
    }
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.observer = new ResizeObserver(entries => {
        this.doResizeElements(this.windowWidth);
      });
      this.observer.observe(this.host.nativeElement);
    } else {
      this.doResizeElements(1024);
    }
    this.searchSub = this.searchRequest
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe(df => {
        this.searchTerm = df;
        if (this.searchTerm && this.searchTerm.length > 0) {
          let params = '?term=' + this.searchTerm;
          this.itemResults$?.setUrl(urlJoin(this.searchUrl || '', params), this.initValues);
        } else if (this.dataUrl) {
          this.itemResults$?.setUrl(this.dataUrl, this.initValues);
        }
      });
  }

  trackItems(index: number, itemObject: any) {
    if (itemObject == null || itemObject == undefined) return null;
    return itemObject[this.trackId];
  }
}
