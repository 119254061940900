import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-lazy-img',
  templateUrl: './ui-lazy-img.component.html',
  styleUrls: ['./ui-lazy-img.component.scss'],
})
export class UiLazyImgComponent {
  @Input() radius: string | undefined = '50%';
  @Input() width: number = 50;
  @Input() height: number = 50;
  @Input() src: string | undefined | null = undefined;
  @Input() alt: string | undefined | null = undefined;
  imageLoaded($event: any) {
    $event.target.classList.add('loaded');
  }
}
