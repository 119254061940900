import { isPlatformBrowser } from '@angular/common';
import { ElementRef, EventEmitter, Output, Directive, AfterViewInit, Input, Inject, PLATFORM_ID } from '@angular/core';

@Directive({
  selector: '[visibilityChange]',
})
export class VisibilityChangeDirective implements AfterViewInit {
  @Output() public visibilityChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public threshold = 1.0;
  isBrowser = false;

  private intersectionObserver?: IntersectionObserver;

  constructor(private element: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  ngAfterViewInit(): void {
    if (this.isBrowser) {
      this.registerIntersectionObserver();
      if (this.intersectionObserver && this.element.nativeElement) {
        this.intersectionObserver.observe(this.element.nativeElement);
      }
    }
  }
  private checkForIntersection = (entries: Array<IntersectionObserverEntry>) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (this.checkIfIntersecting(entry)) {
        this.visibilityChange.emit(true);
      } else {
        this.visibilityChange.emit(false);
      }
    });
  };

  private registerIntersectionObserver(): void {
    if (!!this.intersectionObserver) {
      return;
    }
    this.intersectionObserver = new IntersectionObserver(
      entries => {
        this.checkForIntersection(entries);
      },
      {
        threshold: this.threshold ? this.threshold : 0.0,
      },
    );
  }

  private checkIfIntersecting(entry: IntersectionObserverEntry) {
    return entry.isIntersecting && entry.target === this.element.nativeElement;
  }
}
