import { Injectable, Injector } from '@angular/core';
import { AuthStateService } from 'src/app/auth/services/auth-state.service';
import { CoreSocketService } from 'src/app/shared/services/core.socket.service';
import { RestService } from 'src/app/shared/services/rest/rest.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { NotificationModel } from '../models/notification';
const listLimit: number = 20;

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends CoreSocketService<NotificationModel> {
  private _unreaded = 0;

  constructor(
    public restService: RestService,
    public authService: AuthStateService,
    public toastService: ToastService,
    public injector: Injector
  ) {
    super(
      'auth/notifications/unreaded?limit=' + listLimit,
      'OnNewNotificaiton',
      'OnDeleteNotificaiton',
      injector,
      true
    );

    this.records.values.subscribe(df => {
      this._unreaded = df.count();
    });
  }

  public get Unreaded(): number {
    return this._unreaded;
  }

  public setAllAsRead() {
    this.restService.get('auth/notifications/setAsRead').subscribe(
      result => {
        this._unreaded = 0;
      },
      error => {}
    );
  }
}
