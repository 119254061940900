import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ui-searchbar',
  templateUrl: './ui-searchbar.component.html',
  styleUrls: ['./ui-searchbar.component.scss'],
})
export class UiSearchbarComponent {
  @Input() value: string | null = null;
  @Input() placeholder: string | null = null;
  @Input() disabled: boolean = false;

  @Output() onChange = new EventEmitter<string>();
  @Output() onFocus = new EventEmitter<boolean>();

  enableFocus(focus: boolean) {
    this.onFocus.next(focus);
  }
  onSearchChange(event: string | null) {
    if (event && event.length > 0) {
      this.value = event;
    } else {
      this.value = null;
    }
    this.onChange.next(this.value ? this.value : '');
  }
  reset() {
    this.value = null;
    this.onChange.next(this.value ? this.value : '');
  }
}
