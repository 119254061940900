<div
  class="avatar"
  [style.width.px]="width"
  [style.height.px]="height"
  [style.border-radius]="radius"
>
  <img
    *ngIf="src"
    loading="lazy"
    [alt]="alt"
    [style.width.px]="width"
    [style.height.px]="height"
    class="unloaded"
    (load)="imageLoaded($event)"
    [src]="src"
  />
</div>
