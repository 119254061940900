import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-section-header',
  templateUrl: './ui-section-header.component.html',
  styleUrls: ['./ui-section-header.component.scss'],
})
export class UiSectionHeaderComponent {
  @Input() title: string | undefined = undefined;
  @Input() titleParams: any | undefined = undefined;
  @Input() desc: string | undefined = undefined;
  @Input() translateTitle: boolean = true;
  @Input() translateDesc: boolean = true;
}
