import {
  AfterViewInit,
  Component,
  Injector,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ConnectService } from 'src/app/b2c/library/services/connect.service';
import { ModalComponent } from 'src/app/shared/framework/modal-component';
import { FormBuilderOptions } from 'src/app/ui/form-builder/form-builder-options';
import { FormBuilderSteps } from 'src/app/ui/form-builder/form-builder-wizard/form-builder-wizard.component';
import { FormBuilder } from 'src/app/ui/form-builder/form-builder.component';
import { OnBoardingForm } from '../../forms/onboarding.form';

@Component({
  selector: 'app-onboard-modal',
  templateUrl: './onboard-modal.component.html',
  styleUrls: ['./onboard-modal.component.scss'],
})
export class OnboardModalComponent
  extends ModalComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('dataGrid') dataGrid: TemplateRef<any> | undefined = undefined;
  @ViewChild('wizard', { static: true }) builder: FormBuilder | undefined = undefined;

  public steps: FormBuilderSteps | null = null;
  public form: FormBuilderOptions = Object.assign({}, OnBoardingForm);
  public connectSub: Subscription | undefined = undefined;
  constructor(injector: Injector, public connectService: ConnectService) {
    super(injector);

    if (this.form.tabs.step4.sections.general.inputs.connect) {
      this.form.tabs.step4.sections.general.inputs.connect.canActivate = this.onSelect.bind(this);
    }
  }
  ngAfterViewInit(): void {
    if (this.form.tabs.step2.sections.general.inputs.games.gridData) {
      this.form.tabs.step2.sections.general.inputs.games.gridData.dataTemplate = this.dataGrid;
    }
  }
  imageLoaded($event: any) {
    $event.target.classList.add('loaded');
  }

  ngOnInit(): void {
    this.connectSub = this.connectService.records.values.subscribe(df => {
      const unqiueProviders = [...new Set(df.map(df => df.provider))];

      this.builder?.setValue('connect', unqiueProviders);
    });
  }
  ngOnDestroy(): void {
    this.connectSub?.unsubscribe();
  }

  success(data: any) {
    this.dismiss();
  }

  setSteps(event: FormBuilderSteps) {
    this.steps = event;
  }

  async onSelect(value: any): Promise<boolean> {
    const service = this.connectService;
    return new Promise(resolve => {
      service.connect(value).then(val => {
        resolve(false);
      });
    });
  }
}
