import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UiLazyImgComponent } from './ui-lazy-img/ui-lazy-img.component';

@NgModule({
  imports: [CommonModule, RouterModule, IonicModule],
  providers: [],
  declarations: [UiLazyImgComponent],
  exports: [UiLazyImgComponent],
})
export class UILazyLoadModule {}
