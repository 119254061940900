<div class="shortcut-root" [ngClass]="{ active: isActive() }">
  <ui-navigation-element
    (onClick)="doClick($event, item)"
    *ngIf="item"
    [hasContextMenu]="true"
    [hasActionButton]="false"
    [isCentered]="true"
    [thumbnailSize]="40"
    [thumbnail]="item.card.image | cloudUrl : '50x50'"
    [thumbnailLabel]="item.card.name"
  >
    <div class="unread-chats" *ngIf="item.card.readed && item.card.readed > 0">
      {{ item.card.readed }}
    </div>

    <ui-navigation-element
      uiNavigationContext
      (onClick)="doDelete()"
      [isTrashed]="true"
      iconName="trash"
    >
      <span transloco="shortcut.delete"></span>
    </ui-navigation-element>
  </ui-navigation-element>
</div>
